import { useCallback, useState } from 'react';

import { Platform, Stage, useGuestTokenMutation } from 'generated/graphql-gateway';
import { GuestToken } from 'remote/auth/get-current-guest-session';
import { useLogger } from 'state/logger';
import { env, getCountry, platform } from 'utils/environment';
import { RegionToISOs } from 'utils/form/constants';
import LocalStorage, { StorageKeys } from 'utils/local-storage';

import { ALLOWED_PATHS_FOR_URL_TOKEN, SIGN_IN_FAIL } from '../constants';
import { GuestTokenInvalid } from '../errors';
import { IGuestCredentials, IGuestDetails, IGuestSignInParams } from '../types';

export const getGuestCredentialsFromUrlQuery = (): IGuestCredentials | null => {
  // Parent path
  const path = window.location.pathname?.split('/')[1];
  const isPathAllowed = !!path && ALLOWED_PATHS_FOR_URL_TOKEN.includes(path);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const jwt = params.get('token') ?? '';
  let isTokenValid = false;
  if (jwt?.length) {
    try {
      const guestToken = new GuestToken({ token: jwt });
      isTokenValid = !guestToken?.isExpired() && guestToken?.decodedPayload?.type === 'guest';
    } catch (e) {
      return null;
    }
  }

  if (isPathAllowed && isTokenValid) {
    return { token: jwt };
  }
  return null;
};

const clearGuestCredentials = () => LocalStorage.removeItem(StorageKeys.GUEST);
const storeGuestCredentials = (data: IGuestCredentials) =>
  LocalStorage.setItem(StorageKeys.GUEST, data);

export const getGuestCredentials = (): IGuestCredentials | null => {
  const credentials = LocalStorage.getItem(StorageKeys.GUEST);
  if (!credentials?.token) {
    return null;
  }

  const token = new GuestToken({ token: credentials.token });

  if (token.isExpired()) {
    clearGuestCredentials();
    return null;
  }

  return credentials;
};

export const useGuestAuthentication = () => {
  const logger = useLogger();
  const [guestTokenMutation] = useGuestTokenMutation();
  const [signInProgress, setSignInProgress] = useState(false);

  const requestGuestToken = useCallback(async () => {
    const { data } = await guestTokenMutation({
      variables: {
        input: {
          country: RegionToISOs[getCountry().toUpperCase()],
          platform: platform() as any as Platform,
          stage: env() as any as Stage,
        },
      },
    });

    return data?.generateGuestToken ?? undefined;
  }, [guestTokenMutation]);

  const guestToken = useCallback(() => {
    const guestCredentials = getGuestCredentials();
    if (!guestCredentials) {
      return;
    }

    return new GuestToken({ token: guestCredentials.token });
  }, []);

  const isAllowedToPlaceOrder = useCallback(() => {
    const guestCredentials = getGuestCredentials();
    if (!guestCredentials) {
      return false;
    }

    const isAnyOrderPlacedSuccessfully = !!guestCredentials?.details?.committedOrderId;
    return !isAnyOrderPlacedSuccessfully;
  }, []);

  const signIn = useCallback(
    async ({ guest: { email, name, promotionalOptIn } = {} }: IGuestSignInParams) => {
      try {
        if (signInProgress) {
          return;
        }
        setSignInProgress(true);

        const guestTokenObject = guestToken();
        let token = guestTokenObject?.getJwtToken();

        if (guestTokenObject) {
          const isTokenExpired = guestTokenObject.isExpired();
          const isNotAllowedToPlaceOrder = !isAllowedToPlaceOrder();
          if (isTokenExpired || isNotAllowedToPlaceOrder) {
            token = undefined;
            clearGuestCredentials();
          }
        }

        token = token ?? (await requestGuestToken());
        if (!token) {
          throw new GuestTokenInvalid();
        }

        storeGuestCredentials({
          email,
          name,
          promotionalOptIn,
          token,
        });
      } catch (error) {
        logger.error(error);
        error.code = SIGN_IN_FAIL;
        throw error;
      } finally {
        setSignInProgress(false);
      }
    },
    [signInProgress, guestToken, requestGuestToken, isAllowedToPlaceOrder, logger]
  );

  const signOut = useCallback(() => {
    clearGuestCredentials();
  }, []);

  const updateGuestDetails = useCallback((newDetails: IGuestDetails) => {
    const guestCredentials = getGuestCredentials();
    if (!guestCredentials) {
      return;
    }
    const newGuestCredentials = { ...guestCredentials, details: newDetails };
    storeGuestCredentials(newGuestCredentials);
  }, []);

  return {
    getGuestCredentials,
    signIn,
    signOut,
    updateGuestDetails,
    isAllowedToPlaceOrder,
  };
};
