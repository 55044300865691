import styled from 'styled-components';

import { SafeAreaView } from 'components/safe-area-view';

import { brandSpecificNavBorderStyle } from './mobile-web-navigation-border.styled';

export const MobileWebNavigationBorder = styled(SafeAreaView)`
  display: initial;
  padding-top: 0;
  background: ${Styles.color.white};
  shadow-color: ${Styles.shadowColor};
  shadow-offset: ${Styles.shadowOffset};
  shadow-radius: ${Styles.shadowRadius};
  width: 100%;
  z-index: ${Styles.zIndex.overlay};
  ${brandSpecificNavBorderStyle}
  ${Styles.desktopLarge`
    display: none;

  `}
`;
