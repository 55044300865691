/**
 * AUTOGENERATED - DO NOT EDIT DIRECTLY
 *
 * Please see "scripts/regionalization/README.md" for how to modify
 */

export enum ISOs {
  ATA = 'ATA',
  AUT = 'AUT',
  CAN = 'CAN',
  CHE = 'CHE',
  CZE = 'CZE',
  DEU = 'DEU',
  ESP = 'ESP',
  FRA = 'FRA',
  GBR = 'GBR',
  KOR = 'KOR',
  NLD = 'NLD',
  NZL = 'NZL',
  POL = 'POL',
  PRT = 'PRT',
  ROU = 'ROU',
  SAU = 'SAU',
  SGP = 'SGP',
  TWN = 'TWN',
  USA = 'USA',
  ZAF = 'ZAF',
  AQ = 'ATA',
  AT = 'AUT',
  CA = 'CAN',
  CH = 'CHE',
  CZ = 'CZE',
  DE = 'DEU',
  ES = 'ESP',
  FR = 'FRA',
  GB = 'GBR',
  KR = 'KOR',
  NL = 'NLD',
  NZ = 'NZL',
  PL = 'POL',
  PT = 'PRT',
  RO = 'ROU',
  SA = 'SAU',
  SG = 'SGP',
  TW = 'TWN',
  US = 'USA',
  ZA = 'ZAF',
}

export enum RegionToISOs {
  AQ = ISOs.ATA,
  AT = ISOs.AUT,
  CA = ISOs.CAN,
  CH = ISOs.CHE,
  CZ = ISOs.CZE,
  DE = ISOs.DEU,
  ES = ISOs.ESP,
  FR = ISOs.FRA,
  GB = ISOs.GBR,
  KR = ISOs.KOR,
  NL = ISOs.NLD,
  NZ = ISOs.NZL,
  PL = ISOs.POL,
  PT = ISOs.PRT,
  RO = ISOs.ROU,
  SA = ISOs.SAU,
  SG = ISOs.SGP,
  TW = ISOs.TWN,
  US = ISOs.USA,
  ZA = ISOs.ZAF,
}

export enum ISOsToRegions {
  ATA = 'AQ',
  AUT = 'AT',
  CAN = 'CA',
  CHE = 'CH',
  CZE = 'CZ',
  DEU = 'DE',
  ESP = 'ES',
  FRA = 'FR',
  GBR = 'GB',
  KOR = 'KR',
  NLD = 'NL',
  NZL = 'NZ',
  POL = 'PL',
  PRT = 'PT',
  ROU = 'RO',
  SAU = 'SA',
  SGP = 'SG',
  TWN = 'TW',
  USA = 'US',
  ZAF = 'ZA',
}
