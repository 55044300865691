import React, { useMemo } from 'react';

import { VisuallyHidden } from '@rbilabs/components-library/build/components/visually-hidden';
import { useIntl } from 'react-intl';

import Dropdown from 'components/app-header/dropdown';
import { LeftNav } from 'components/app-header/left-nav';
import { RightNav } from 'components/app-header/right-nav';
import StyleLoadingIndicator from 'components/app-header/styled-loading-indicator';
import { AppSubheader } from 'components/app-subheader';
import CartPreview from 'components/cart-preview';
import { DeliveryBannerContainer } from 'components/delivery-banner';
import Logo from 'components/logo';
import { TopServiceMode } from 'components/top-service-mode';
import { useFeatureNavigation } from 'hooks/use-feature-navigation';
import { useAccessibility } from 'state/accessibility';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLoadingIndicator } from 'state/loading';
import { CustomEventNames, useMParticleContext } from 'state/mParticle';
import { useReversedUIContext } from 'state/reversed-ui';
import { useStoreContext } from 'state/store';
import { routes } from 'utils/routing';

import { ArrayNode, SingleNode } from '../dropdown/list-of-links';
import { SideNav } from '../side-navigation';
import NavContainer from '../styled/nav-container';

import { BRAND_LOGO_NAME_ID, MEDIA_QUERY_HEADER } from './constants';
import {
  DesktopHeaderContainer,
  LeftNavWrapper,
  RightNavContainer,
  StyledLink,
} from './desktop-header.styled';
import { DesktopHamburgerButton } from './hamburger-button';
import LogoContainer from './styled/logo-container';
import { IDesktopHeaderProps } from './types';

const DesktopHeader = ({
  serviceMode,
  userAddress,
  cateringPickupDateTime,
}: IDesktopHeaderProps) => {
  const { formatMessage } = useIntl();
  const enableOrdering = useFlag(LaunchDarklyFlag.ENABLE_ORDERING) ?? false;
  const enableBottomServiceMode = useFlag(LaunchDarklyFlag.ENABLE_BOTTOM_SERVICE_MODE);
  const enableTopServiceMode =
    useFlag(LaunchDarklyFlag.ENABLE_TOP_SERVICE_MODE) && !enableBottomServiceMode;
  const enableDesktopSideNavigation = useFlag(LaunchDarklyFlag.ENABLE_DESKTOP_SIDE_NAVIGATION);
  const mParticle = useMParticleContext();
  const { isStoreOpenAndAvailable, noStoreSelected } = useStoreContext();
  const LoadingIndicator = useLoadingIndicator();
  const { featureAboutMenuEntriesDesktop: aboutMenuEntries } = useFeatureNavigation();
  const { isFullPageModalActive } = useAccessibility();
  const { reversedUI } = useReversedUIContext();

  const logNavigationClick = mParticle.logNavigationClick;

  const sideNavEntries = useMemo(() => {
    return aboutMenuEntries?.pages ?? [];
  }, [aboutMenuEntries]);

  // enable cart if a selected store is open and and available or no store is selected
  const enableCart = enableOrdering && (isStoreOpenAndAvailable || noStoreSelected);

  return (
    <DesktopHeaderContainer
      data-mediaquery={MEDIA_QUERY_HEADER}
      aria-hidden={isFullPageModalActive}
    >
      <NavContainer reversed={reversedUI} aria-label={formatMessage({ id: 'primaryNavLinks' })}>
        <LeftNavWrapper>
          {enableDesktopSideNavigation ? (
            <SideNav entries={sideNavEntries as [SingleNode | ArrayNode]} />
          ) : (
            <DesktopHamburgerButton />
          )}
          <LeftNav />
          {aboutMenuEntries && !enableDesktopSideNavigation && (
            <Dropdown title={aboutMenuEntries?.name?.locale ?? ''} entries={sideNavEntries} />
          )}
        </LeftNavWrapper>
        <StyledLink
          to={routes.base}
          onClick={() => logNavigationClick(CustomEventNames.BUTTON_CLICK_LOGO)}
        >
          <VisuallyHidden>{formatMessage({ id: BRAND_LOGO_NAME_ID })}</VisuallyHidden>
          <LogoContainer aria-hidden="true">
            <Logo aria-hidden="true" tabIndex={-1} />
          </LogoContainer>
        </StyledLink>
        <RightNavContainer>
          {enableTopServiceMode && <TopServiceMode />}
          <RightNav
            onMyAccountClick={() => logNavigationClick(CustomEventNames.BUTTON_CLICK_MY_ACCOUNT)}
          />
          {enableCart && <CartPreview isVisible={false} />}
        </RightNavContainer>
      </NavContainer>
      <StyleLoadingIndicator
        as={LoadingIndicator}
        hasStoreSelection={isStoreOpenAndAvailable && !enableBottomServiceMode}
      />
      {!enableBottomServiceMode && !enableTopServiceMode && <DeliveryBannerContainer />}
      <AppSubheader
        serviceMode={serviceMode}
        userAddress={userAddress}
        cateringPickupDateTime={cateringPickupDateTime}
      />
    </DesktopHeaderContainer>
  );
};

export default DesktopHeader;
