import styled from 'styled-components';

import LockedIcon from 'components/icons/locked';
import { primitive } from 'styles/constants/primitives';

export const LockOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${primitive.$black};
  opacity: 0.5;
  z-index: ${Styles.zIndex.top};
`;

export const Lock = styled(LockedIcon)`
  position: absolute;
  width: 2rem;
  height: 2rem;
  left: 0;
  right: 0;
  margin: 2rem auto 0;
  z-index: ${Styles.zIndex.top};
`;
