import dlv from 'dlv';

import {
  IBackendCartEntries,
  ICartEntry,
  ICombo,
  IItem,
  IItemOptionModifier,
  IOffer,
  IPluWithQuantity,
  IPluWithSize,
  IPrices,
  ISanityCombo,
  ISanityItem,
  ISanityItemOptionModifier,
  ISanityVendorConfigs,
  IVendorConfig,
} from '@rbi-ctg/menu';
import { LoyaltyOffer } from 'state/loyalty/types';
import { CartEntryType } from 'utils/cart/types';
import logger from 'utils/logger';
import { isCombo } from 'utils/menu';

export enum PluTypes {
  CONSTANT = 'constantPlu',
  IGNORE = 'ignore',
  MULTI_CONSTANT = 'multiConstantPlus',
  PARENT_CHILD = 'parentChildPlu',
  QUANTITY = 'quantityBasedPlu',
  SIZE_BASED = 'sizeBasedPlu',
}

export enum PosVendors {
  CARROLLS = 'carrols',
  CARROLS_DELIVERY = 'carrolsDelivery',
  NCR = 'ncr',
  NCR_DELIVERY = 'ncrDelivery',
  OHEICS = 'oheics',
  OHEICS_DELIVERY = 'oheicsDelivery',
  PARTNER = 'partner',
  PARTNER_DELIVERY = 'partnerDelivery',
  PRODUCT_NUMBER = 'productNumber',
  PRODUCT_NUMBER_DELIVERY = 'productNumberDelivery',
  QDI = 'qdi',
  QDI_DELIVERY = 'qdiDelivery',
  QST = 'qst',
  QST_DELIVERY = 'qstDelivery',
  RPOS = 'rpos',
  RPOS_DELIVERY = 'rposDelivery',
  SICOM = 'sicom',
  SICOM_DELIVERY = 'sicomDelivery',
  SIMPLY_DELIVERY = 'simplyDelivery',
  SIMPLY_DELIVERY_DELIVERY = 'simplyDeliveryDelivery',
  TABLET = 'tablet',
  TABLET_DELIVERY = 'tabletDelivery',
}

export const sortQuantityBasedPlus = (plus: IPluWithQuantity[]) => {
  return plus.slice().sort((left, right) => (left.quantity > right.quantity ? 1 : -1));
};

export const minQuantityPlu = (plus: IPluWithQuantity[] | undefined = []) => {
  const sorted = sortQuantityBasedPlus(plus);
  return sorted[0];
};

export interface IWithVendorConfig {
  _type?: string;
  type?: string;
  name?: { locale: string } | string;
  vendorConfigs?: ISanityVendorConfigs | null;
}

export const getVendorConfig = (
  item: IWithVendorConfig,
  vendor: PosVendors | null
): IVendorConfig | null | undefined => {
  if (!item || !item.vendorConfigs || !vendor) {
    return null;
  }

  const { vendorConfigs } = item;
  return vendorConfigs[vendor];
};

export const getConstantPlu = (vendorConfig: IVendorConfig | null | undefined): string | null => {
  if (!vendorConfig || vendorConfig.pluType !== PluTypes.CONSTANT) {
    return null;
  }

  return vendorConfig.constantPlu;
};

export const getQuantityBasedPlu = (plus: IPluWithQuantity[], quantity?: number): string | null => {
  if (!quantity) {
    return dlv(minQuantityPlu(plus), 'plu', null);
  }

  return dlv(plus.find(plu => plu.quantity === quantity)!, 'plu', null);
};

export const concatenateSizePlu = (plu: IPluWithSize): string | null => {
  if (!plu.comboPlu || !plu.comboSize) {
    return null;
  }

  return `${plu.comboPlu}-${plu.comboSize}`;
};

export const maybeConcatenateMainItemPlu = (
  plu: string,
  mainItemPlu: string | null
): string | null => {
  if (mainItemPlu) {
    return `${plu}-${mainItemPlu}`;
  }

  return plu;
};

export const bestPlusForQuantity = (
  plus: IPluWithQuantity[],
  prices: IPrices,
  quantity: number
) => {
  // remove plus with no price and plus for quantities greater than the amount user is buying
  const relevantPlus = plus.filter(
    ({ plu, quantity: quantityForPlu }) => quantityForPlu <= quantity && plu in (prices ?? {})
  );
  // sort in descending order
  const sorted = relevantPlus.sort((left, right) => (left.quantity < right.quantity ? 1 : -1));
  // build a bucket of plus that sums to the user's selected quantity
  return sorted.reduce<IPluWithQuantity[]>((bucket, currentPlu) => {
    const remainingSpace = quantity - bucket.reduce((acc, { quantity: qty }) => acc + qty, 0);
    return bucket.concat(
      Array<IPluWithQuantity>(Math.floor(remainingSpace / currentPlu.quantity)).fill(currentPlu)
    );
  }, []);
};

export const getOfferVendorConfigForPricing = (offer: IOffer | LoyaltyOffer | null) => {
  return offer?.marketPrice?.vendorConfigs || offer?.vendorConfigs;
};

interface IComputeCompositeModifierPlu {
  item: IBackendCartEntries | ICartEntry | IItem;
  modifier: IBackendCartEntries | ICartEntry | ISanityItemOptionModifier | IItemOptionModifier;
  vendor: PosVendors | null;
}

interface IComputeCompositeComboSlotPlu {
  parent: IBackendCartEntries | ICartEntry | ICombo | ISanityCombo;
  child: IBackendCartEntries | ICartEntry | IItem | ISanityItem;
  vendor: PosVendors | null;
}

interface IComputePlu {
  modifier: IBackendCartEntries | ICartEntry | ISanityItemOptionModifier | IItemOptionModifier;
  vendor: PosVendors | null;
}

/**
 * Computes a Plu for a modifier using its parent item's plu, i.e.
 * item (parent) - constantPlu 123
 * modifier (child) - constantPlu 345
 *  returns 123-345
 *
 * currently this is only supported if:
 *   - the parent item has a constant plu
 *   - the modifier has a constant or multi-constant
 *     plu array with a length of 1
 *
 * @returns {string | null} the composite plu or null
 * if the criteria were not satisfied by the provided
 * arguments
 */
export const computeCompositeModifierPlu = ({
  item,
  modifier,
  vendor,
}: IComputeCompositeModifierPlu): string | null => {
  const itemVendorConfig = getVendorConfig(item, vendor);

  // modifier composite plus can only
  // be computed for items with a`constantPlu`
  if (!itemVendorConfig || itemVendorConfig.pluType !== PluTypes.CONSTANT) {
    return null;
  }

  const modifierVendorConfig = getVendorConfig(modifier, vendor);

  if (!modifierVendorConfig) {
    return null;
  }

  if (modifierVendorConfig.pluType === PluTypes.SIZE_BASED) {
    logger.warn({ message: 'Unable to compute modifier PLU with PluType SizeBasedPlu', modifier });
    // TODO: determine what should happen here..
    return null;
  }

  if (modifierVendorConfig.pluType === PluTypes.PARENT_CHILD) {
    logger.warn({
      message: 'Unable to compute modifier PLU with PluType ParentChildPlu',
      modifier,
    });
    // TODO: determine what should happen here..
    return null;
  }

  if (
    modifierVendorConfig.pluType === PluTypes.MULTI_CONSTANT &&
    modifierVendorConfig.multiConstantPlus !== null &&
    modifierVendorConfig.multiConstantPlus.length
  ) {
    const [{ plu }] = modifierVendorConfig.multiConstantPlus;

    return `${itemVendorConfig.constantPlu}-${plu}`;
  }

  if (
    modifierVendorConfig.pluType === PluTypes.CONSTANT &&
    modifierVendorConfig.constantPlu !== null
  ) {
    return `${itemVendorConfig.constantPlu}-${modifierVendorConfig.constantPlu}`;
  }

  return null;
};

/**
 * Computes a Plu for a premium comboSlot item that must be priced
 * using its parent's plu, i.e.
 *
 * combo (parent) - constantPlu 123
 * item (child) - constantPlu 345
 *  returns 123-345
 *
 * currently this is only supported if:
 *   - the parent has a plu (any plu type)
 *   - the child has a constant or multi-constant
 *     plu array with a length of 1
 *
 * @returns {string | null} the composite plu or null
 * if the criteria were not satisfied by the provided
 * arguments
 */
export const computeCompositeComboSlotPlu = ({
  parent,
  child,
  vendor,
}: IComputeCompositeComboSlotPlu): string | null => {
  const parentVendorConfig = getVendorConfig(parent, vendor);

  if (!parentVendorConfig) {
    return null;
  }

  const mainItemComboPlu =
    isCombo(parent) && parent.mainItem
      ? getConstantPlu(getVendorConfig(parent.mainItem, vendor))
      : null;

  /**
   * This is due to repricing of cart entries.
   * The correct way would be to reprice everything based on requests from sanity,
   * and not count exclusively with the data from local storage.
   * This should be temporary fix.
   */
  const mainItemCartEntry =
    // @ts-ignore CartEntries and Menu Items have no common fields. The only reliable way to check if this is a CartEntry is to check the type
    parent?.type === CartEntryType.combo &&
    // @ts-ignore CartEntries and Menu Items have no common fields. The only reliable way to check if this is a CartEntry is to check the type
    parent?.cartId &&
    // @ts-ignore CartEntries and Menu Items have no common fields. The only reliable way to check if this is a CartEntry is to check the type
    parent.children.find(entry => entry.type === 'Item');

  const mainItemCartEntryPlu = mainItemCartEntry
    ? getConstantPlu(getVendorConfig(mainItemCartEntry, vendor))
    : null;

  const mainItemPlu = mainItemComboPlu || mainItemCartEntryPlu;

  let parentPlu;
  if (parentVendorConfig.pluType === PluTypes.CONSTANT) {
    const constantPlu = getConstantPlu(parentVendorConfig);
    parentPlu = constantPlu ? constantPlu : null;
  } else if (parentVendorConfig.pluType === PluTypes.SIZE_BASED) {
    const sizeBasedPlu = concatenateSizePlu(parentVendorConfig.sizeBasedPlu);
    parentPlu = sizeBasedPlu ? maybeConcatenateMainItemPlu(sizeBasedPlu, mainItemPlu) : null;
  } else {
    // Other parent types are not supported at the composite plu level.
    // For these other types (Quantity, ParentChild, MultiConstant) you have to perform multiple price lookups and combine them.
    // Returning a single composite plu won't work for those types.
    logger.warn({
      message: `Unable to compute composite PLU for comboSlot, parent combo was pluType '${parentVendorConfig.pluType}.`,
      parent,
    });
    return null;
  }

  const childVendorConfig = getVendorConfig(child, vendor);

  if (!childVendorConfig) {
    return null;
  }

  if (childVendorConfig.pluType !== PluTypes.CONSTANT) {
    logger.warn({
      message: `Unable to compute composite PLU for comboSlot, child was pluType '${childVendorConfig.pluType} but we only support constant plus for comboSlot items.`,
      child,
    });
    return null;
  }

  if (childVendorConfig.constantPlu === null) {
    logger.warn({
      message: `Unable to compute composite PLU for comboSlot, constantPlu doesn't exist for ${child.name}`,
      child,
    });
    return null;
  }

  return `${parentPlu}-${childVendorConfig.constantPlu}`;
};

export const computeSimpleModifierPlu = ({ modifier, vendor }: IComputePlu): string | null => {
  const modifierVendorConfig = getVendorConfig(modifier, vendor);

  if (modifierVendorConfig && modifierVendorConfig.pluType === PluTypes.CONSTANT) {
    return modifierVendorConfig.constantPlu;
  }

  return null;
};

export enum PluQualifier {
  ADD = 'ADD',
  HALF = '1/2',
  NO = 'NO',
}

// multi constant plus sometimes have qualifiers
// that express a quantity, i.e. NO or 1/2
// when an item option modifier has
// multi constant plus we should reduce the array
// of plus to a quantity to determine pricing
export function reduceMultiConstantPluQuantity(plus: IPluWithQuantity[]) {
  // if any NO plu is found this modifier
  // should not be considered "added"
  if (plus.find(({ qualifier }) => qualifier === PluQualifier.NO)) {
    return 0;
  }

  return plus.reduce((quantity, { qualifier, quantity: pluQuantity }) => {
    switch (qualifier) {
      case PluQualifier.HALF:
        return quantity + 0.5;
      case PluQualifier.ADD:
      default:
        return quantity + (pluQuantity || 0);
    }
  }, 0);
}
