import React, { FC, useCallback, useEffect, useState } from 'react';

import { useIntl } from 'react-intl';

import useErrorModal from 'hooks/use-error-modal';
import { useThRewardsManagementContext } from 'state/th-rewards-management';

// eslint-disable-next-line import/no-cycle
import { RewardTierSelectionModal } from '../reward-tier-selection-modal';

export type IUseRewardSelectionModal<P = any> = [
  React.FC<P>,
  {
    openRewardsModal: () => void;
  },
];

export function useRewardSelectionModal(): IUseRewardSelectionModal {
  const { formatMessage } = useIntl();
  const {
    dismissUpdateError,
    errorUpdatingRewardTier,
    onChangeRewardTier,
    selectedRewardCategoryId,
    selectedRewardTierId,
  } = useThRewardsManagementContext();

  const [showRewardSelectionModal, setShowhowRewardSelectionModal] = useState(false);

  const openRewardsModal = useCallback(() => {
    setShowhowRewardSelectionModal(true);
  }, [setShowhowRewardSelectionModal]);
  const closeRewardsModal = useCallback(
    () => setShowhowRewardSelectionModal(false),
    [setShowhowRewardSelectionModal]
  );

  const [ErrorDialog, openErrorDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Setting Reward Tier Failure',
    onCancel: dismissUpdateError,
    onConfirm: dismissUpdateError,
    onDismiss: dismissUpdateError,
  });

  useEffect(() => {
    if (errorUpdatingRewardTier) {
      openErrorDialog({ message: formatMessage({ id: 'setRewardsTierError' }) });
    }
  });

  const RewardSelectionModal: FC = () => {
    return (
      <>
        {showRewardSelectionModal && (
          <RewardTierSelectionModal
            onDismiss={closeRewardsModal}
            radioButtonName="change-reward-level-modal-reward-tier"
            onChangeRewardTier={onChangeRewardTier}
            selectedRewardTierId={selectedRewardTierId}
            selectedRewardCategoryId={selectedRewardCategoryId}
          />
        )}
        <ErrorDialog />
      </>
    );
  };

  return [
    RewardSelectionModal,
    {
      openRewardsModal,
    },
  ];
}
