import React from 'react';

import { VisuallyHidden } from '@rbilabs/components-library/build/components/visually-hidden';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import IconCircleCheck from 'components/icons/circle-check';
import { DeliveryStatus } from 'state/order/types';
import type { TLocalizationKey } from 'types/i18n';

import { DELIVERY_PROGRESS_COLOR } from './constants';
import theme from './theme';

const StatusMessage = styled.p`
  font-size: 0.875rem;
  color: ${theme.statusMessageColor};
  padding: 0;
  margin: -0.8rem 0 1rem 0;
`;

const ProgressList = styled.ul<{ percentComplete: number }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  list-style: none;
  font-size: 0.75rem;
  line-height: 1.3;
  color: ${Styles.color.grey.two};
  margin: 1.5rem 0 1rem 0;
  padding: 0;

  &::after {
    content: '';
    position: absolute;
    inset-block-start: 0.5625rem;
    inset-inline-start: 2.1875rem;
    inset-inline-end: 2.1875rem;
    height: 0.25rem;
    background: ${props => {
      return `linear-gradient(
        to right,
        ${DELIVERY_PROGRESS_COLOR} 0 ${props.percentComplete}%,
        ${Styles.color.grey.four} ${props.percentComplete}% 100%
      );`;
    }};
  }

  li {
    flex: 0 1 3.125rem;
    text-align: center;
    z-index: ${Styles.zIndex.below};
  }
`;

const Circle = styled(IconCircleCheck)`
  width: 1.375rem;
  display: block;
  margin: 0 auto 0.25rem auto;
  fill: ${props => {
    return props.isActive ? DELIVERY_PROGRESS_COLOR : Styles.color.grey.four;
  }};
`;

interface IDeliveryProgressProps {
  deliveryStatus: DeliveryStatus;
  rbiOrderId: string;
}

export const STATUS_PERCENTS = {
  [DeliveryStatus.ORDER_CREATED]: 33,
  [DeliveryStatus.DRIVER_ASSIGNED]: 33,
  [DeliveryStatus.DRIVER_UNASSIGNED]: 33,
  [DeliveryStatus.DRIVER_STARTING]: 33,
  [DeliveryStatus.DRIVER_AT_STORE]: 50,
  [DeliveryStatus.ORDER_PICKED_UP]: 66.7,
  [DeliveryStatus.DRIVER_AT_CUSTOMER]: 83.3,
  [DeliveryStatus.ORDER_DROPPED_OFF]: 100,
  [DeliveryStatus.ORDER_ABANDONED]: 100,
};

export const STATUS_MESSAGES: Record<string, TLocalizationKey> = {
  [DeliveryStatus.QUOTE_SUCCESSFUL]: 'deliveryStatusQuoteSuccessful',
  [DeliveryStatus.QUOTE_ERROR]: 'deliveryStatusQuoteError',
  [DeliveryStatus.QUOTE_REQUESTED]: 'deliveryStatusQuoteRequested',
  [DeliveryStatus.QUOTE_UNAVAILABLE]: 'deliveryStatusQuoteUnavailable',
  [DeliveryStatus.ORDER_CREATED]: 'deliveryStatusOrderCreated',
  [DeliveryStatus.DRIVER_ASSIGNED]: 'deliveryStatusDriverAssigned',
  [DeliveryStatus.DRIVER_UNASSIGNED]: 'deliveryStatusDriverUnassigned',
  [DeliveryStatus.DRIVER_STARTING]: 'deliveryStatusDriverStarting',
  [DeliveryStatus.DRIVER_AT_STORE]: 'deliveryStatusDriverAtStore',
  [DeliveryStatus.ORDER_PICKED_UP]: 'deliveryStatusOrderPickedUp',
  [DeliveryStatus.DRIVER_AT_CUSTOMER]: 'deliveryStatusDriverAtCustomer',
  [DeliveryStatus.ORDER_DROPPED_OFF]: 'deliveryStatusOrderDroppedOff',
  [DeliveryStatus.ORDER_ABANDONED]: 'deliveryStatusOrderAbandoned',
  [DeliveryStatus.ORDER_CANCELLED]: 'deliveryStatusOrderCancelled',
  [DeliveryStatus.ORDER_ERROR]: 'deliveryStatusOrderError',
  [DeliveryStatus.ORDER_TIMEOUT]: 'deliveryStatusOrderTimeout',
};

const DeliveryProgress: React.FC<IDeliveryProgressProps> = ({ deliveryStatus }) => {
  const { formatMessage } = useIntl();
  const percentComplete = STATUS_PERCENTS[deliveryStatus];
  const isOrderPlaced = percentComplete >= STATUS_PERCENTS[DeliveryStatus.ORDER_CREATED];
  const isBeingPrepared = percentComplete >= STATUS_PERCENTS[DeliveryStatus.DRIVER_ASSIGNED];
  const isDriverEnroute = percentComplete >= STATUS_PERCENTS[DeliveryStatus.ORDER_PICKED_UP];
  const isDelivered = percentComplete >= STATUS_PERCENTS[DeliveryStatus.ORDER_DROPPED_OFF];
  const statusMessageId = STATUS_MESSAGES[deliveryStatus];
  const ariaLabel = () => {
    const [complete, incomplete] = [
      formatMessage({ id: 'complete' }),
      formatMessage({ id: 'incomplete' }),
    ];
    return `
      ${formatMessage({ id: 'orderPlaced' })} ${isOrderPlaced ? complete : incomplete},
      ${formatMessage({ id: 'deliveryOrderBeingPrepared' })} ${
        isBeingPrepared ? complete : incomplete
      },
      ${formatMessage({ id: 'deliveryDriverEnroute' })} ${isDriverEnroute ? complete : incomplete},
      ${formatMessage({ id: 'deliveryDelivered' })} ${isDelivered ? complete : incomplete},
    `;
  };

  return (
    <>
      <StatusMessage data-testid="progress-status-message" tabIndex={0}>
        {statusMessageId && formatMessage({ id: statusMessageId })}
      </StatusMessage>
      <VisuallyHidden tabIndex={0} role="alert" aria-live="polite" aria-label={ariaLabel()}>
        {ariaLabel()}
      </VisuallyHidden>
      <ProgressList tabIndex={0} percentComplete={percentComplete}>
        <li>
          <Circle isActive={isOrderPlaced} />
          {formatMessage({ id: 'orderPlaced' })}
        </li>
        <li>
          <Circle isActive={isBeingPrepared} />
          {formatMessage({ id: 'deliveryOrderBeingPrepared' })}
        </li>
        <li>
          <Circle isActive={isDriverEnroute} />
          {formatMessage({ id: 'deliveryDriverEnroute' })}
        </li>
        <li>
          <Circle isActive={isDelivered} />
          {formatMessage({ id: 'deliveryDelivered' })}
        </li>
      </ProgressList>
    </>
  );
};

export default DeliveryProgress;
