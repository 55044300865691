// This file is generated via scripts/build-static-config.js
  // Do not modify directly as changes will be overwritten in CI

  export const CONFIGURATION = {
    country: 'sg',
    brand: 'plk',
    env: 'prod',
    graphqlEnv: 'prod',
    graphqlGatewayEnv: '',
    sanityEnv: 'prod',
    platform: 'web',
    commitRef: 'c10b7c4d9caf2626126b4082a56ac2230fcde6e8',
    releaseTagRef: '48f1950',
    releaseTimestamp: '1701758947',
    sanityDataset: '',
    sanityProjectId: 'czqk28jt',
    appFlowBuildId: '',
    adyen: {
      clientKey: '',
      environment: '',
      urlPrefix: '',
      ideal: {
        idealBanks: [],
      }
    },
    apiKeys: {
      amplitude: '',
      branch: '',
      braze: 'b576cdec-fa9a-41b5-89f2-1cd6ba4f36c9',
      bringg: '',
      googleMaps: 'AIzaSyDOtGSKPthz3am_QTmuavFu42QiIcVORm0',
      googleMapsAndroid: '',
      googleMapsIOS: '',
      googleMapsWeb: '',
      tomtom: '',
      launchDarkly: '644687324b9d9412dd2dc595',
      launchDarklyMobileKey: 'mob-85ec4068-e805-4b02-ac5a-e55a10b444be',
      mParticle: 'eu1-388098d94d7a524abeea87f77c1534f7',
      radar: '',
    },
    apple: {
      merchantId: '',
      migrationMerchantId: '',
      paymentsNetworks: [],
      prepaidMerchantId: '',
      migrationPrepaidMerchantId: '',
      socialLogin: {
        clientId: '',
        redirectURI: '',
      },
    },
    facebook: {
      socialLogin: {
        appId: '',
        redirectURI: '',
      },
    },
    aws: {
      gqlApiUrl: 'https://euc1-qa-bk.rbictg.com/graphql',
      gqlGatewayApiUrl: 'https://euc1-qa-bk-gateway.rbictg.com/graphql',
      region: 'eu-central-1',
      userPoolClientId: 'knn87f21ktnaghucrtkr98t2c',
      userPoolId: 'eu-central-1_fTcmZY0Pq',
    },
    bringg: {
      region: '',
    },
    datadog: {
      logs: {
        clientToken: '',
        dataCenter: '',
      },
      rum: {
        applicationId: '',
        clientToken: '',
        dataCenter: '',
      },
    },
    firstData: {
      corporateStoreId: '',
    },
    forter: {
      siteId: 'idNotSet',
    },
    google: {
      paymentsEnvironment: '',
      paymentsGateway: '',
      paymentsGatewayMerchantId: {
        ca: '',
        us: '',
      },
      migrationPaymentsGateway: '',
      migrationPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
      },
      paymentsNetworks: [],
      prepaidPaymentsGateway: '',
      prepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
      },
      migrationPrepaidPaymentsGateway: '',
      migrationPrepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
      },
      socialLogin: {
        clientId: '',
        redirectURI: '',
      },
    },
    googleAdManager: {
      campaignId: '',
    },
    googleTagManager: {
      containerId: '',
    },
    oneTrust: {
      cookieCompliance: {
        consentCategoryBranch: '',
        consentCategoryBraze: '',
        consentCategoryMParticle: '',
        globalConsentState: '',
        scriptUrl: '',
        scriptId: '',
      },
      deleteAccountUrl: '',
    },
    pypestream: {
      scriptUrl: '',
      appId: '',
    },
    restaurants: {
      displayEnv: 'dev',
      validMobileOrderingEnvs: ['dev'],
    },
    urls: {
      braze: 'https://dashboard-01.braze.eu',
      firstData: 'https://api-int.payeezy.com',
      firstDataTh: '',
      webBaseUrl: '',
      thDigital: '',
    },
    tokenEx: {
      publicKey: '',
    },
    vrPayment: {
      braintreeTokenizationKey: '',
    },
    orbital: {
      baseUrl: '',
      subscriberId: '',
      pieFormat: '',
      mode: '',
    },
    advancedBooking: {
      catering: '',
      birthday: '',
    },
    timeFormat: '',
    dateFormat: 'MM/dd/yyyy',
    addressFormat: '',
    maxCartQuantity: '',
    appStoreDeeplink: '',
    iosDeepLink: '',
    androidDeepLink: '',
  };