import React, { useCallback, useMemo } from 'react';

import { Icon } from '@rbilabs/components-library/build/components/icon';
import { isNil } from 'lodash';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { isFalse } from 'utils';

import ListOfLinks from 'components/app-header/dropdown/list-of-links';
import { ArrowLink } from 'components/arrow-link';
import LanguageSelector from 'components/language-selector';
import AccountLayout from 'components/layout/account';
import { brandFont } from 'components/layout/brand-font';
import Link from 'components/link';
import { BackArrowHistory } from 'components/navigation/back-arrow';
import { VerifyEmailMessage } from 'components/verify-email-message';
import useAuth from 'hooks/auth/use-auth';
import useAuthRedirects from 'hooks/auth/use-auth-redirects';
import { useFeatureInvitationCode } from 'hooks/use-feature-invitation-code';
import { useFeatureMemberships } from 'hooks/use-feature-memberships';
import { useFeatureNavigation } from 'hooks/use-feature-navigation';
import useMediaQuery from 'hooks/use-media-query';
import LoyaltyCardTile from 'pages/loyalty/loyalty-card-tile/loyalty-card-tile';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { MyAccountSections, defaultMyAccountSections } from 'state/launchdarkly/variations';
import { CustomEventNames, EventTypes, useMParticleContext } from 'state/mParticle';
import { primitive } from 'styles/constants/primitives';
import { routes } from 'utils/routing';

import Disclaimer from '../about/disclaimer';

import CommPreferenceLink from './comm-preference-link';
import {
  ACCOUNT_DISPLAY_SIGN_UP_HEADLINE,
  ACCOUNT_LINKS_BORDER_COLOR,
  ACCOUNT_LINKS_DISPLAY_LANGUAGE_SELECTOR,
  ACCOUNT_LINKS_DISPLAY_SECTION_HEADINGS,
  isAccountActionsEnabled,
} from './constants';
import CreateAccount from './create-account';
import InvitationCode from './invitation-code-link';
import RewardsLink from './rewards-link';
import SubmitFeedbackLink from './submit-feedback-link';

const AccountAction = styled(Link)`
  text-decoration: none;
  font: ${brandFont.headerThree};
  display: flex;
  margin: ${primitive.$spacing2} 0;
`;

const StyledIcon = styled.div`
  position: relative;
  inset-inline-end: ${primitive.$spacing1};
`;

const StyledBackArrowHistory = styled(BackArrowHistory)`
  margin: 0rem;
`;

const AccountLanguageSelectorContainer = styled.div`
  border-bottom: 1px solid ${p => p.theme.token('border-color-default')};
  padding-bottom: ${primitive.$spacing4};
`;

const LanguageSelectIntro = styled.div`
  text-transform: none;
`;

const ActionsWrapper = styled.div`
  border-bottom: 1px solid ${ACCOUNT_LINKS_BORDER_COLOR};
  padding-bottom: ${primitive.$spacing4};
`;

const AccountLinksWrapper = styled.div`
  margin-bottom: ${primitive.$spacing7};
`;

const Account = ({ className }: { className?: string }) => {
  const mParticle = useMParticleContext();
  const { formatMessage } = useIntl();
  const { user } = useAuthContext();
  const enableAutoSignInOnSignUp = useFlag(LaunchDarklyFlag.ENABLE_AUTO_SIGN_IN_ON_SIGN_UP);
  const enableRequestMyInformation = useFlag(LaunchDarklyFlag.ENABLE_REQUEST_MY_INFORMATION);
  const enableOrderingFlag = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);
  const enableOrdering = isNil(enableOrderingFlag) || enableOrderingFlag;
  const enableAccountDeletePage = useFlag(LaunchDarklyFlag.ENABLE_ACCOUNT_DELETE_PAGE);
  const enableCommPrefFlag = useFlag(LaunchDarklyFlag.ENABLE_COMMUNICATION_PREFERENCES);
  const isLocalizationDisabled = useFlag(LaunchDarklyFlag.DISABLE_LOCALIZATION);
  const enableDiagnosticTools = useFlag(LaunchDarklyFlag.ENABLE_DIAGNOSTIC_TOOLS);
  const enableNativeMobileAccount = useFlag(LaunchDarklyFlag.ENABLE_NATIVE_MOBILE_ACCOUNT);
  const enableFindRestaurantLink = useFlag(LaunchDarklyFlag.ENABLE_FIND_RESTAURANT_LINK);
  const enablePayback = useFlag(LaunchDarklyFlag.ENABLE_PAYBACK);
  const enableAccountSettings = useFlag(LaunchDarklyFlag.ENABLE_ACCOUNT_SETTINGS);
  const enableCateringBirthday = useFlag(LaunchDarklyFlag.ENABLE_CATERING_BIRTHDAY);
  const enableAccountCateringBirthday = useFlag(LaunchDarklyFlag.ENABLE_ACCOUNT_CATERING_BIRTHDAY);
  const isMobile = useMediaQuery('mobile');
  const enableCommunicationPreferences = !isFalse(enableCommPrefFlag);
  const { isAuthenticated } = useAuth();
  const theme = useTheme();

  const isCateringBirthdayEnable = useMemo(
    () => enableCateringBirthday && enableAccountCateringBirthday,
    [enableAccountCateringBirthday, enableCateringBirthday]
  );

  const showAboutMenuLinks = enableNativeMobileAccount && isMobile;
  useAuthRedirects({ skip: showAboutMenuLinks });

  const { featureAboutMenuEntriesMobile: aboutMenuEntries }: any = useFeatureNavigation();

  const { featureInvitationCode } = useFeatureInvitationCode();
  const enableInvitationCode = featureInvitationCode?.enableInvitationCode;

  const { enableMemberships } = useFeatureMemberships();

  const navigate = useNavigate();

  const createOnStaticPageClick = (attr: any) => {
    mParticle.trackEvent({
      name: CustomEventNames.BUTTON_CLICK_STATIC_PAGES,
      type: EventTypes.Navigation,
      attributes: attr,
    });
  };

  const showLanguageSelector = !isLocalizationDisabled && ACCOUNT_LINKS_DISPLAY_LANGUAGE_SELECTOR;

  const enableSignUpFlag = useFlag(LaunchDarklyFlag.ENABLE_SIGN_UP);
  const enableSignUp = isNil(enableSignUpFlag) || enableSignUpFlag;
  const enabledAccountSections =
    useFlag<MyAccountSections>(LaunchDarklyFlag.ENABLE_ACCOUNT_SECTIONS_VARIATIONS) ||
    defaultMyAccountSections;

  const isMobileAccountSignUp = isMobile && ACCOUNT_DISPLAY_SIGN_UP_HEADLINE;
  const showCreateAccount = isMobileAccountSignUp && !isAuthenticated();
  const showSignUpLink = !isMobileAccountSignUp && !isAuthenticated();
  const isHamburgerMenuEnabled = useFlag(LaunchDarklyFlag.ENABLE_HAMBURGER_MENU_ON_MOBILE);

  const handleAccountSettingsClick = useCallback(() => {
    mParticle.logNavigationClick(CustomEventNames.SETTINGS);
  }, [mParticle]);

  const handleCateringBirthdayClick = useCallback(() => {
    mParticle.logNavigationClick(CustomEventNames.CATERING_BIRTHDAY);
  }, [mParticle]);

  const handleMembershipsClick = useCallback(() => {
    mParticle.logNavigationClick(CustomEventNames.MEMBERSHIPS);
  }, [mParticle]);

  if (!showAboutMenuLinks && !isAuthenticated()) {
    return <div />;
  }

  return (
    <div>
      <Helmet title={formatMessage({ id: 'myAccount' })} />
      <AccountLayout className={className}>
        {isHamburgerMenuEnabled && (
          <StyledBackArrowHistory
            icon={<Icon icon="close" width="24px" color="icon-header-contrast" aria-hidden />}
          />
        )}
        {isAuthenticated() && (
          <AccountLinksWrapper>
            {enableAutoSignInOnSignUp && user?.details.emailVerified === false && (
              <VerifyEmailMessage />
            )}
            <RewardsLink />
            {enabledAccountSections.accountInfo && (
              <ArrowLink
                to={routes.info}
                onClick={() => {
                  mParticle.logNavigationClick(CustomEventNames.BUTTON_CLICK_ACCOUNT_INFO);
                }}
                data-testid="account-info"
                prefixIcon="account"
              >
                {formatMessage({ id: 'accountInfo' })}
              </ArrowLink>
            )}
            {enabledAccountSections.earnPoints && (
              <ArrowLink
                to={isMobile ? routes.redeem : formatMessage({ id: 'routes.linkPhysicalCard' })}
                onClick={() => {
                  mParticle.logNavigationClick(CustomEventNames.BUTTON_CLICK_EARN_POINTS);
                }}
                data-testid="earn-points"
                prefixIcon="rewards"
              >
                {formatMessage({ id: 'earnPoints' })}
              </ArrowLink>
            )}
            {enablePayback && (
              <ArrowLink
                to={routes.payback}
                onClick={() => {
                  mParticle.logNavigationClick(CustomEventNames.BUTTON_CLICK_PAYBACK);
                }}
                data-testid="payback"
                prefixIcon="payback"
              >
                {formatMessage({ id: 'payback' })}
              </ArrowLink>
            )}
            {enabledAccountSections.paymentMethods && enableOrdering && (
              <ArrowLink
                to={routes.payment}
                onClick={() => {
                  mParticle.logNavigationClick(CustomEventNames.BUTTON_CLICK_PAYMENT_METHODS);
                }}
                data-testid="payment-methods-url"
                prefixIcon="paymentCard"
              >
                {formatMessage({ id: 'paymentMethods' })}
              </ArrowLink>
            )}
            {enabledAccountSections.recentOrders && enableOrdering && (
              <ArrowLink
                to={routes.orders}
                onClick={() => {
                  mParticle.logNavigationClick(CustomEventNames.BUTTON_CLICK_RECENT_ORDERS);
                }}
                data-testid="recent-orders"
                prefixIcon="cart"
              >
                {formatMessage({ id: 'recentOrders' })}
              </ArrowLink>
            )}
            {enableInvitationCode && <InvitationCode />}
            {isCateringBirthdayEnable && (
              <ArrowLink
                to={formatMessage({ id: 'routes.cateringBirthday' })}
                onClick={handleCateringBirthdayClick}
                prefixIcon="cateringBirthday"
              >
                {formatMessage({ id: 'accountCateringBirthday' })}
              </ArrowLink>
            )}
            {enableCommunicationPreferences && <CommPreferenceLink />}
            {enabledAccountSections.submitFeedback && <SubmitFeedbackLink />}
            {enableRequestMyInformation && (
              <ArrowLink
                to={formatMessage({ id: 'routes.accountRequestInfo' })}
                onClick={() => {
                  mParticle.logNavigationClick(
                    CustomEventNames.BUTTON_CLICK_REQUEST_MY_INFORMATION
                  );
                }}
                prefixIcon="requestInfo"
              >
                {formatMessage({ id: 'requestMyInformation' })}
              </ArrowLink>
            )}
            {enabledAccountSections.userPreferences && (
              <ArrowLink
                to={formatMessage({ id: 'routes.userPreferencesModal' })}
                onClick={() => {
                  mParticle.logNavigationClick(CustomEventNames.BUTTON_CLICK_USER_PREFERENCES);
                }}
                prefixIcon="preferences"
              >
                {formatMessage({ id: 'userPreferences' })}
              </ArrowLink>
            )}
            {enableAccountDeletePage && (
              <ArrowLink
                to={formatMessage({ id: 'routes.accountDelete' })}
                onClick={() => {
                  mParticle.logNavigationClick(CustomEventNames.BUTTON_CLICK_ACCOUNT_DELETE);
                }}
                data-testid="delete-account-button"
                prefixIcon="deleteAccount"
              >
                {formatMessage({ id: 'deleteAccount' })}
              </ArrowLink>
            )}

            {enableAccountSettings && (
              <ArrowLink
                to={formatMessage({ id: 'routes.settings' })}
                onClick={handleAccountSettingsClick}
                data-testid="settings-account-button"
                prefixIcon="gear"
              >
                {formatMessage({ id: 'accountSettings' })}
              </ArrowLink>
            )}

            {enableMemberships && (
              <ArrowLink
                to={formatMessage({ id: 'routes.memberships' })}
                onClick={handleMembershipsClick}
                data-testid="account-memberships-button"
                prefixIcon="gear"
              >
                {formatMessage({ id: 'memberships' })}
              </ArrowLink>
            )}
            <LoyaltyCardTile />
          </AccountLinksWrapper>
        )}

        {showAboutMenuLinks && (
          <>
            {showLanguageSelector && (
              <AccountLanguageSelectorContainer>
                <LanguageSelectIntro>
                  {formatMessage({ id: 'languageAndRegion' })}
                </LanguageSelectIntro>
                <LanguageSelector color={theme.token('text-default')} />
              </AccountLanguageSelectorContainer>
            )}

            {isAccountActionsEnabled(enableSignUp) && (
              <ActionsWrapper>
                {showCreateAccount && <CreateAccount />}
                {showSignUpLink && (
                  <AccountAction data-testid="signup-link" to={routes.signUp}>
                    <StyledIcon>
                      <Icon icon="account" color="icon-default" width="24px" aria-hidden />
                    </StyledIcon>
                    <span>{formatMessage({ id: 'signInOrCreateAccount' })}</span>
                  </AccountAction>
                )}
                {enableFindRestaurantLink && (
                  <AccountAction to={routes.storeLocator}>
                    <StyledIcon>
                      <Icon icon="location" color="icon-default" width="24px" aria-hidden />
                    </StyledIcon>
                    <span>{formatMessage({ id: 'findARestaurant' })}</span>
                  </AccountAction>
                )}
              </ActionsWrapper>
            )}

            {aboutMenuEntries && (
              <ListOfLinks
                data-testid="links-list"
                title={aboutMenuEntries.name?.locale || ''}
                entries={aboutMenuEntries.pages || []}
                trackStaticPage={createOnStaticPageClick}
                borderColor={ACCOUNT_LINKS_BORDER_COLOR}
                sectionHeadings={ACCOUNT_LINKS_DISPLAY_SECTION_HEADINGS}
                navigate={navigate}
              />
            )}
            <Disclaimer showCalorieDisclaimer={false} />
            {enableDiagnosticTools && (
              <Link to={routes.diagnostics}>{formatMessage({ id: 'diagnostics' })}</Link>
            )}
          </>
        )}
      </AccountLayout>
    </div>
  );
};

export default Account;
