import { useMemo } from 'react';

import { IHomePageConfigurationFragment } from 'generated/sanity-graphql';

import {
  FeatureHomePageComponentTypes,
  FeatureHomePageComponents,
  IRawFeatureHomePageComponent,
} from '../types';

import { useAlert } from './use-alert';
import { useAppDownload } from './use-app-download';
import { useBrazeContentCards } from './use-braze-content-cards';
import { useFanFavorites } from './use-fan-favorites';
import { useGoogleAdSlots } from './use-google-ad-slots';
import { useHero } from './use-hero';
import { useMainHero } from './use-main-hero';
import { useMarketingCardGroups } from './use-marketing-card-groups';
import { useMarketingTileCarousel } from './use-marketing-tile-carousel';
import { useMarketingTileGroups } from './use-marketing-tile-groups';
import { useOfferCarouselSection } from './use-offer-carousel-section';
import { useOurServices } from './use-our-services';
import { useRewardsSection } from './use-rewards-section';
import { useTimsRewardPointsSection } from './use-tims-rewards-points-section';
import { useTrending } from './use-trending';

const ID_ONLY_COMPONENTS = [
  FeatureHomePageComponentTypes.LockedOffers,
  FeatureHomePageComponentTypes.OffersSection,
  FeatureHomePageComponentTypes.RecentItems,
  FeatureHomePageComponentTypes.RollUpTheRimSection,
  FeatureHomePageComponentTypes.ScanForRewards,
];

const MULTIPLE_INSTANCE_COMPONENTS = [
  FeatureHomePageComponentTypes.MarketingCardGroup,
  FeatureHomePageComponentTypes.MarketingTileGroup,
  FeatureHomePageComponentTypes.GoogleAdSlot,
];

/**
 * @name formatIdOnlyComponents
 * @description
 *  At this point there are some components we only care about their _id
 *    and we don't need to query for them because we already have the _id
 *    from the original homepage query.
 *  So, lets just format them to mirror the queried components
 */
const formatIdOnlyComponents = (featureHomePageComponentMap: any) => {
  return ID_ONLY_COMPONENTS.reduce((acc, compType) => {
    if (featureHomePageComponentMap[compType]) {
      acc[compType] = {
        _id: featureHomePageComponentMap[compType],
        __typename: compType,
      };
    }
    return acc;
  }, {});
};

const orderPopulatedFeatureHomePageComponents = (
  allPopulatedComponents: FeatureHomePageComponents,
  featureHomePage?: IHomePageConfigurationFragment
) => {
  const components = (featureHomePage?.components ?? []).reduce(
    (acc: FeatureHomePageComponents[], rawComp: IRawFeatureHomePageComponent) => {
      if (rawComp?.__typename && allPopulatedComponents[rawComp?.__typename]) {
        const allowedMultipleInstances = MULTIPLE_INSTANCE_COMPONENTS.includes(rawComp.__typename);
        // These types are allowed multiple instances so we need to find the right one
        if (allowedMultipleInstances) {
          const specificItem = allPopulatedComponents[rawComp.__typename].find(
            (popComp: any) => popComp._id === rawComp._id
          );
          if (specificItem) {
            acc.push(specificItem);
          }
        }
        // all others are limited to one instance so just push the one
        else {
          acc.push(allPopulatedComponents[rawComp?.__typename]);
        }
      }
      return acc;
    },
    [] as FeatureHomePageComponents[]
  );
  return components;
};

export const useFeatureHomePageComponents = (featureHomePage?: IHomePageConfigurationFragment) => {
  /**
   * @name featureHomePageComponentMap
   * @description
   *  maps raw feature home page components to { type: id } structure
   *  to simplify querying for the different component types
   */
  const featureHomePageComponentMap: FeatureHomePageComponents = (
    featureHomePage?.components ?? []
  ).reduce((acc: any, comp: IRawFeatureHomePageComponent) => {
    const allowedMultipleInstances = MULTIPLE_INSTANCE_COMPONENTS.includes(comp.__typename);
    // These types are allowed multiple instances
    // setup the component type as an array of ids
    if (allowedMultipleInstances) {
      if (!acc[comp.__typename]) {
        acc[comp.__typename] = [];
      }
      acc[comp.__typename].push(comp._id);
    }
    // all others are limited to one instance so just one id
    else {
      acc[comp.__typename] = comp._id;
    }
    return acc;
  }, {});

  /**
   * ==================================
   *    INDIVIDUAL COMPONENT QUERIES
   * ==================================
   */

  const { mainHero, mainHeroLoading } = useMainHero(featureHomePageComponentMap.MainHero);

  const { hero, heroLoading } = useHero(featureHomePageComponentMap.Hero);

  const { brazeContentCards, brazeContentCardsLoading } = useBrazeContentCards(
    featureHomePageComponentMap.BrazeContentCards
  );

  const { alert, alertLoading } = useAlert(featureHomePageComponentMap.Alert);

  const { marketingCardGroups, marketingCardGroupsLoading } = useMarketingCardGroups(
    featureHomePageComponentMap.MarketingCardGroup
  );

  const { marketingTileCarousel, marketingTileCarouselLoading } = useMarketingTileCarousel(
    featureHomePageComponentMap.MarketingTileCarousel
  );

  const { marketingTileGroups, marketingTileGroupsLoading } = useMarketingTileGroups(
    featureHomePageComponentMap.MarketingTileGroup
  );

  const { trending, trendingLoading } = useTrending(featureHomePageComponentMap.Trending);

  const { fanFavorites, fanFavoritesLoading } = useFanFavorites(
    featureHomePageComponentMap.FanFavorites
  );

  const { ourServices, ourServicesLoading } = useOurServices(
    featureHomePageComponentMap.OurServices
  );

  const { appDownload, appDownloadLoading } = useAppDownload(
    featureHomePageComponentMap.AppDownload
  );

  const { rewardsSection, rewardsSectionLoading } = useRewardsSection(
    featureHomePageComponentMap.RewardsSection
  );

  const { offerCarousel, offerCarouselLoading } = useOfferCarouselSection(
    featureHomePageComponentMap.OfferCarouselSection
  );

  const { googleAdSlots, googleAdSlotsLoading } = useGoogleAdSlots(
    featureHomePageComponentMap.GoogleAdSlot
  );

  const { pointsSection, pointsSectionLoading } = useTimsRewardPointsSection(
    featureHomePageComponentMap.TimsRewardsPointsSection
  );

  /**
   * ==================================
   *   MERGE POPULATED COMPONENT DATA
   * ==================================
   */
  const allPopulatedComponents: FeatureHomePageComponents = {
    [FeatureHomePageComponentTypes.Alert]: alert,
    [FeatureHomePageComponentTypes.AppDownload]: appDownload,
    [FeatureHomePageComponentTypes.BrazeContentCards]: brazeContentCards,
    [FeatureHomePageComponentTypes.Hero]: hero,
    [FeatureHomePageComponentTypes.FanFavorites]: fanFavorites,
    [FeatureHomePageComponentTypes.MainHero]: mainHero,
    [FeatureHomePageComponentTypes.MarketingTileGroup]: marketingTileGroups,
    [FeatureHomePageComponentTypes.MarketingCardGroup]: marketingCardGroups,
    [FeatureHomePageComponentTypes.OurServices]: ourServices,
    [FeatureHomePageComponentTypes.RewardsSection]: rewardsSection,
    [FeatureHomePageComponentTypes.Trending]: trending,
    [FeatureHomePageComponentTypes.MarketingTileCarousel]: marketingTileCarousel,
    [FeatureHomePageComponentTypes.GoogleAdSlot]: googleAdSlots,
    [FeatureHomePageComponentTypes.TimsRewardsPointsSection]: pointsSection,
    [FeatureHomePageComponentTypes.OfferCarouselSection]: offerCarousel,
    ...formatIdOnlyComponents(featureHomePageComponentMap),
  };

  /**
   * ==================================
   *   ORDER POPULATED COMPONENT DATA
   * ==================================
   */
  const orderedPopulatedFeatureHomePageComponents = useMemo(
    () => orderPopulatedFeatureHomePageComponents(allPopulatedComponents, featureHomePage),
    [allPopulatedComponents, featureHomePage]
  );

  /**
   * ==================================
   *    DETERMINE LOADING STATE
   * ==================================
   */
  const featureHomePageComponentsLoading =
    mainHeroLoading ||
    heroLoading ||
    brazeContentCardsLoading ||
    alertLoading ||
    marketingCardGroupsLoading ||
    marketingTileGroupsLoading ||
    trendingLoading ||
    marketingTileCarouselLoading ||
    fanFavoritesLoading ||
    ourServicesLoading ||
    appDownloadLoading ||
    rewardsSectionLoading ||
    googleAdSlotsLoading ||
    offerCarouselLoading ||
    pointsSectionLoading;

  /**
   * ==================================
   *            RETURN DATA
   * ==================================
   */
  return {
    featureHomePageComponentsLoading,
    featureHomePageComponents: orderedPopulatedFeatureHomePageComponents,
  };
};
