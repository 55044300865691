import styled from 'styled-components';

import Link from 'components/link';
import { View } from 'components/view';

import { RIGHT_NAV_ALIGN_ITEMS } from './constants';

export const DesktopHeaderContainer = styled(View)`
  display: ${props => props['aria-hidden'] && `none`};
  transform: translateY(0) scale(1);
  width: 100%;
  z-index: ${Styles.zIndex.overlay + 100};
`;

// This causes "extra" nav elements to be hidden (per product req), as opposed to
// wrapping and looking broken on small desktop screens when there are > 3 nav elements.
export const LeftNavWrapper = styled.div`
  max-height: 3.125rem;
  max-width: 40%;
  overflow: hidden;
  padding: 0.313rem;
  display: flex;
  align-items: center;
  margin: auto 0;
`;

export const RightNavContainer = styled.div`
  display: flex;
  align-items: ${RIGHT_NAV_ALIGN_ITEMS};
  padding: 0.313rem;
`;

export const StyledLink = styled(Link)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &::before {
    color: transparent;
  }
`;
