import { IFeatureMembershipsQuery, useFeatureMembershipsQuery } from 'generated/sanity-graphql';

interface IUseFeatureMemberships {
  featureMemberships: IFeatureMembershipsQuery['LiveMemberships'] | null;
  enableMemberships: boolean;
  featureMembershipsLoading: boolean;
}

const useFeatureMemberships = (): IUseFeatureMemberships => {
  const { data, loading } = useFeatureMembershipsQuery({
    variables: {
      featureMembershipsId: 'feature-memberships-singleton',
    },
  });

  const featureMemberships = data?.LiveMemberships ?? null;
  const enableMemberships = Boolean(featureMemberships?.liveMemberships?.length);

  return { featureMemberships, enableMemberships, featureMembershipsLoading: loading };
};

export default useFeatureMemberships;
