import React, { useEffect } from 'react';

import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { BrowserRouter as Router } from 'react-router-dom';
import { StyleSheetManager, ThemeProvider } from 'styled-components';

import Layout from 'components/layout';
import ReduceProviders from 'components/reduce-providers';
import { store } from 'state/global-state';
import { theme } from 'styles/configure-theme';
import { BrandGlobalStyles } from 'styles/global/configure-styles';
import { WebGlobalStyle } from 'styles/global/web-global-style';
import { brand, isLocalDev, platform } from 'utils/environment';
import { LaunchDarklyFlagsObject } from 'utils/launchdarkly';
import LocalStorage from 'utils/local-storage';
import { Keys } from 'utils/local-storage/constants';
import { shouldForwardProp } from 'utils/shouldForwardProp';

import providers from './providers';
import Global from './styles/global';

const clearStorageOnBrandChange = () => {
  const prevBrand = LocalStorage.getItem(Keys.BRAND);
  if (prevBrand && brand() !== prevBrand) {
    LocalStorage.clearAll();
  }
  LocalStorage.setItem(Keys.BRAND, brand());
};

const shouldClear = !LocalStorage.isCurrentVersion();
if (isLocalDev) {
  clearStorageOnBrandChange();
}

// NOTE: cypress-v2 test suite requirement.
// Avoid localStorage clearing. Cypress does this automatically between tests.
if (shouldClear && !window.Cypress) {
  LocalStorage.clearAll();
  LocalStorage.setCurrentVersion();
}

function App({
  ldFlags,
  userHasSignedIn,
  userSession,
}: {
  ldFlags: LaunchDarklyFlagsObject;
  userHasSignedIn: boolean;
  userSession: CognitoUserSession | null;
}) {
  const [BRAND, PLATFORM] = [brand().toUpperCase(), platform()];

  useEffect(() => {
    if (!isLocalDev) {
      const renderSuccessTime = performance.now?.();
      const durationTillRenderSuccess = renderSuccessTime - window.LOADING_START_TIME;
      (window.dataLayer || []).push({
        event: 'APP_REACT_RENDER_SUCCESS',
        brand: BRAND,
        timestamp: renderSuccessTime,
        duration: durationTillRenderSuccess,
        platform: PLATFORM,
      });
    }
  }, [BRAND, PLATFORM]);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Global />
        <WebGlobalStyle />
        <BrandGlobalStyles />
        <ReduceProviders providers={providers({ store, ldFlags, userHasSignedIn, userSession })}>
          <StyleSheetManager shouldForwardProp={shouldForwardProp}>
            <Layout />
          </StyleSheetManager>
        </ReduceProviders>
      </Router>
    </ThemeProvider>
  );
}

export default App;
