/* eslint-disable no-console */
import { noop } from 'lodash';

import { IMParticleCtx } from 'state/mParticle';

interface IAttTrackingOptions {
  requestPermission: boolean;
}

export interface IAttTrackingStatusResponse {
  value: string;
  status: 'authorized' | 'denied' | 'unrequested' | 'restricted';
}
interface IEnablePush {
  trackEvent?: IMParticleCtx['trackEvent'];
}

interface ISelectPermissions extends IEnablePush {}

export const enablePush = ({ trackEvent }: IEnablePush) => {
  console.debug(`Permissions ${trackEvent} enablePush triggered - but only works for natives`);
};

export const openPushSetting = noop;

export const isNotificationsPermissionGranted = async () => noop;

export const isNotificationsPermissionDenied = async () => {
  console.debug(
    `Permissions isNotificationsPermissionDenied triggered - but only works for natives`
  );
  return false;
};

export const selectPermission = async ({ trackEvent }: ISelectPermissions) => {
  console.debug(
    `Permissions ${trackEvent} selectPermission triggered - but only works for natives`
  );
};

export const enableLocation = (timeout: number): Promise<any | void> => {
  console.debug(`Permissions ${timeout} enableLocation triggered - but only works for natives`);
  return Promise.reject(new Error('Permissions dont work for web'));
};

export const appTrackingTransparency = async ({
  requestPermission,
}: IAttTrackingOptions): Promise<IAttTrackingStatusResponse | undefined> => {
  console.debug(
    `Permissions ${requestPermission} appTrackingTransparency triggered - but only works for natives`
  );
  return undefined;
};
