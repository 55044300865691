import React from 'react';

import { Icon } from '@rbilabs/components-library/build/components/icon';
import { useIntl } from 'react-intl';

import { MobileHeaderContainer } from 'components/app-header/mobile-header/styled';
import useGoBack from 'hooks/use-go-back';
import { useNativeStatusBar } from 'hooks/use-native-status-bar';

import { BackArrowContainer, SignUpHeader } from './signup-mobile-header.styled';

const SignupMobileHeader = () => {
  const { formatMessage } = useIntl();
  const { nativeStatusBarExist } = useNativeStatusBar();
  const goBack = useGoBack();

  return (
    <MobileHeaderContainer data-mediaquery="mobile" data-testid="signup-mobile-header">
      <SignUpHeader nativeStatusBarExist={nativeStatusBarExist}>
        <BackArrowContainer onClick={goBack}>
          <Icon
            icon="back"
            color="icon-default"
            width="24px"
            title={formatMessage({ id: 'back' })}
          />
        </BackArrowContainer>
      </SignUpHeader>
    </MobileHeaderContainer>
  );
};

export default SignupMobileHeader;
