import { SupportedLanguages } from '@rbi-ctg/frontend';
import { IStaticPageRoute } from 'remote/queries/static-page';

export const findLocaleStaticPage = (
  path: string,
  selectedLanguage: SupportedLanguages,
  previousLanguage: SupportedLanguages,
  routes: IStaticPageRoute[]
): string => {
  const findPathStatic =
    routes
      .map(route => {
        const current = route?.path?.current ?? '';
        const localePath = route?.localePath;

        if (localePath && localePath[selectedLanguage]) {
          const findUrl =
            path.includes(current) ||
            localePath[previousLanguage]?.current === path?.replace('/', '');

          if (findUrl) {
            return localePath[selectedLanguage]?.current;
          }
        }

        return path.includes(current) ? current : null;
      })
      .filter(item => !!item)[0] ?? undefined;

  // Clear path preventing if someone inserts a bar in Sanity
  const pathStaticPage = findPathStatic ? `/${findPathStatic.replace(/^\//, '')}` : '';

  return pathStaticPage;
};
