import { useCallback, useEffect, useMemo, useState } from 'react';

import { asIsoCountryCode2, getDateFormat, getLocales } from '@rbilabs/intl';

import { SupportedLanguages, SupportedRegions } from '@rbi-ctg/frontend';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import useEffectOnce from 'hooks/use-effect-once';
import { LaunchDarklyFlag, useFlag, useLDContext } from 'state/launchdarkly';
import { useLoggerContext } from 'state/logger/context';
import en from 'state/translations/en.json';
import { TLocalizationMessages } from 'types/i18n';
import {
  ENABLE_AUTO_SHOW_REGION_SELECTOR,
  FALLBACK_ISO2,
  FALLBACK_ISO3,
  FALLBACK_LOCALES,
} from 'utils/constants';
import * as DatadogLogger from 'utils/datadog';
import { brand, getCountry } from 'utils/environment';
import { ISOs } from 'utils/form/constants';
import { getLanguageOrDefault } from 'utils/i18n/language';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { routes } from 'utils/routing';

import { LOCALE_SELECTED_QUERY_PARAM, regionSpecificLocalStorageKeys } from './constants';
import { getMessagesForLanguage } from './get-messages-for-language';
import { inferSupportedLocale } from './infer-supported-locale';
import { inferLanguage } from './inferLanguage';
import { loadLanguage } from './load-language';
import { loadLocale } from './load-locale';
import { loadRegion } from './load-region';
import { IUserCurrentLocaleCache } from './types';

export * from './constants';

const LOCALE_SEPARATOR = '-';

const reloadWindow = () => document.location.reload();

function findSupportedRegion(requestedRegion: string): SupportedRegions {
  return asIsoCountryCode2(requestedRegion) || FALLBACK_ISO2;
}

function findSupportedLanguage(requestedLanguage: string): SupportedLanguages {
  return getLanguageOrDefault(requestedLanguage);
}

function hydrateInitialLocale(useDefault = false): string {
  // if native, uses loadLocale.app.tsx to hydrate based on env variable
  const { language, region } = loadLocale();

  const newLocale = inferSupportedLocale(
    language as SupportedLanguages,
    region as SupportedRegions,
    useDefault
  );

  return newLocale;
}

function inferHasShownLanguageSelectorFromUrlParams(): boolean {
  return Boolean(new URL(window.location.href).searchParams.get(LOCALE_SELECTED_QUERY_PARAM));
}

export function inferHasShownLanguageSelector() {
  const hasSelectedLocale = inferHasShownLanguageSelectorFromUrlParams();
  const locales = getLocales(brand(), getCountry()) ?? FALLBACK_LOCALES;
  const hasSingleSupportedLocale = locales.length < 2;

  return (
    hasSelectedLocale ||
    hasSingleSupportedLocale ||
    !!LocalStorage.getItem<boolean>(StorageKeys.HAS_SHOWN_LOCALE_SELECTOR)
  );
}

/*
 * This Region hook has a few specs to it:
 *
 * 1. It is preloaded with the users inferred region from domain or url params
 * 2. It allows the UI to update the region manually
 * 3. When the users intentionally changes the region, we store that in local storage.
 *    so that we can preload with that selected region in the future.
 * 4. The preloaded locale (language + region) must exist in our list of `SORTED_PROD_SUPPORTED_LOCALES` or fallback to a value on that list.
 * 5. If the user updates their browser region, we should update too.
 */
const useIntl = () => {
  const { updateUserLocale } = useLDContext();
  const { decorateLogger } = useLoggerContext();

  const isLocalizationDisabled = useFlag(LaunchDarklyFlag.DISABLE_LOCALIZATION);

  const [messages, setMessages] = useState<TLocalizationMessages>(en);
  const [locale, setLocale] = useState<string>(hydrateInitialLocale(isLocalizationDisabled));
  const [userCurrentLocale, setUserCurrentLocale] = useState<IUserCurrentLocaleCache | undefined>(
    undefined
  );
  const [language, region] = useMemo(() => locale.split(LOCALE_SEPARATOR), [locale]) as [
    SupportedLanguages,
    SupportedRegions,
  ];

  const [hasShownLocaleSelector, setInternalHasShownLocaleSelector] = useState<boolean>(
    inferHasShownLanguageSelector()
  );

  const setHasShownLocaleSelector = useCallback(() => {
    LocalStorage.setItem(StorageKeys.HAS_SHOWN_LOCALE_SELECTOR, true);

    setInternalHasShownLocaleSelector(true);
  }, [setInternalHasShownLocaleSelector]);

  const clearRegionSpecificStorage = useCallback(() => {
    // Wipe out region specific keys
    regionSpecificLocalStorageKeys.forEach(LocalStorage.removeItem);
  }, []);

  const setCurrentLocale = useCallback(
    (newLanguage: SupportedLanguages, newRegion: SupportedRegions, reloadPage: boolean = true) => {
      const newLocale = inferSupportedLocale(newLanguage, newRegion, isLocalizationDisabled);
      const [newLocaleLanguage, newLocaleRegion] = newLocale.split(LOCALE_SEPARATOR);

      LocalStorage.setItem(StorageKeys.LANGUAGE, newLocaleLanguage);
      LocalStorage.setItem(StorageKeys.REGION, newLocaleRegion);

      if (newRegion !== region) {
        // Wipe out region specific keys
        clearRegionSpecificStorage();
      }

      // todo: make sure the locale is valid so we dont end up with fr-US
      setLocale(newLocale);
      getMessagesForLanguage({
        language: newLocaleLanguage,
        country: newLocaleRegion,
      }).then(setMessages);
      updateUserLocale({ region: newRegion, language: newLanguage });
      // reload to refresh content that is pulled from Sanity - edge case
      // eg if user's language is english, but they click an external link to french static page
      if (reloadPage) {
        reloadWindow();
      }
    },
    [isLocalizationDisabled, region, clearRegionSpecificStorage, updateUserLocale]
  );

  useEffectOnce(() => {
    window.addEventListener('languagechange', () => {
      setCurrentLocale(loadLanguage(), loadRegion());
    });
  });

  useEffectOnce(() => {
    decorateLogger({ region });
  });

  // We track the user's locale (region + language) in logger errors
  // some errors might occur in specific langauges, so its crucial
  // to know all info on how to reproduce.
  useEffect(() => {
    DatadogLogger.addContext('page_locale', locale);
  }, [locale]);

  useEffectOnce(() => {
    // Make sure we never show the user the dialog again if the params we passed
    if (inferHasShownLanguageSelectorFromUrlParams()) {
      setHasShownLocaleSelector();
    }
    getMessagesForLanguage({ language, country: region }).then(setMessages);
  });

  // Make sure locale will update for logged-in users who have localization enabled
  // and also any time the isLocalization flag changes - including when it kicks in initially
  useEffectOnUpdates(() => {
    // remove the region from localStorage so that it doesn't override the domain's region
    localStorage.removeItem(StorageKeys.REGION);
    setCurrentLocale(loadLanguage(), loadRegion(), false);
  }, [isLocalizationDisabled]);

  const dateFormat = getDateFormat(brand(), getCountry(), locale);

  const blacklistedLangModalRoute = [routes.confirmJwt, routes.store].some(route =>
    window.location.pathname.includes(route)
  );

  const shouldAutoShowSelector =
    ENABLE_AUTO_SHOW_REGION_SELECTOR &&
    !isLocalizationDisabled &&
    !blacklistedLangModalRoute &&
    !hasShownLocaleSelector;

  const [showLanguageSelectorModal, setInternalShowLanguageSelectorModal] =
    useState(shouldAutoShowSelector);

  const setShowLanguageSelectorModal = useCallback(
    (bool: boolean) => {
      setInternalShowLanguageSelectorModal(bool);
    },
    [setInternalShowLanguageSelectorModal]
  );

  return {
    region,
    feCountryCode: ISOs[region] || FALLBACK_ISO3,
    language,
    locale,
    inferLanguage,
    findSupportedRegion,
    clearRegionSpecificStorage,
    findSupportedLanguage,
    setCurrentLocale,
    hasShownLocaleSelector,
    setHasShownLocaleSelector,
    showLanguageSelectorModal,
    setShowLanguageSelectorModal,
    setUserCurrentLocale,
    userCurrentLocale,
    messages,
    dateFormat,
  };
};

export default useIntl;
