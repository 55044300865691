import React, { ReactNode, createContext, useCallback, useContext } from 'react';

import { IServerOrder } from '@rbi-ctg/menu';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { useGuestAuthentication } from './hooks/use-guest-authentication';
import { IGuestDetails, IGuestSignInParams, IGuestUser } from './types';

export interface AuthGuestInterface {
  guestUser(): IGuestUser | null;
  signIn(args: IGuestSignInParams): Promise<void>;
  signOut(): void;
  updateGuestDetails(guestDetails: IGuestDetails): void;

  isGuestCheckoutEnabled(): boolean;
  isAllowedToPlaceOrder(): boolean;
  isAuthenticated(): boolean;
  isAnonymous: () => boolean;

  onCommitOrderSuccess(rbiOrder: IServerOrder): void;
}

const providerExceptionMessage = 'useAuthGuestContext must be used within an AuthGuestProvider';

const defaultAuthGuest: AuthGuestInterface = {
  isAllowedToPlaceOrder: () => {
    throw new Error(providerExceptionMessage);
  },
  isAnonymous: () => {
    throw new Error(providerExceptionMessage);
  },
  signIn: () => {
    throw new Error(providerExceptionMessage);
  },
  guestUser: () => {
    throw new Error(providerExceptionMessage);
  },
  signOut: () => {
    throw new Error(providerExceptionMessage);
  },
  updateGuestDetails: () => {
    throw new Error(providerExceptionMessage);
  },

  isGuestCheckoutEnabled: () => {
    throw new Error(providerExceptionMessage);
  },
  isAuthenticated: () => {
    throw new Error(providerExceptionMessage);
  },
  onCommitOrderSuccess: () => {
    throw new Error(providerExceptionMessage);
  },
};

export const AuthGuestContext = createContext<AuthGuestInterface>(defaultAuthGuest);
export const useAuthGuestContext = () => useContext(AuthGuestContext);

export const AuthGuestProvider = ({ children }: { children: ReactNode }) => {
  const enableGuestCheckout = useFlag(LaunchDarklyFlag.ENABLE_GUEST_CHECKOUT);
  const { signIn, signOut, updateGuestDetails, isAllowedToPlaceOrder, getGuestCredentials } =
    useGuestAuthentication();

  const onCommitOrderSuccess = useCallback(
    (rbiOrder: IServerOrder) => {
      if (!rbiOrder.cart.guestId) {
        return;
      }

      updateGuestDetails({
        committedOrderId: rbiOrder.rbiOrderId,
      });
    },
    [updateGuestDetails]
  );

  const guestUser = useCallback(() => {
    const guestCredentials = getGuestCredentials();
    const { details, email, name, promotionalOptIn } = guestCredentials || {};

    if (!guestCredentials || !email) {
      return null;
    }

    return {
      details,
      email,
      name,
      promotionalOptIn,
    };
  }, [getGuestCredentials]);

  const isAuthenticated = useCallback(() => {
    const guestCredentials = getGuestCredentials();
    return !!guestCredentials?.token;
  }, [getGuestCredentials]);

  const isAnonymous = useCallback(() => {
    const guestCredentials = getGuestCredentials();
    if (!guestCredentials) {
      return false;
    }

    const isEmailProvided = !!guestCredentials?.email;
    return !isEmailProvided;
  }, [getGuestCredentials]);

  const isGuestCheckoutEnabled = useCallback(() => {
    return enableGuestCheckout; // TODO: TRX-1009 load data from sanity / store settings
  }, [enableGuestCheckout]);

  const getContext = useCallback(() => {
    return {
      guestUser,
      isAnonymous,
      signIn,
      signOut,
      updateGuestDetails,
      isGuestCheckoutEnabled,
      isAllowedToPlaceOrder,
      isAuthenticated,
      onCommitOrderSuccess,
    };
  }, [
    guestUser,
    isAnonymous,
    signIn,
    signOut,
    updateGuestDetails,
    isGuestCheckoutEnabled,
    isAllowedToPlaceOrder,
    isAuthenticated,
    onCommitOrderSuccess,
  ]);

  return <AuthGuestContext.Provider value={getContext()}>{children}</AuthGuestContext.Provider>;
};
