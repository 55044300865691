import React, { useCallback, useEffect, useState } from 'react';

import { useBranchContext } from 'state/branch';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useMParticleContext } from 'state/mParticle';
import { MParticleAdapter } from 'state/mParticle/mparticle-adapter';
import * as Braze from 'utils/braze';
import { getConfigValue } from 'utils/environment';
import LocalStorage, { StorageKeys } from 'utils/local-storage';

import { isBranchUnavailable } from './isBranchUnavailable';
import useOneTrustSdk from './use-one-trust-sdk';

type ConsentGroups = {
  C0001: number;
  C0002: number;
  C0003: number;
  C0004: number;
};

enum OneTrustGlobalConsentState {
  OPT_OUT = 'Opt-Out',
  OPT_IN = 'Opt-In',
}

export const OneTrustCookieBanner = () => {
  const { init: initMParticle } = useMParticleContext();
  const { initBranch } = useBranchContext();
  const { loaded: otSdkLoaded } = useOneTrustSdk();

  const [servicesLoaded, setServiceLoaded] = useState(false);
  const [otConsentGroups, setConsentGroups] = useState({} as ConsentGroups);
  const [consentGroupsSetInLocalStorage, setConsentGroupsSetInLocalStorage] = useState(false);
  const branchUnavailable = isBranchUnavailable();

  // Fucntion to initialize 3rd party services
  const initServicesWithCookies = useCallback(() => {
    if (!window.mParticle) {
      initMParticle();
    }
    if (!window.branch && !branchUnavailable) {
      initBranch();
    }
  }, [initBranch, initMParticle, branchUnavailable]);

  // loads 3rd party services once OneTrust sdk is loaded
  useEffect(() => {
    if (otConsentGroups && otSdkLoaded && !servicesLoaded) {
      initServicesWithCookies();
    }
  }, [initServicesWithCookies, otConsentGroups, otSdkLoaded, servicesLoaded]);

  const servicesInitCheck = useCallback(() => {
    const branchValidation = branchUnavailable ?? !!window.branch;
    const areServicesInitialized = !!window.mParticle && branchValidation && !!Braze.getSdk();
    if (areServicesInitialized) {
      setServiceLoaded(true);
    }
    return areServicesInitialized;
  }, [branchUnavailable]);

  // Sets a interval to check if all 3rd party services are initialized
  useEffect(() => {
    const id = setInterval(servicesInitCheck, 1000);
    if (servicesLoaded) {
      clearInterval(id);
    }
    return () => clearInterval(id);
  }, [servicesInitCheck, servicesLoaded]);

  const getConsentGroups = (consentState: String[]) => {
    const groups: ConsentGroups = {
      C0001: 0,
      C0002: 0,
      C0003: 0,
      C0004: 0,
    };

    return Object.keys(groups).reduce((accumulator, group) => {
      if (consentState.includes(group)) {
        accumulator[group] = 1;
      }
      return accumulator;
    }, groups);
  };

  // Registers onetrust consent change handler
  useEffect(() => {
    const id = setInterval(() => {
      if (window?.OneTrust && otSdkLoaded) {
        window.OneTrust.OnConsentChanged((event: any) => {
          const consentState = event?.detail ?? [];
          const consentGroups = getConsentGroups(consentState);
          setConsentGroups(consentGroups);
          LocalStorage.setItem(StorageKeys.ONETRUST_COOKIE_CONSENT_GROUPS, consentGroups);
        });
        setConsentGroupsSetInLocalStorage(true);
      }
    }, 1000);
    if (consentGroupsSetInLocalStorage) {
      clearInterval(id);
    }
    return () => clearInterval(id);
  }, [otSdkLoaded, consentGroupsSetInLocalStorage]);

  // Pull the consent groups out of local storage if it's already populated.
  // This will trigger the hook below, and disable/enable any global services based on
  // the previously chosen settings.
  useEffect(() => {
    const existingConsentGroups = LocalStorage.getItem(StorageKeys.ONETRUST_COOKIE_CONSENT_GROUPS);
    if (existingConsentGroups) {
      setConsentGroups(existingConsentGroups);
    }
  }, []);

  const disableCookieOptOut = useFlag(LaunchDarklyFlag.DISABLE_COOKIE_MPARTICLE_OPT_OUT);

  // Switches off tracking for each service if data tracking is opted out
  useEffect(() => {
    const {
      globalConsentState,
      consentCategoryMParticle,
      consentCategoryBraze,
      consentCategoryBranch,
    } = getConfigValue('oneTrust').cookieCompliance;

    // reads consent state for each consent category
    const mparticleConsentState = otConsentGroups[consentCategoryMParticle];
    const brazeConsentState = otConsentGroups[consentCategoryBraze];
    const branchConsentState = otConsentGroups[consentCategoryBranch];

    // if consent state is opted out for respective service, data tracking is opted out
    if (servicesLoaded) {
      const isOptOutState = globalConsentState === OneTrustGlobalConsentState.OPT_OUT;
      if (mparticleConsentState === 0 || isOptOutState) {
        MParticleAdapter.setOptOut(true);
      }
      if (brazeConsentState === 0 || isOptOutState) {
        Braze.disableSDK();
      }
      if ((branchConsentState === 0 || isOptOutState) && !branchUnavailable) {
        window.branch.disableTracking(true);
      }
      if (mparticleConsentState === 1) {
        if (!disableCookieOptOut) {
          MParticleAdapter.setOptOut(false);
        }
      }
      if (brazeConsentState === 1) {
        Braze.enableSDK();
      }
      if (branchConsentState === 1 && !branchUnavailable) {
        window.branch.disableTracking(false);
      }
    }
  }, [otConsentGroups, servicesLoaded, branchUnavailable, disableCookieOptOut]);

  return <div data-testid="one-trust-cookie-banner" />;
};
