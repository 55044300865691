import * as React from 'react';

import { IIcon, Icon } from '@rbilabs/components-library';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { useFlag } from 'state/launchdarkly';
import { CustomEventNames, useMParticleContext } from 'state/mParticle';
import { isExternalLink } from 'utils/is-external-link';
import { LaunchDarklyFlag } from 'utils/launchdarkly';

const LinkStyles = css`
  border-bottom: 1px solid ${Styles.color.grey.four};
  width: 100%;
  font-family: ${Styles.fontFamily.body};
  text-decoration: none;
  color: ${p => p.theme.token('text-default')};
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledLink = styled(Link)`
  ${LinkStyles}
`;
export const StyledAnchor = styled.a`
  ${LinkStyles}
`;

export const StyledLabel = styled.label`
  margin: 0;
  width: 100%;
`;

export const StyledIcon = styled.div`
  margin: 0 0.8rem 0 0;
`;

interface IArrowLinksProps<T extends Element = Element> {
  prefixIcon?: keyof IIcon;
  eventName?: (typeof CustomEventNames)[keyof typeof CustomEventNames];
  to: string;
  onClick?: (event: React.MouseEvent<T>) => void;
}

export const ArrowLink: React.FC<
  React.ComponentProps<
    typeof StyledLink | typeof StyledAnchor | typeof StyledLabel | typeof StyledIcon
  > &
    IArrowLinksProps
> = ({ children, eventName, onClick, prefixIcon, ...props }) => {
  const mParticle = useMParticleContext();

  const enableAccountMenuBrandIcons = useFlag(LaunchDarklyFlag.ENABLE_ACCOUNT_MENU_BRAND_ICONS);

  const handleClick = (e: React.MouseEvent) => {
    if (eventName) {
      mParticle.logNavigationClick(eventName);
    }

    if (onClick) {
      onClick(e);
    }

    return true;
  };

  return isExternalLink(props.to) ? (
    <StyledAnchor href={props.to} target="_blank" onClick={handleClick}>
      {children}
      <Icon
        icon="next"
        color="icon-default"
        width="16px"
        aria-hidden
        data-testid="icon-external-link-test-id"
      />
    </StyledAnchor>
  ) : (
    <StyledLink {...(props as IArrowLinksProps)} onClick={handleClick}>
      {enableAccountMenuBrandIcons && prefixIcon && (
        <StyledIcon>
          <Icon
            icon={prefixIcon}
            color="icon-default"
            aria-hidden
            width="24px"
            height="24px"
            data-testid={`${prefixIcon}-prefix-icon-test-id`}
          />
        </StyledIcon>
      )}
      <StyledLabel>{children}</StyledLabel>
      <Icon icon="next" color="icon-default" width="16px" aria-hidden data-testid="icon-test-id" />
    </StyledLink>
  );
};
