import React, { useState } from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { IDeliveryDriver } from '@rbi-ctg/menu';
import ActionButton, { ActionButtonVariants } from 'components/action-button';
import IconX from 'components/icons/x';
import courierMarkerSvg from 'pages/order-confirmation/delivery/icons/courier-image';
import { primitive } from 'styles/constants/primitives';

import theme from './theme';

const iconBase = 'data:image/svg+xml;charset=UTF-8;base64,';
const fallbackImgUrl = iconBase + btoa(courierMarkerSvg);

const Main = styled.div`
  display: flex;
  margin-bottom: 0.8125rem;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const Heading = styled.h4<{ large?: boolean }>`
  color: ${Styles.color.black};
  font-family: ${Styles.fontFamily.base};
  font-size: ${props => (props.large ? '1.675rem' : '1.125rem')};
  line-height: 1.3;
  margin: 0 0 1rem 0;
  padding: 0;
  text-transform: uppercase;
`;

const CourierInfoContainer = styled.div`
  display: flex;
`;

const CourierImg = styled.div`
  align-items: center;
  display: flex;
  margin-right: ${primitive.$spacing2};
  img {
    width: 2.55rem;
    height: 2.55rem;
    border-radius: 50%;
  }
`;

const CourierInfo = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  color: ${theme.courierInfoColor};
`;

const CourierName = styled.p`
  font-size: 0.9375rem;
  font-weight: 500;
  margin-right: ${primitive.$spacing2};
  padding: 0;
  max-width: ${primitive.$spacing10} + ${primitive.$spacing1};
  text-overflow: ellipsis;

  ${Styles.mobile`
    max-width: 5rem;
  `}
`;
// TODO: uncomment once make and model become available
// const VehicleInfo = styled.p`
//   font-size: 0.75rem;
//   font-weight: ${Styles.fontWeight.normal};
//   margin: 0;
//   padding: 0;
// `;

const ContactCourier = styled.div`
  align-items: center;
  justify-content: flex-end;
  display: flex;
  flex-wrap: wrap;
`;

const CourierContactInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: solid 1px ${Styles.color.grey.four};
  padding-top: 0.5rem;
`;

const CourierContactInfo = styled.div``;

const ContactPrompt = styled.p`
  font-size: 0.75rem;
  color: ${theme.contactPromptColor};
  padding: 0;
  margin: 0;
`;

const ContactInfo = styled.a`
  font-size: 0.9375rem;
  color: ${theme.contactInfoColor};
  padding: 0;
  margin: 0;
  text-decoration: none;
`;

const CloseContact = styled.button`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border: none;
  background-color: ${Styles.color.white};
  font-family: ${Styles.fontFamily.base};
  svg {
    width: 0.8125rem;
  }
`;

const ContactButton = styled(ActionButton)`
  font-size: 0.83rem;
  padding: ${primitive.$spacing2} ${primitive.$spacing4};
`;

interface ICourierDetailProps {
  driver: IDeliveryDriver;
}

const CourierDetail: React.FC<ICourierDetailProps> = ({ driver }) => {
  const [isContactVisible, setIsContactVisible] = useState<boolean>(false);
  const [imgUrl, setImgUrl] = useState(driver?.profileImageUrl || fallbackImgUrl);
  const { formatMessage } = useIntl();

  return (
    <>
      <Heading>Your Courier</Heading>
      <Main>
        <CourierInfoContainer>
          <CourierImg data-testid="driver-img">
            <img onError={() => setImgUrl(fallbackImgUrl)} src={imgUrl} alt="courier" />
          </CourierImg>
          <CourierInfo>
            <CourierName data-testid="driver-name" data-private data-dd-privacy="mask">
              {driver?.name || formatMessage({ id: 'noCourierName' })}
            </CourierName>
            {/* {TODO: uncomment this line once make and model become available} */}
            {/* <VehicleInfo>Make & Model</VehicleInfo> */}
          </CourierInfo>
        </CourierInfoContainer>
        <ContactCourier>
          <ContactButton
            variant={ActionButtonVariants.OUTLINE}
            fullWidth
            disabled={isContactVisible}
            onClick={() => setIsContactVisible(true)}
            value="contact"
            data-testid="driver-contact"
          >
            Contact
          </ContactButton>
        </ContactCourier>
      </Main>
      {isContactVisible && (
        <CourierContactInfoContainer>
          <CourierContactInfo>
            <ContactPrompt>{formatMessage({ id: 'contactCourier' })}</ContactPrompt>
            <ContactInfo
              href={`tel:${driver?.phoneNumber}`}
              data-testid="driver-phone"
              data-private
              data-dd-privacy="mask"
            >
              {driver?.phoneNumber || formatMessage({ id: 'noCourierPhone' })}
            </ContactInfo>
          </CourierContactInfo>
          <CloseContact
            onClick={() => setIsContactVisible(false)}
            data-testid="driver-contact-close"
          >
            <IconX fill={theme.iconXFill} />
          </CloseContact>
        </CourierContactInfoContainer>
      )}
    </>
  );
};

export default CourierDetail;
