import { ApolloClient, InMemoryCache } from '@apollo/client';

import { SupportedLanguages, SupportedRegions } from '@rbi-ctg/frontend';
import introspectionQueryResultData from 'generated/sanity-fragments.json';

import { getConfiguredLink } from './links';

const getConfiguredCache = () =>
  new InMemoryCache({
    typePolicies: {
      // couponId for national offers, in some cases there won't be a couponId but instead a cartEntry (which is an id of the cartEntry for the coupon)
      CouponUserOffersFeedbackEntry: {
        keyFields: ['couponId', 'cartEntry'],
      },
      // Using `lineId` to simplify things, but this causes lots of duplicate data (https://rbictg.atlassian.net/browse/GST-2318)
      CartEntries: {
        keyFields: ['lineId'],
      },
      // Using `rbiOrderId` causes lots of duplicate data
      // Two options to improve:
      // 1. Evict cache entries for orders you no longer will use (https://rbictg.atlassian.net/browse/GST-2304)
      // 2. Find a better way to identify if an order is the same (Crypto generate a key for the contents of the entire order??)
      Order: {
        keyFields: ['rbiOrderId'],
        fields: {
          // A better custom merge function may be needed here at some point. Keep an eye on order carts displaying bad data
          cart: {
            merge: true,
          },
        },
      },
      Item: {
        fields: {
          name: {
            merge: true,
          },
          description: {
            merge: true,
          },
        },
      },
      // Don't normalize vendorConfigs, normalizing this causes issues with historical orders overriding with new data
      // Always merge new data with the assumption that the parent is normalizing in a way that guarantees the vendorConfigs will always be the same
      // ---
      // For example, Orders/CartEntries can have different vendorConfigs for the same items based on when the order was placed
      // This is why Orders/CartEntries duplicate entries using a `uuid` that is specific to that order.
      // They duplicate a lot of the same data but in doing that we can guarantee that fields like `vendorConfigs` won't be overwritten with data that doesn't match that specific order.
      VendorConfigs: {
        keyFields: false,
        merge: true,
      },
    },
    possibleTypes: introspectionQueryResultData.possibleTypes,
  });

export const getClient = (
  language: SupportedLanguages,
  region: SupportedRegions,
  alternativeGqlApiUrl: string
) =>
  new ApolloClient({
    cache: getConfiguredCache(),
    link: getConfiguredLink(language, region, alternativeGqlApiUrl),
  });
