import { getAllLocalizedVersionsOfRoute, routes } from 'utils/routing';

export const APP_PLATFORM = 'app';

// The max number of allow custom attributes.
// We use the this value to determine when batching must occur.
// https://docs.mparticle.com/guides/default-service-limits/
export const LOG_EVENT_CUSTOM_ATTRIBUTES_MAX_COUNT = 100;

// all "boolean" type fields will be strings with one of the following two values
export const TRUE = 'True';
export const FALSE = 'False';

// inconsistent values for boolean string; could be any of the three strings
export const TRUE_VALS = ['Y', 'true', TRUE];
export const FALSE_VALS = ['N', 'false', FALSE];

// One indexing offset
export const ONE_INDEX_OFFSET = 1;

// Defines the default value for the 'layer' property
// This property is sent on frontend events and it's helpful to differentiate
// frontend events from backend events on mParticle and Amplitude
export const LAYER_ATTRIBUTE_DEFAULT_VALUE = 'FE';

// Defines keys to be used for mParticle reporting
// The keys will be used to group messages so it's important to be consistent
// when calling an error or custom event
//
// Add new actions here
export enum CustomEventNames {
  ADD_FAVORITE_TO_CART = 'Add Favorite to Cart',
  ADD_ITEM_TO_FAVORITES = 'Add Item To Favorites',
  ADD_ITEMS_TO_FAVORITE = 'Add Items to Favorite',
  ADD_RECENT_ITEM_TO_CART = 'Add Recent Item To Cart',
  ADDONS_ITEM_DISPLAYED = 'Addons Item Displayed',
  APP_ERROR = 'Application Error',
  APP_REVIEW_POP_UP = 'App Review Pop Up',
  APP_ERROR_RELOAD = 'Application Error Reload Click',
  APPFLOW_UPDATE_APPLIED = 'Appflow Update Applied',
  APPFLOW_UPDATE_DOWNLOADED = 'Appflow Update Downloaded',
  APPFLOW_UPDATE_EXTRACTED = 'Appflow Update Extracted',
  APPFLOW_UPDATE_STARTED = 'Appflow Update Started',
  APPFLOW_UPDATE_UNKNOWN = 'Appflow Update Unknown',
  APP_TRACKING_REQUEST_PERMISSION = 'App Tracking Request Permission',
  BOTTOM_SERVICE_MODE = 'Bottom Service Mode',
  BOTTOM_SERVICE_CART = 'Bottom Service Cart',
  BUTTON_CLICK = 'Button Click',
  BUTTON_CLICK_ACCESSIBILITY = 'Accessibility',
  BUTTON_CLICK_ACCEPT_COOKIES = 'Accepted Cookies',
  BUTTON_CLICK_REJECT_COOKIES = 'Rejected Cookies',
  BUTTON_CLICK_ACCEPTANCE_AGREEMENTS_CONTINUE = 'Accepted updated agreement',
  BUTTON_CLICK_ACCEPTANCE_AGREEMENTS_DISAGREE = 'Disagree updated agreement',
  BUTTON_CLICK_ACCOUNT_DELETE = 'Account Delete',
  BUTTON_CLICK_ACCOUNT_DELETE_ONE_TRUST = 'Account Delete One Trust',
  BUTTON_CLICK_ACCOUNT_INFO = 'Account Info',
  BUTTON_CLICK_ADD_PAYMENT_METHOD = 'Add New Payment Method',
  BUTTON_CLICK_CASH_OR_VOUCHER = 'Cash or Voucher method selected.',
  BUTTON_CLICK_APPFLOW_TRY_AGAIN = 'Appflow Try Again',
  BUTTON_CLICK_BK_REWARDS = 'BK Rewards',
  BUTTON_CLICK_CAREERS = 'Careers',
  BUTTON_CLICK_CATERING = 'Catering',
  BUTTON_CLICK_CATERING_ACCEPT = 'Catering Accept',
  BUTTON_CLICK_CATERING_DECLINE = 'Catering Decline',
  BUTTON_CLICK_CATERING_DELIVERY = 'Catering Delivery Mode Selected',
  BUTTON_CLICK_CATERING_PICKUP = 'Catering Pick Up Mode Selected',
  BUTTON_CLICK_CHANGE_DELIVERY_ADDRESS = 'Change delivery address',
  BUTTON_CLICK_COMMUNICATION_PREFERENCES = 'Communication Preferences',
  BUTTON_CLICK_COMPANY = 'Company',
  BUTTON_CLICK_DELIVER_HERE = 'Deliver Here',
  BUTTON_CLICK_DELIVERY = 'Delivery Mode Selected',
  BUTTON_CLICK_DRAWER_REWARDS = 'Rewards',
  BUTTON_CLICK_EARN_POINTS = 'Earn Points',
  BUTTON_CLICK_EMAIL_PROFILE_INFO = 'Email Me My Profile Info',
  BUTTON_CLICK_FOUNDATION = 'Foundation',
  BUTTON_CLICK_FRANCHISEE_OPPORTUNITIES = 'Franchisee Opportunities',
  BUTTON_CLICK_INVESTOR_RELATIONS = 'Investor Relations',
  BUTTON_CLICK_INVITATION_CODE = 'Invitation Code',
  BUTTON_CLICK_LEGAL = 'Legal',
  BUTTON_CLICK_LOGO = 'Brand Name',
  BUTTON_CLICK_MENU = 'MENU',
  BUTTON_CLICK_MENU_WIZARD_CONTINUE = 'Menu Wizard Continue',
  BUTTON_CLICK_MY_ACCOUNT = 'My Account',
  BUTTON_CLICK_NO_DELIVERY_OFFER_FIND_RESTAURANTS = 'Find Restaurants',
  BUTTON_CLICK_NO_DELIVERY_OFFER_SHOW_DEALS = 'Show me Deals',
  BUTTON_CLICK_NO_DELIVERY_PARTNERS = 'See Delivery Partners',
  BUTTON_CLICK_OFFERS = 'Offers',
  BUTTON_CLICK_ORDER = 'Order',
  BUTTON_CLICK_ORDER_MISSING_ITEMS_SUBMIT = 'Item(s) Never Arrived',
  BUTTON_CLICK_ORDER_NEVER_ARRIVED_SUBMIT = 'Order Never Arrived',
  BUTTON_CLICK_OTHER_LOCATION = 'Click Change Store Button',
  BUTTON_CLICK_OUR_STORY = 'Our Story',
  BUTTON_CLICK_PAYBACK = 'Payback',
  BUTTON_CLICK_PAYMENT_METHODS = 'Payment Methods',
  BUTTON_CLICK_PICKUP = 'Pick Up Mode Selected',
  BUTTON_CLICK_PLACE_ORDER = 'Place Order',
  BUTTON_CLICK_PLK_REWARDS = 'Popeyes Rewards',
  BUTTON_CLICK_PRIVACY_POLICY = 'Privacy Policy',
  BUTTON_CLICK_PROMOTION_TILE = 'Clicked Promotion Tile',
  BUTTON_CLICK_RECENT_ORDER_NEED_HELP = 'Need Help',
  BUTTON_CLICK_RECENT_ORDERS = 'Recent Orders',
  BUTTON_CLICK_REGISTER_PHYSICAL_CARD = 'Register Physical Card',
  BUTTON_CLICK_REQUEST_MY_INFORMATION = 'Request My Information',
  BUTTON_CLICK_RESTAURANTS = 'Restaurants',
  BUTTON_CLICK_SEND_A_GIFT = 'Send a Gift',
  BUTTON_CLICK_SERVICE_MODE_TOGGLE = 'Service Mode Toggle Click',
  BUTTON_CLICK_SCAN_FOR_REWARDS = 'Scan for Rewards',
  BUTTON_CLICK_SCAN_TO_PAY = 'Scan to Pay',
  BUTTON_CLICK_SIGN_IN = 'Sign In',
  BUTTON_CLICK_SIGN_UP = 'Sign Up',
  BUTTON_CLICK_SIGN_OUT = 'Sign Out',
  BUTTON_CLICK_SITEMAP = 'Sitemap',
  BUTTON_CLICK_STATIC_PAGES = 'Static Pages',
  BUTTON_CLICK_STORE_BUTTON = 'Store Button Clicked',
  BUTTON_CLICK_STORE_LOCATOR_TAB = 'Store Locator Tab',
  BUTTON_CLICK_STORE_LOCATOR_VIEW = 'Store Locator View',
  BUTTON_CLICK_STORE_SELECTED = 'Store Selected',
  BUTTON_CLICK_SUPPORT = 'Support',
  BUTTON_CLICK_TABLE_SERVICE = 'Table Service Mode Selected',
  BUTTON_CLICK_TERMS_OF_USE = 'Terms of Use',
  BUTTON_CLICK_TIMS_CARD = 'Tims Card',
  BUTTON_CLICK_TIMS_CARD_RELOAD = 'Tims Card Reload',
  BUTTON_CLICK_TIMS_REWARDS = 'Tims Rewards',
  BUTTON_CLICK_TRENDING = 'Trending',
  BUTTON_CLICK_USER_PREFERENCES = 'User Preferences Modal',
  BUTTON_CLICK_VIEW_ALL_OFFERS = 'View all offers',
  CANCEL_CATERING_ORDER = 'Cancel Catering Order',
  CATERING_DATE_TIME_SELECT = 'Catering Date Time Event',
  CARD = 'Card',
  CHECKOUT = 'Checkout',
  CHECKOUT_DELIVERY_MINIMUM_NOT_REACHED = 'Checkout delivery minimum not reached',
  CHECKOUT_LIMIT_REACHED = 'Checkout limit reached',
  CHECKOUT_MOBILE = 'Mobile Checkout',
  CHALLENGE_COMPLETE = 'Challenge Completed',
  CLICK_EVENT = 'Click',
  DELETE_PAYMENT_METHOD = 'Delete Payment Method',
  EDIT_CART = 'Edit Cart',
  EDIT_FAVORITE = 'Edit Favorite',
  ERROR = 'Error',
  EXTERNAL_OFFER_ADDED_TO_ORDER = 'External Offer Added to Order',
  EXTERNAL_OFFER_REDEEMED = 'External Offer Redeemed',
  EXTERNAL_OFFER_SELECTED = 'External Offer Selected',
  FLAGS_EVALUATED = 'Flags Evaluated',
  FIRST_DELIVERY_QUOTE = 'First Delivery Quote',
  GIFT_CARD_BALANCE_TRANSFER = 'Gift Card Balance Transfer',
  HERO_COMPLEMENTARY_CTA_CLICK = 'Hero Complementary CTA Click',
  HERO_PRIMARY_CTA_CLICK = 'Hero Primary CTA Click',
  LOAD_MORE = 'Load More',
  LOCALE_SELECTOR_MODAL_SHOWN = 'Locale Selector Modal Shown',
  LOYALTY_CODE_SUBMITTED = 'Loyalty Code Submitted',
  LOGIN_CONFIRM_JWT_EMAIL = 'Confirm jwt local storage email',
  LOYALTY_ACKNOWLEDGEMENT = 'Loyalty Acknowledgement',
  LOYALTY_CARD_DEACTIVATED = 'Loyalty card deactivated',
  LOYALTY_LOAD = 'Loyalty Load',
  MANUAL_LOCATION_SEARCH = 'Manual location search',
  MARKETING_TILE_CLICK = 'Marketing Tile Click',
  MARKETING_TILE_CAROUSEL = 'Marketing Tile Carousel',
  MEMBERSHIPS = 'Memberships',
  MOBILE_NAV_ORDER = 'Mobile Nav Order',
  MODAL_APPEARANCE = 'Modal Appearance',
  NETWORK_ERROR = 'Network Error Modal',
  NETWORK_ERROR_RELOAD = 'Network Error Reload Click',
  NAV_CLICK = 'Mobile Navigation Link Click',
  OFFER_ACTIVATED = 'Offer activated',
  OFFER_ADDED = 'Offers added to cart',
  OFFER_ADDED_TO_ORDER = 'Offer Added to Order',
  OFFER_CLICK = 'Offer Click',
  OFFER_CODE_REQUESTED = 'Offer Code Requested',
  OFFER_FAVORITE_ADDED = 'Offer Favorite Added',
  OFFER_FAVORITE_REMOVED = 'Offer Favorite Removed',
  OFFER_REDEMPTION_CANCELLED = 'Offer redeemed cancelled',
  OFFER_REDEMPTION_CONFIRMED = 'Offer redeemed confirmed',
  OFFER_SELECTED = 'Offer Selected',
  OFFER_SELECTED_CLEARED = 'Cleared selected offer',
  OFFER_SELECTED_MOBILE = 'Mobile offer selected',
  OFFER_SELECTED_RESTAURANT = 'Restaurant offer selected',
  ORDER_HERE_STORE_CONFIRMATION_FLOW = 'Order Here Store Confirmation Flow Initiated',
  ORDER_LATENCY_COMMIT = 'Order Latency Event, Commit',
  ORDER_LATENCY_PRICING = 'Order Latency Event, Price',
  OFFERS_DISPLAYED = 'Offers Displayed',
  OTP_SIGN_IN_VALIDATION = 'One Time Password Code Validation', // Deprecated
  OTP_SIGN_IN_START = 'One Time Password Sign In Start', // Deprecated
  OTP_SIGN_UP = 'OTP Sign Up Complete',
  PHYSICAL_CARD_REGISTERED = 'Physical card registered',
  PROMO_CARD_REGISTERED = 'Promo Card Registered',
  PROMO_CODE_APPLIED = 'Promo Code Applied',
  PURCHASE = 'Purchase',
  PUSH_NOTIFICATIONS_RESPONSE = 'Push Notifications Response',
  RBI_LOGGER = 'RBI Logger Event',
  RECENT_ITEMS_DISPLAYED = 'Recent Items Displayed',
  REDEEM_OFFER = 'Redeem Offer',
  RESTAURANTS_FOUND = 'Restaurants Found',
  REWARD_ADDED_TO_ORDER = 'Reward Added to Order',
  REWARD_SELECTED = 'Reward Selected',
  REWARD_TIER_CHANGED = 'Reward tier changed',
  RUTR_BUTTON_CLICK_DONE_WITH_GAMEPLAY = 'Rutr Done with gameplay',
  RUTR_BUTTON_CLICK_HOME_PAGE_TIME_TO_PLAY = 'Rutr Home Time to play',
  RUTR_BUTTON_CLICK_RRROLL_FOR_ME_FROM_GAMEPLAY = 'Rutr Roll for me',
  RUTR_BUTTON_CLICK_RRROLL_NOW_LANDING_PAGE_ENTER_GAMEPLAY = 'Rutr Landing Enter Gameplay',
  RUTR_BUTTON_CLICK_SHARE_ROLL = 'Rutr Share roll',
  RUTR_BUTTON_CLICK_SKILLS_CHECK_PASSED = 'Rutr Skills Check Passed',
  RUTR_BUTTON_CLICK_SKILLS_CHECK_FAILED = 'Rutr Skills Check Failed',
  RUTR_LANDING_PAGE = 'Rutr Landing Page',
  RUTR_PRIZE_URL_CLICKED = 'Rutr Prize URL Clicked',
  RUTR_SWIPE_TO_RRROLL = 'Rutr Swipe to roll',
  SAVE_NEW_PAYMENT_METHOD = 'Save New Payment Method',
  SELECT_CATERING_SERVICE_MODE = 'Select Catering Service Mode',
  SELECT_DELIVERY_RECENT_ADDRESS = 'Select Delivery Recent Address',
  SELECT_DELIVERY_SAVED_ADDRESS = 'Select Delivery Saved Address',
  SELECT_SERVICE_MODE = 'Select Service Mode',
  SELECT_TIMED_FIRE_OPTION = 'Select Timed Fire Option',
  SESSION_RESET_FROM_INACTIVITY = 'Session Reset From Inactivity',
  SIGN_IN_COMPLETE = 'Sign In Complete',
  SIGN_IN_FAILED = 'Sign In Failed',
  SIGN_IN_START = 'Sign In Start', // Deprecated
  SIGN_IN_START_LEGACY = 'Sign In Start Legacy',
  SIGN_IN_SUBMITTED = 'Sign In Submitted',
  SIGN_OUT = 'Sign Out Complete',
  SIGN_UP = 'Sign Up Complete',
  SIGN_UP_FAILED = 'Sign Up Failed',
  SIGN_UP_SUBMITTED = 'Sign Up Submitted',
  SIGN_UP_SUCCESSFUL = 'Sign Up Successful',
  SIGN_UP_TERM_TOGGLE = 'Sign Up Term Toggled',
  SNAP_QR_CODE = 'Snap QR Code',
  STORE_DELIVERY_INVALID_HOO = 'Store Invalid Hours of Operation for Delivery',
  SUPPORT_FORM_SUBMIT = 'Submit Support Form',
  SUPPORT_FORM_NAVIGATION_DECISION = 'Support Form Navigation Decision',
  TAB = 'Tab',
  TABBED_HEADER = 'Tabbed Header Click',
  UNEXPECTED_SIGN_OUTS = 'Unexpected Sign Outs',
  UPDATE_USER_ATTRIBUTES = 'Update User Attributes',
  UPDATE_USER_IDENTITY = 'Update User Identity',
  UPSELL_ADDED = 'Upsell Added',
  UPSELL_DISPLAYED = 'Upsell Displayed',
  UPSELL_REMOVED = 'Upsell Removed',
  AUTO_SIGN_IN = 'Auto Sign In',
  AUTO_SIGN_IN_START = 'Auto Sign In Start',
  AUTO_SIGN_IN_COMPLETE = 'Auto Sign In Complete',
  SUCCESSFUL_CUSTOMER_INITIATED_REFUND = 'Successful Customer Initiated Refund',
  SWAP_OFFER_USED = 'Swap Offer Used',
  TOP_SERVICE_MODE = 'Top Service Mode',
  REFUND_ELIGIBILITY_CHECK = 'Refund Eligibility Check',
  FAILED_CUSTOMER_INITIATED_REFUND = 'Failed Customer Initiated Refund',
  NETWORK_ERROR_CUSTOMER_INITIATED_REFUND = 'Network Error Customer Initiated Refund',
  PAGE_NOT_FOUND = 'Page not found',
  TEST_USER_LOCATION = 'Test User Location',
  PAGE_VIEW = 'Page View',
  PROMO_CODE_FIELD_EXPANDED = 'Promo Code Field Expanded',
  PROMO_CODE_FIELD_CLOSED = 'Promo Code Field Closed',
  TH_ROUND_UP_STATUS_TOGGLE = 'TH Round-Up Status Toggle',
  APP_INITIALIZED = 'Application Initialized',
  CLOSE_GHOST_DETECTOR = 'Close Ghost Detector',
  MESSAGE_FROM_GHOST_DETECTOR = 'Message from Ghost Detector',
  ACTION_SHEET_VIEW = 'Action Sheet View',
  APP_RATING = 'App Rating',
  ACTION_SHEET_SUBMISSION = 'Action Sheet Submission',
  SETTINGS = 'Settings',
  SETTINGS_SELECT_LOCATION = 'Select a Location',
  CATERING_BIRTHDAY = 'Catering & Birthday',
  CATERING_BIRTHDAY_EVENT = 'Catering Birthday Event',
  INVITATION_CODE_COPIED = 'Invitation Code Copied',
  INVITATION_CODE_SHARED = 'Invitation Code Shared',
}

export enum ClickEventComponentNames {
  NAV_BAR = 'Nav Bar',
  PRODUCT_SECTION_TABS = 'Product Sections Tabs',
  PRODUCT_SECTION_TILES = 'Product Sections Tiles',
  MARKETING_TILE = 'Marketing Tile',
  PRODUCTS = 'Products',
  PRODUCT_MODIFIER_CUSTOMIZATION = 'Product Modifier Customization',
  PRODUCT_SELECTION_MODIFICATION = 'Product Selection Modification',
  BUTTON = 'Button',
  PROMO_CODE_TILE = 'Promo Code Tile',
  ACCOUNT_SETTINGS_GEOLOCATION = 'Account Settings Geolocation',
}

export enum SignInPhases {
  START = 'start',
  COMPLETE = 'complete',
}

export enum EventTypes {
  Navigation = 1,
  Location = 2,
  Search = 3,
  Transaction = 4,
  UserContent = 5,
  UserPreference = 6,
  Social = 7,
  Other = 8,
}

export enum ProductActionTypes {
  Unknown = 0,
  AddToCart = 1,
  RemoveFromCart = 2,
  Checkout = 3,
  CheckoutOption = 4,
  Click = 5,
  ViewDetail = 6,
  Purchase = 7,
  Refund = 8,
  AddToWishlist = 9,
  RemoveFromWishlist = 10,
}

export const TRACKED_PAGES = [
  routes.about,
  routes.addCard,
  routes.cart,
  routes.cartAddCard,
  routes.info,
  routes.offers,
  routes.orderConfirmation,
  routes.orders,
  ...getAllLocalizedVersionsOfRoute('signIn'),
  ...getAllLocalizedVersionsOfRoute('signUp'),
  ...getAllLocalizedVersionsOfRoute('storeLocator'),
  ...getAllLocalizedVersionsOfRoute('sendGift'),
  routes.store,
  // @todo are these routes accurate?
  '/checkin',
  '/checkout',
  '/comboselections',
  '/coupons',
  '/coupons/couponbuilder',
  '/coupons/shortcode',
  '/favorites',
  '/forgotpassword',
  '/loginlegacy',
  '/loginoptions',
  '/menu/productupsell',
  '/menu/specialoffersize',
  '/menucategory',
  '/nickname',
  '/nutritioninfo',
  '/orderdetails',
  '/paymenttypes',
  '/privacypolicy',
  '/productcustomize',
  '/reorder',
  '/restaurantfilters',
  '/restaurants',
  '/settings',
  '/settings/managesubscriptions',
  '/signupoptions',
  '/specialofferitemschooser',
  '/specialoffermealbuilder',
  '/splash',
  '/storedetails',
  '/storeselection',
  '/submitfeedback',
  '/subscriptioncheckout',
  '/terms',
  '/timdubienauquotidien',
  '/tims-for-good',
  '/tims-rewards',
  '/verificationcode',
];

// Events to be skipped when happened consecutively within a time margin.
export const SKIP_EVENTS: string[] = [CustomEventNames.MODAL_APPEARANCE];

// Page name used in Page View event
export const EVENT_PAGE_NAMES = {
  ExternalOffersDetail: 'External Offer Detail',
  MyBurgerKing: 'MyBurgerKing',
  MyBurgerKingLoyaltyTiers: 'MyBurgerKing (Loyalty Tiers)',
  Rewards: 'Rewards',
  RewardDetail: 'Reward Detail',
};

/**
 * CNAME domains per market
 */
export const CNAME_DOMAINS = {
  bk_ch: 'mp.burger-king.ch',
};
