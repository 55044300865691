import styled from 'styled-components';

import CloseButton from 'components/close-button';
import { FIXED_STICKY_FOOTER_HEIGHT } from 'components/sticky-footer/constants';
import { primitive } from 'styles/constants/primitives';

export const ModalCloseButton = styled(CloseButton)`
  inset-inline-start: calc(1.25rem + env(safe-area-inset-left));
  position: fixed;
  inset-block-start: calc(1rem + env(safe-area-inset-top));
  z-index: ${Styles.zIndex.below};
  color: ${p => p.theme.token('text-button-primary')};
  background: transparent;
`;

export const Background = styled.div`
  position: relative;
  background: ${({ theme }) => theme.token('background-pattern')};
`;

export const AllModalContent = styled.div`
  background-color: ${p => p.theme.token('background-pattern')};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const RewardCategoriesContainer = styled.div`
  padding-top: ${primitive.$spacing11};
  ${Styles.mobile`
    padding-bottom: calc(env(safe-area-inset-bottom) + ${FIXED_STICKY_FOOTER_HEIGHT} + 2rem);
  `}
`;

export const PositionedLogoContainer = styled.div`
  inset-inline-start: 0;
  inset-block-start: 0;
  width: 100%;
  min-height: 62px;
  background-color: ${({ theme }) => theme.token('background-active')};
  padding-top: env(safe-area-inset-top);
  position: fixed;
  display: grid;
  place-items: center;

  z-index: ${Styles.zIndex.normal - 101};

  & > a {
    padding: ${primitive.$spacing4} 0;
  }
`;
