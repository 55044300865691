import { useCallback, useState } from 'react';

import { gql, useMutation } from '@apollo/client';
import { Gender } from '@rbilabs/intl-common';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { isValid } from 'date-fns';
import { LDUser } from 'launchdarkly-js-client-sdk';
import { cloneDeepWith, isPlainObject, pickBy } from 'lodash';
import { useIntl } from 'react-intl';
import uuid from 'uuid';

import { DeepPartial } from '@rbi-ctg/frontend';
import { ISocialLoginInput } from 'generated/graphql-gateway';
import {
  CreateOtpDocument,
  ICreateOtpMutation,
  ICreateOtpMutationVariables,
  IRequiredAcceptanceAgreementInfo,
  IRequiredAcceptanceAgreementInfoInput,
  ProviderType,
  useSignInJwtMutation,
  useSignUpMutation,
  useValidateAuthJwtMutation,
  useValidateOtpMutation,
} from 'generated/rbi-graphql';
import useEffectOnce from 'hooks/use-effect-once';
import { ModalCb } from 'hooks/use-error-modal';
import { useOtpFeature } from 'hooks/use-otp-feature';
import { useSocialLogin } from 'hooks/use-social-login';
import { SignInMethods } from 'pages/authentication/sign-up/types';
import {
  signOut as cognitoSignOut,
  signUp as cognitoSignUp,
  validateLogin as cognitoValidateLogin,
} from 'remote/auth/cognito';
import {
  GetStaticPageAcceptanceAgreementQuery,
  IStaticPageRoute,
} from 'remote/queries/static-page';
import { useAuthGuestContext } from 'state/auth-guest';
import { useFlag, useLDContext } from 'state/launchdarkly';
import { SwitchUpdateDateAcceptanceAgreement } from 'state/launchdarkly/variations';
import { useLocationContext } from 'state/location';
import { useMParticleContext } from 'state/mParticle';
import { SignInPhases } from 'state/mParticle/constants';
import { useStaticPageRoutes } from 'state/static-page-manager/hooks/use-static-page-routes';
import { platform, welcomeEmailDomain } from 'utils/environment';
import { parseGraphQLErrorCodes } from 'utils/errors';
import { GraphQLErrorCodes } from 'utils/errors/types';
import { ISOs } from 'utils/form/constants';
import { parseJwt } from 'utils/jwt';
import { LaunchDarklyFlag } from 'utils/launchdarkly';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { OTPAuthDeliveryMethod, isOTPEmailEnabled, isOTPSMSEnabled } from 'utils/otp';
import { routes } from 'utils/routing';

import { SIGN_IN_FAIL } from '../constants';
import { JwtValidationError, OtpValidationError, UserNotFoundError } from '../errors';

import { useThirdPartyAuthentication } from './use-third-party-authentication';

const NON_SESSION_SPECIFIC_STORAGE_KEYS = [
  StorageKeys.LANGUAGE,
  StorageKeys.REGION,
  StorageKeys.HAS_SHOWN_LOCALE_SELECTOR,
  StorageKeys.LAST_TIME_COOKIES_ACCEPTED,
  StorageKeys.TABLE_NUMBER,
  StorageKeys.QUEST_COMPLETED_UNLOCKED_INCENTIVE,
];

export interface ISocialLoginParams extends ISocialLoginInput {
  readonly throwError?: boolean;
}

export interface IUseAccountAuthentication {
  refreshCurrentUser(): Promise<void>;
  openErrorDialog: ModalCb;
  setCurrentUser(session: null | CognitoUserSession): void;
}

interface INavigateOptions {
  state: {
    email: string;
  };
}

interface ISignInNavigation {
  navigateOnSuccess?: boolean;
  navigateState?: INavigateOptions;
}

interface ISignInUserParams {
  email?: string;
  phoneNumber?: string;
}

export type ISignIn = ISignInNavigation & ISignInUserParams;

interface ISignUp {
  email: string;
  name: string;
  dob?: string;
  phoneNumber: string;
  country: string;
  wantsPromotionalEmails: boolean;
  zipcode?: string;
  gender?: Gender;
  providerType?: ProviderType;
  invitationCode?: string;
}

export interface ISignUpResult {
  jwt: string | null | undefined;
  cognitoId: string | null;
}

interface IValidateLogin {
  jwt: string;
  username: string;
}

interface IGetSesionIdAndChallengeCodeOtp {
  email: string;
  otpCode: string;
  sessionId: string;
}

type IStoreOtpCredentials = { sessionId: string } & ISignInUserParams;

export const getStoredOtpCredentials = () => LocalStorage.getItem(StorageKeys.OTP);
export const storeOtpCredentials = (data: IStoreOtpCredentials) =>
  LocalStorage.setItem(StorageKeys.OTP, data);

export const useAccountAuthentication = ({
  refreshCurrentUser,
  openErrorDialog,
  setCurrentUser,
}: IUseAccountAuthentication) => {
  const { formatMessage } = useIntl();
  const { attemptGetUpdatedLdFlag } = useLDContext();
  const { signInEvent: mParticleSignInEvent, signOutEvent: mParticleSignOutEvent } =
    useMParticleContext();
  const { logUserOutOfThirdPartyServices } = useThirdPartyAuthentication();

  const switchUpdatedAteAcceptanceAgreement =
    useFlag<SwitchUpdateDateAcceptanceAgreement>(
      LaunchDarklyFlag.SWITCH_UPDATE_DATE_ACCEPTANCE_AGREEMENT
    ) ?? [];

  const enableSignUpInBE = useFlag(LaunchDarklyFlag.ENABLE_COGNITO_SIGNUP_IN_BE);
  const enableUserNotFoundMaskAuthFlow = useFlag(
    LaunchDarklyFlag.ENABLE_USER_NOT_FOUND_MASK_ON_AUTH_FLOW
  );
  const enableLoginFromUS = useFlag(LaunchDarklyFlag.ENABLE_LOGIN_FROM_US_IF_USER_NOT_IN_EU);
  const { enableOtpFlagValue } = useOtpFeature();
  const preloaded = LocalStorage.getItem(StorageKeys.AUTH_REDIRECT) || {};
  const [originLocation, setOriginLoc] = useState<null | string>(preloaded.callbackUrl || null);
  const {
    navigate,
    location: { search },
  } = useLocationContext();
  // Track sign in otp method separatly from `enableOtpFlagValue` so that changes
  // in `enableOtpFlagValue` does not affect the sign in method used.
  const [signInOtpMethod, setSignInOtpMethod] = useState<OTPAuthDeliveryMethod>(enableOtpFlagValue);

  const [signInJwtMutation, { loading: signInMutationLoading }] = useSignInJwtMutation();
  const [validateAuthJwtMutation, { loading: validateAuthMutationLoading }] =
    useValidateAuthJwtMutation();

  const { signOut: guestSignOut } = useAuthGuestContext();

  /*
  This code ensures that the user service and whitelabel service can be deployed in any order.
  Based on an LD flag, we use either the original CreateOtpDocument (which doesn't have the redirectToUS flag)
  or we use the new CreateOtpDocumentWithUSRedirect, which includes the field.
  In this way, we prevent the front-end from making a request to graphql with a field that might not be there yet
  */
  const CreateOtpDocumentWithUSRedirect = gql`
    mutation CreateOTP($input: CreateOTPInput!) @gateway {
      createOTP(input: $input) {
        maxValidateAttempts
        ttl
        redirectToUS
      }
    }
  `;
  const [createOtpMutation, { loading: createOtpMutationLoading }] = useMutation<
    ICreateOtpMutation,
    ICreateOtpMutationVariables
  >(!enableLoginFromUS ? CreateOtpDocument : CreateOtpDocumentWithUSRedirect);
  const [validateOtpMutation, { loading: validateOtpMutationLoading }] = useValidateOtpMutation();
  const [signUpMutation] = useSignUpMutation();

  const { socialLoginMutate } = useSocialLogin();

  const getSessionIdAndChallengeCodeOtp = useCallback(
    async ({ email, otpCode, sessionId }: IGetSesionIdAndChallengeCodeOtp) => {
      const { data } = await validateOtpMutation({
        variables: {
          input: {
            code: otpCode,
            email,
            sessionId,
          },
        },
      });

      const { sessionId: validatedSessionId, challengeCode } =
        data?.exchangeOTPCodeForCognitoCredentials ?? {};

      if (!validatedSessionId || !challengeCode) {
        throw new OtpValidationError('GraphQL validation failed');
      }

      return { sessionId: validatedSessionId, code: challengeCode };
    },
    [validateOtpMutation]
  );

  const getSessionIdAndChallengeCode = useCallback(
    async (jwt: string) => {
      try {
        const { data } = await validateAuthJwtMutation({
          variables: {
            input: { jwt },
          },
        });
        const { sessionId, challengeCode } = data?.validateAuthJwt ?? {};
        if (!sessionId || !challengeCode) {
          throw new JwtValidationError();
        }

        return { sessionId, code: challengeCode };
      } catch (error) {
        if (error?.originalError?.[0]?.message?.toLowerCase() === 'email not registered') {
          throw new UserNotFoundError();
        }
        throw error;
      }
    },
    [validateAuthJwtMutation]
  );

  const signOut = useCallback(async () => {
    try {
      await cognitoSignOut();

      setCurrentUser(null);
      // remove USER_SIGNED_IN_SUCCESSFULLY to avoid mis-trigged unexpected sign outs events
      LocalStorage.removeItem(StorageKeys.USER_SIGNED_IN_SUCCESSFULLY);

      const user = LocalStorage?.getItem(StorageKeys.USER)?.cognitoId;
      if (user) {
        // Once we have namespaced auth storage for all platforms we can remove the excluded
        // keys from here but for now we need to make sure we don't wipe all LocalStorage
        LocalStorage.clear({ excludeKeys: NON_SESSION_SPECIFIC_STORAGE_KEYS });

        mParticleSignOutEvent(true);
        logUserOutOfThirdPartyServices();
      }
    } catch (error) {
      mParticleSignOutEvent(false, error.message);
      refreshCurrentUser();
      throw error;
    }
  }, [setCurrentUser, mParticleSignOutEvent, logUserOutOfThirdPartyServices, refreshCurrentUser]);

  const signInWithOtp = async (param: { email: string } | { phoneNumber: string }) => {
    const sessionId = uuid();
    await createOtpMutation({
      variables: {
        input: {
          ...param,
          platform: platform(),
          sessionId,
        },
      },
    });
    storeOtpCredentials({ ...param, sessionId });

    return (navigateState?: INavigateOptions) => {
      navigate(routes.confirmOtp + search, navigateState);
    };
  };

  const signInWithJwt = async (email: string) => {
    LocalStorage.setItem(StorageKeys.LOGIN, email);
    await signInJwtMutation({
      variables: {
        input: {
          email,
          stage: welcomeEmailDomain(),
          platform: platform(),
        },
      },
    });
    return (navigateState?: INavigateOptions) => {
      navigate(routes.authChallengeJwt, navigateState);
    };
  };

  const getOtpFlagValueForAttributes = useCallback(
    async (ldAttributes: DeepPartial<LDUser>) => {
      // omit nested empty/null values
      const attributes = cloneDeepWith(ldAttributes, value =>
        isPlainObject(value) ? pickBy(value, (v: any) => !!v) : !!value
      );

      const flagValue = await attemptGetUpdatedLdFlag(
        LaunchDarklyFlag.ENABLE_ONE_TIME_PASSWORD,
        attributes,
        OTPAuthDeliveryMethod.None
      );
      return flagValue;
    },
    [attemptGetUpdatedLdFlag]
  );

  const signInUsingEnabledMethod = async ({
    email,
    phoneNumber,
    otpMethod,
  }: Pick<ISignIn, 'email' | 'phoneNumber'> & { otpMethod: OTPAuthDeliveryMethod }) => {
    const isEmailEnabled = isOTPEmailEnabled(otpMethod);
    const isSMSEnabled = isOTPSMSEnabled(otpMethod);

    if (isSMSEnabled && phoneNumber) {
      return signInWithOtp({ phoneNumber });
    }

    if (!email) {
      throw new Error('No email provided and SMS login disabled');
    }

    if (isEmailEnabled) {
      return signInWithOtp({ email });
    }

    return signInWithJwt(email);
  };

  const signIn = async ({
    email,
    phoneNumber,
    navigateOnSuccess = true,
    navigateState,
  }: ISignIn) => {
    const otpMethod: OTPAuthDeliveryMethod = await getOtpFlagValueForAttributes({
      email,
      custom: { phoneNumber },
    });
    setSignInOtpMethod(otpMethod);
    try {
      const redirect = await signInUsingEnabledMethod({ email, phoneNumber, otpMethod });
      mParticleSignInEvent({
        phase: SignInPhases.START,
        success: true,
        otpMethod,
        method: SignInMethods.OTP,
      });
      if (navigateOnSuccess) {
        redirect(navigateState);
      }
    } catch (error) {
      const createOtpError = enableUserNotFoundMaskAuthFlow
        ? GraphQLErrorCodes.CREATE_OTP_FAILED
        : GraphQLErrorCodes.AUTH_EMAIL_NOT_REGISTERED;
      const notRegisteredError = parseGraphQLErrorCodes(error)
        .map(err => err.errorCode)
        .includes(createOtpError);
      if (!notRegisteredError) {
        mParticleSignInEvent({
          phase: SignInPhases.START,
          success: false,
          message: error.message,
          otpMethod,
          method: SignInMethods.OTP,
        });
      }
      error.code = SIGN_IN_FAIL;
      throw error;
    }
  };

  const {
    loadRoutes,
    routes: staticPageRoute,
    loadRoutesHasBeenCalled,
  } = useStaticPageRoutes(GetStaticPageAcceptanceAgreementQuery);

  useEffectOnce(() => {
    loadRoutes();
  });

  const getAcceptanceAgreementFromSanity = useCallback(() => {
    if (!loadRoutesHasBeenCalled) {
      loadRoutes();
    }
    return staticPageRoute.filter(pages => pages?.requiredUserAcceptance);
  }, [loadRoutes, loadRoutesHasBeenCalled, staticPageRoute]);

  const getAndValidateAcceptanceAgreementFromSanity = useCallback(
    (requiredAcceptanceAgreementInfo: IRequiredAcceptanceAgreementInfo[]): IStaticPageRoute[] => {
      const acceptanceAgreementFromSanity = getAcceptanceAgreementFromSanity();

      if (requiredAcceptanceAgreementInfo?.length) {
        const missingAcceptanceAgreementFromSanity = acceptanceAgreementFromSanity.filter(
          (sanityAcceptanceAgreement: IStaticPageRoute) => {
            const exist = requiredAcceptanceAgreementInfo.find(
              (acceptanceAgreement: IRequiredAcceptanceAgreementInfo) =>
                sanityAcceptanceAgreement._id === acceptanceAgreement.id
            );
            if (!exist) {
              return sanityAcceptanceAgreement;
            }
            return null;
          }
        );

        const updateAcceptanceAgreementFromSanity = acceptanceAgreementFromSanity.filter(
          (sanityAcceptanceAgreement: IStaticPageRoute) =>
            requiredAcceptanceAgreementInfo.find(
              (acceptanceAgreement: IRequiredAcceptanceAgreementInfo) => {
                const updatedAtAcceptanceAgreement = acceptanceAgreement.updatedAt;

                const launchDarklyAcceptanceAgreement = switchUpdatedAteAcceptanceAgreement.find(
                  ({ id }) => id === sanityAcceptanceAgreement._id
                );

                const updatedAtFromSanity =
                  launchDarklyAcceptanceAgreement?.updatedAt ??
                  sanityAcceptanceAgreement._updatedAt!;

                const sanityAcceptanceAgreementDate = new Date(updatedAtFromSanity);

                const acceptanceAgreementDate = updatedAtAcceptanceAgreement
                  ? new Date(updatedAtAcceptanceAgreement)
                  : undefined;

                return (
                  sanityAcceptanceAgreement._id === acceptanceAgreement.id &&
                  (!isValid(acceptanceAgreementDate) ||
                    (sanityAcceptanceAgreementDate &&
                      acceptanceAgreementDate &&
                      sanityAcceptanceAgreementDate > acceptanceAgreementDate))
                );
              }
            )
        );

        return missingAcceptanceAgreementFromSanity.concat(updateAcceptanceAgreementFromSanity);
      }
      return acceptanceAgreementFromSanity;
    },
    [getAcceptanceAgreementFromSanity, switchUpdatedAteAcceptanceAgreement]
  );

  const signUp = async (
    {
      email,
      name,
      dob,
      phoneNumber,
      country,
      wantsPromotionalEmails,
      zipcode,
      gender,
      providerType,
      invitationCode,
    }: ISignUp,
    signInOverride: (args: ISignIn) => Promise<void> = signIn
  ): Promise<any> => {
    let jwt;
    let cognitoId = null;

    const requiredAcceptance = await getAcceptanceAgreementFromSanity();

    const requiredAcceptanceFilter = requiredAcceptance.map(pages => {
      return {
        id: pages._id,
        updatedAt: pages._updatedAt,
      } as IRequiredAcceptanceAgreementInfoInput;
    });

    try {
      if (enableSignUpInBE) {
        const { data } = await signUpMutation({
          variables: {
            input: {
              country: country as ISOs,
              dob,
              name,
              phoneNumber,
              platform: platform(),
              stage: welcomeEmailDomain(),
              userName: email,
              wantsPromotionalEmails,
              zipcode,
              gender,
              providerType,
              referralCode: invitationCode,
              requiredAcceptanceAgreementInfo: requiredAcceptanceFilter?.length
                ? requiredAcceptanceFilter
                : undefined,
            },
          },
        });
        jwt = data?.signUp;
        cognitoId = jwt ? parseJwt(jwt).sub : null;
      } else {
        const { userSub } = await cognitoSignUp({
          name,
          phoneNumber,
          username: email,
          country,
          wantsPromotionalEmails,
          dob,
          gender,
          requiredAcceptanceAgreementInfo: requiredAcceptanceFilter?.length
            ? requiredAcceptanceFilter
            : undefined,
        });
        cognitoId = userSub;
      }
    } catch (error) {
      throw error;
    }

    // If providerType is present we can't sign in with OTP
    if (!providerType) {
      await signInOverride({
        email,
        phoneNumber,
      });
    }

    return { jwt, cognitoId };
  };

  const signInSocialLogin = useCallback(
    async ({
      providerToken,
      providerType,
      providerEmail,
      throwError,
    }: ISocialLoginParams): Promise<void> => {
      try {
        const {
          challengeCode = '',
          sessionId = '',
          email,
        } = await socialLoginMutate({
          providerToken,
          providerType,
          providerEmail,
        });

        const session = await cognitoValidateLogin({
          username: email || '',
          code: challengeCode,
          sessionId,
        });

        guestSignOut();
        setCurrentUser(session);

        if (throwError) {
          mParticleSignInEvent({
            phase: SignInPhases.START,
            success: true,
            method: SignInMethods.SOCIAL,
            providerType,
          });
        }

        mParticleSignInEvent({
          phase: SignInPhases.COMPLETE,
          success: true,
          method: SignInMethods.SOCIAL,
          providerType,
        });
        navigate(routes.base);
      } catch (error) {
        // This flow is only used when checking if the user already has an account
        // The error should be thrown only if the user is not found in our system
        if (throwError && error?.graphQLErrors[0]?.extensions.code === 'USER_NOT_FOUND') {
          throw error;
        }

        const errorMap = {
          INVALID_PROVIDER_TOKEN_FOR_USER: 'authErrorDifferentProvider',
          USER_NOT_FOUND: 'authErrorUserNotFound',
        };

        mParticleSignInEvent({
          phase: SignInPhases.START,
          success: false,
          message: error.message,
          method: SignInMethods.SOCIAL,
          providerType,
        });

        const errorMessageId = errorMap[error?.graphQLErrors[0]?.extensions.code] || 'authError';

        openErrorDialog({
          error,
          message: formatMessage({ id: errorMessageId }),
          modalAppearanceEventMessage: 'Error: Sign In Failure',
        });

        mParticleSignInEvent({
          phase: SignInPhases.COMPLETE,
          success: false,
          message: formatMessage({ id: errorMessageId }),
          method: SignInMethods.SOCIAL,
          providerType,
        });
      }
    },
    [
      formatMessage,
      mParticleSignInEvent,
      navigate,
      openErrorDialog,
      setCurrentUser,
      socialLoginMutate,
    ]
  );

  const validateLoginOtp = useCallback(
    async ({ otpCode }) => {
      try {
        const { email, phoneNumber, sessionId: storedSessionId } = getStoredOtpCredentials() ?? {};
        if (!email || !storedSessionId) {
          throw new OtpValidationError('Missing email or sessionId');
        }

        const { code, sessionId } = await getSessionIdAndChallengeCodeOtp({
          email,
          otpCode,
          sessionId: storedSessionId,
        });

        const validateOtpMethod =
          signInOtpMethod && signInOtpMethod !== OTPAuthDeliveryMethod.None
            ? signInOtpMethod
            : await getOtpFlagValueForAttributes({
                email,
                custom: { phoneNumber },
              });
        const session = await cognitoValidateLogin({ username: email, code, sessionId });
        mParticleSignInEvent({
          phase: SignInPhases.COMPLETE,
          success: true,
          otpMethod: validateOtpMethod,
          method: SignInMethods.OTP,
        });
        guestSignOut();
        setCurrentUser(session);
      } catch (error) {
        mParticleSignInEvent({
          phase: SignInPhases.COMPLETE,
          success: false,
          message: error.message,
          otpMethod: signInOtpMethod,
          method: SignInMethods.OTP,
        });

        throw error;
      }
    },
    [
      signInOtpMethod,
      getSessionIdAndChallengeCodeOtp,
      getOtpFlagValueForAttributes,
      mParticleSignInEvent,
      setCurrentUser,
    ]
  );

  const validateLogin = useCallback(
    async ({ jwt, username }: IValidateLogin) => {
      try {
        const { sessionId, code } = await getSessionIdAndChallengeCode(jwt);
        const session = await cognitoValidateLogin({ username, code, sessionId });
        mParticleSignInEvent({
          phase: SignInPhases.COMPLETE,
          success: true,
          method: SignInMethods.OTP,
        });
        guestSignOut();
        setCurrentUser(session);
        // Add a marker when a user is successful login to track unexpected sign outs
        LocalStorage.setItem(StorageKeys.USER_SIGNED_IN_SUCCESSFULLY, true);
      } catch (error) {
        mParticleSignInEvent({
          phase: SignInPhases.COMPLETE,
          success: false,
          message: error.message,
          method: SignInMethods.OTP,
        });
        openErrorDialog({
          error,
          message: formatMessage({ id: 'authError' }),
          modalAppearanceEventMessage: 'Error: JWT Validation Failure',
        });
        throw error;
      }
    },
    [
      formatMessage,
      getSessionIdAndChallengeCode,
      mParticleSignInEvent,
      openErrorDialog,
      setCurrentUser,
    ]
  );

  return {
    authLoading:
      signInMutationLoading ||
      validateAuthMutationLoading ||
      createOtpMutationLoading ||
      validateOtpMutationLoading,
    originLocation,
    setOriginLoc,
    signIn,
    signInSocialLogin,
    signOut,
    signUp,
    validateLogin,
    validateLoginOtp,
    getAcceptanceAgreementFromSanity,
    getAndValidateAcceptanceAgreementFromSanity,
  };
};
