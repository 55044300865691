import { useEffect, useState } from 'react';

import { Omit } from '@rbi-ctg/frontend';
import { IStore, RestaurantStatus } from '@rbi-ctg/store';
import {
  DeliveryStatus,
  DeliveryStoreStatus,
  OperationalStatus,
  ServiceMode,
  useGetRestaurantsLazyQuery,
} from 'generated/rbi-graphql';
import { CustomEventNames, EventTypes, useMParticleContext } from 'state/mParticle';
import { useServiceModeContext } from 'state/service-mode';
import { convertMilesToMeters } from 'utils/distance';
import { RBIEnv, getConfigValue, sanityEnv } from 'utils/environment';
import { IPlaceAddress } from 'utils/geolocation';
import { INetworkStatus } from 'utils/network/status';
import { useGetAvailableRestaurantWithDetails } from 'utils/restaurant';

import { GroqArgs, groqQuery } from './groq';

const projection = `
  _id,
  chaseMerchantId,
  deliveryHours,
  diningRoomHours,
  curbsideHours,
  drinkStationType,
  driveThruHours,
  driveThruLaneType,
  email,
  franchiseGroupId,
  franchiseGroupName,
  frontCounterClosed,
  hasBreakfast,
  hasBurgersForBreakfast,
  hasCurbside,
  hasDineIn,
  hasCatering,
  hasDelivery,
  hasDriveThru,
  hasTableService,
  hasMobileOrdering,
  hasParking,
  hasPlayground,
  hasTakeOut,
  hasWifi,
  hasLoyalty,
  isDarkKitchen,
  isHalal,
  latitude,
  longitude,
  mobileOrderingStatus,
  name,
  number,
  parkingType,
  phoneNumber,
  physicalAddress,
  playgroundType,
  pos,
  posRestaurantId,
  restaurantPosData->{_id},
  status,
  restaurantImage{..., asset->},
  vatNumber,
  customerFacingAddress,
`;

const nearbyQueryArguments = [
  "_type == 'restaurant'",
  'environment == $environment',
  '!($appEnvironemnt in coalesce(hideInEnvironments, []))',
  'latitude > $minLat',
  'latitude < $maxLat',
  'longitude > $minLng',
  'longitude < $maxLng',
  'status == $status',
];

const nearbyQueryDeliveryArguments = [...nearbyQueryArguments, 'hasDelivery == $hasDelivery'];

const nearbyQuery = `
  *[ ${nearbyQueryArguments.join(' && ')} ] |
  order(($userLat - latitude) ** 2 + ($userLng - longitude) ** 2)[$offset...($offset + $limit)] {
    ${projection}
  }
`;

const nearbyDeliveryQuery = `
  *[ ${nearbyQueryDeliveryArguments.join(' && ')} ] |
  order(($userLat - latitude) ** 2 + ($userLng - longitude) ** 2)[$offset...($offset + $limit)] {
    ${projection}
  }
`;

const storeQuery = `
  *[ _type == 'restaurant' && _id == $id ]{
    ${projection}
  }
`;

const storesByIdsWithOutLocationQuery = `
  *[ _type == 'restaurant' && _id in $storeIds ] {
    ${projection}
  }
`;

const storesByNumbersWithOutLocationQuery = `
  *[ _type == 'restaurant' && number in $storeNumbers ] {
    ${projection}
  }
`;

const storesByNumbersWithLocationQuery = `
*[ _type == 'restaurant' && number in $storeNumbers ] |
order(($userLat - latitude) ** 2 + ($userLng - longitude) ** 2) {
  ${projection}
}
`;

const storesByIdsQuery = `
  *[ _type == 'restaurant' && _id in $storeIds ] |
  order(($userLat - latitude) ** 2 + ($userLng - longitude) ** 2) {
    ${projection}
  }
`;

const storesByNumberQuery = `
  *[ _type == 'restaurant' && number == $storeNumber ] {
    ${projection}
  }
`;

interface IRestaurantQueryArgs {
  limit?: number;
  maxLat: number;
  maxLng: number;
  minLat: number;
  minLng: number;
  offset?: number;
  status: 'Open';
  userLat: number;
  userLng: number;
  hasDelivery?: boolean;
}

interface IRestaurantQueryEnvs {
  appEnvironemnt: RBIEnv;
  environment: RestaurantStatus;
}

function restaurantEnv() {
  const displayEnv = getConfigValue('restaurants').displayEnv;
  return displayEnv as RestaurantStatus & RBIEnv;
}

export const getRestaurantsNearMe = (
  endpoint: string,
  {
    connection,
    limit = 20,
    maxLat,
    maxLng,
    minLat,
    minLng,
    offset = 0,
    userLat,
    userLng,
    deliveryOnly = false,
  }: Omit<GroqArgs<IRestaurantQueryArgs & { deliveryOnly: boolean }>, 'status'>
) => {
  const queryParams: GroqArgs<IRestaurantQueryArgs> & IRestaurantQueryEnvs = {
    appEnvironemnt: sanityEnv(),
    connection,
    environment: restaurantEnv(),
    limit,
    maxLat,
    maxLng,
    minLat,
    minLng,
    offset,
    status: 'Open',
    userLat,
    userLng,
  };

  if (deliveryOnly) {
    queryParams.hasDelivery = true;
  }

  const query = deliveryOnly ? nearbyDeliveryQuery : nearbyQuery;

  return groqQuery<IStore[], IRestaurantQueryArgs & IRestaurantQueryEnvs>(
    endpoint,
    query,
    queryParams
  );
};

type ClosestAvailableRestaurantArgs = Pick<IRestaurantQueryArgs, 'userLat' | 'userLng'> & {
  connection: INetworkStatus;
};

export enum StoreStatus {
  OPEN = 'deliveryStoreOpen',
  CLOSED = 'deliveryStoresClosed',
  NO_DELIVERY = 'noDeliveryStores',
}

export declare interface IGetClosestAvailableDeliveryRestaurantResult {
  deliveryQuote: DeliveryStatus | null;
  storeStatus: StoreStatus;
  store: IStore | null;
  nextEarliestOpen?: Date;
}

export const DeliveryStoreStatusToStoreStatusMap: Record<DeliveryStoreStatus, StoreStatus> = {
  [DeliveryStoreStatus.CLOSED]: StoreStatus.CLOSED,
  [DeliveryStoreStatus.NO_DELIVERY]: StoreStatus.NO_DELIVERY,
  [DeliveryStoreStatus.OPEN]: StoreStatus.OPEN,
};

export type QueryClosestAvailableRestaurantTriple = [
  (
    phoneNumber: string,
    deliveryRadiusInMiles: number,
    { userLat, userLng }: ClosestAvailableRestaurantArgs,
    deliveryAddress?: IPlaceAddress | null
  ) => void,
  IGetClosestAvailableDeliveryRestaurantResult | undefined,
  string | undefined,
];

export const useQueryClosestAvailableDeliveryRestaurant =
  (): QueryClosestAvailableRestaurantTriple => {
    const [query, { data }] = useGetRestaurantsLazyQuery();
    const { setDeliverySurchargeFee } = useServiceModeContext();
    const [phone, setPhone] = useState('');
    const [error, setError] = useState('');
    const [storeData, setStoreData] = useState<
      IGetClosestAvailableDeliveryRestaurantResult | undefined
    >();
    const [address, setDeliveryAddress] = useState<IPlaceAddress | null | undefined>();
    const getAvailableRestaurantWithDetails = useGetAvailableRestaurantWithDetails();
    const { trackEvent } = useMParticleContext();

    const getRestaurants = (
      phoneNumber: string,
      deliveryRadiusInMiles: number,
      { userLat, userLng }: ClosestAvailableRestaurantArgs,
      deliveryAddress?: IPlaceAddress | null
    ) => {
      setPhone(phoneNumber);
      setDeliveryAddress(deliveryAddress);
      query({
        variables: {
          input: {
            coordinates: {
              searchRadius: convertMilesToMeters(deliveryRadiusInMiles),
              userLat,
              userLng,
            },
            radiusStrictMode: true,
            serviceModes: [ServiceMode.DELIVERY],
            status: OperationalStatus.OPEN,
          },
        },
      });
    };

    const restaurantNodes = data?.restaurants?.nodes;
    useEffect(() => {
      if (!restaurantNodes) {
        return;
      }

      const handleResults = async () => {
        const {
          deliveryQuoteError,
          storesWithInvalidHOO,
          deliveryQuoteSurchargeFeeCents,
          ...restaurantDetails
        } = await getAvailableRestaurantWithDetails(
          (restaurantNodes ?? []) as unknown as IStore[],
          phone,
          address
        );

        // Log stores which don't have delivery hours set
        storesWithInvalidHOO.forEach((store: IStore) => {
          trackEvent({
            name: CustomEventNames.STORE_DELIVERY_INVALID_HOO,
            type: EventTypes.Other,
            attributes: {
              StoreId: store._id,
              DeliveryHours: store.deliveryHours,
            },
          });
        });

        if (deliveryQuoteError) {
          setError(deliveryQuoteError);
        }
        if (deliveryQuoteSurchargeFeeCents) {
          setDeliverySurchargeFee(deliveryQuoteSurchargeFeeCents);
        }

        setStoreData(restaurantDetails as IGetClosestAvailableDeliveryRestaurantResult);
      };

      handleResults();
    }, [
      address,
      getAvailableRestaurantWithDetails,
      trackEvent,
      phone,
      restaurantNodes,
      setDeliverySurchargeFee,
    ]);

    const result: QueryClosestAvailableRestaurantTriple = [getRestaurants, storeData, error];
    return result;
  };

type GetRestaurantArgs = GroqArgs<{ id: string }>;

export const getRestaurant = (endpoint: string, { connection, id }: GetRestaurantArgs) =>
  groqQuery<[IStore], GetRestaurantArgs>(endpoint, storeQuery, { connection, id });

type GetRestaurantsByStoreNumberArgs = GroqArgs<{ storeNumber: string }>;

export const getRestaurantsByStoreNumber = (
  endpoint: string,
  { connection, storeNumber }: GetRestaurantsByStoreNumberArgs
) =>
  groqQuery<IStore[], GetRestaurantsByStoreNumberArgs>(endpoint, storesByNumberQuery, {
    connection,
    storeNumber,
  });

type GetRestaurantsByIdsArgs = {
  connection: INetworkStatus;
  storeIds: string[];
  userLat: number;
  userLng: number;
};

type GetAllRestaurantsByIdsArgs = {
  connection: INetworkStatus;
  storeIds: string[];
};

type GetAllRestaurantsByStoreNumbersArgs = {
  connection: INetworkStatus;
  storeNumbers: string[];
  userLat?: number;
  userLng?: number;
};

export const getAllRestaurantsByIdsOrderedByLocation = (
  endpoint: string,
  { connection, storeIds, userLat, userLng }: GroqArgs<GetRestaurantsByIdsArgs>
) =>
  groqQuery<IStore[], GetRestaurantsByIdsArgs>(endpoint, storesByIdsQuery, {
    connection,
    storeIds,
    userLat,
    userLng,
  });

export const getAllRestaurantsByIds = (
  endpoint: string,
  { connection, storeIds }: GroqArgs<GetAllRestaurantsByIdsArgs>
) =>
  groqQuery<IStore[], GetAllRestaurantsByIdsArgs>(endpoint, storesByIdsWithOutLocationQuery, {
    connection,
    storeIds,
  });

export const getAllRestaurantsByStoreNumbers = (
  endpoint: string,
  { connection, storeNumbers }: GroqArgs<GetAllRestaurantsByStoreNumbersArgs>
) =>
  groqQuery<IStore[], GetAllRestaurantsByStoreNumbersArgs>(
    endpoint,
    storesByNumbersWithOutLocationQuery,
    {
      connection,
      storeNumbers,
    }
  );

export const getAllRestaurantsByStoreNumbersOrderedByLocation = (
  endpoint: string,
  { connection, storeNumbers, userLat, userLng }: GroqArgs<GetAllRestaurantsByStoreNumbersArgs>
) =>
  groqQuery<IStore[], GetAllRestaurantsByStoreNumbersArgs>(
    endpoint,
    storesByNumbersWithLocationQuery,
    {
      connection,
      storeNumbers,
      userLat,
      userLng,
    }
  );
