import React, { useMemo } from 'react';

import { Footer } from '@rbilabs/components-library/build/components/footer';
import { asLanguage, getLocales } from '@rbilabs/intl';

import { LanguageSelectorV2 } from 'components/language-selector-v2';
import useLocation from 'hooks/location/use-location';
import { useFeatureFooterV2 } from 'hooks/use-feature-footer-v2';
import useMediaQuery from 'hooks/use-media-query';
import { useLocale } from 'state/intl';
import { FALLBACK_LANGUAGE, FALLBACK_LOCALES } from 'utils/constants';
import { brand } from 'utils/environment';
import { RegionToISOs } from 'utils/form/constants';
import { getNativeLanguageName } from 'utils/i18n';
import { routes } from 'utils/routing';

export const FooterV2: React.FC = () => {
  const isMobile = useMediaQuery('mobile');
  const { featureFooterV2Loading, featureFooterV2 } = useFeatureFooterV2();
  const { region } = useLocale();
  const {
    location: { pathname },
  } = useLocation();

  /** All valid locales for given brand/region */
  const locales = useMemo(() => getLocales(brand(), region), [region]);

  // Hide footer on sign up & login flow
  if (pathname === routes.signUp || pathname === routes.signIn || pathname === routes.confirmOtp) {
    return null;
  }

  if (isMobile) {
    return null;
  }

  if (!featureFooterV2 || featureFooterV2Loading) {
    return null;
  }
  const { mainLinks, miscellaneousLinks, copyrightText, socialMediaData } = featureFooterV2;
  if (!mainLinks?.length || !miscellaneousLinks?.length || !copyrightText || !socialMediaData) {
    return null;
  }

  const localeOptions = (locales ?? FALLBACK_LOCALES).map(locale => {
    return {
      value: locale,
      /** label reads as English (USA) for example */
      label: `${getNativeLanguageName(asLanguage(locale.slice(0, 2)) ?? FALLBACK_LANGUAGE)} (${
        RegionToISOs[region]
      })`,
    };
  });

  return (
    <Footer
      mainLinks={mainLinks}
      miscellaneousLinks={miscellaneousLinks}
      copyrightText={copyrightText}
      socialMediaData={socialMediaData}
      localeSelector={
        locales && locales.length > 1 ? (
          <LanguageSelectorV2 localeOptions={localeOptions} redirectToCurrentLocation={true} />
        ) : null
      }
      id="footer-component"
      onClick={() => null}
    />
  );
};
