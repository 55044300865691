import React, { useCallback, useState } from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { SupportedLanguages, SupportedRegions } from '@rbi-ctg/frontend';
import Modal, { ModalContent, ModalHeading } from 'components/modal';
import useEffectOnce from 'hooks/use-effect-once';
import { useLocale } from 'state/intl';
import { LOCALE_SELECTED_QUERY_PARAM } from 'state/intl/hook';
import { inferSupportedLocale } from 'state/intl/infer-supported-locale';
import { useFlag } from 'state/launchdarkly';
import { NEXT_URL_QUERY_PARAM } from 'state/location/constants';
import { CustomEventNames, EventTypes, useMParticleContext } from 'state/mParticle';
import { useStaticPageRoutes } from 'state/static-page-manager/hooks/use-static-page-routes';
import { findLocaleStaticPage } from 'utils/language/static-page';
import { LaunchDarklyFlag } from 'utils/launchdarkly';
import LocalStorage, { StorageKeys } from 'utils/local-storage';

import { modalHeadingStyles } from './constants';
import LanguageSelectorModalContents from './language-selector-modal-contents';
import { ModalAdditionalContentWrapper } from './language-selector-modal-contents/styled';

const ContentContainer = styled(ModalContent)`
  padding-bottom: 2rem;
  & div.modal-inner {
    margin: 0;
    width: 100%;
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    grid-auto-rows: auto;
    text-align: justify;
  }
`;

const StyledModalHeading = styled(ModalHeading)`
  justify-self: center;

  ${modalHeadingStyles}

  ${Styles.desktop`
    max-width: none;
  `}
`;

const PrimaryText = styled.div`
  margin: 2rem auto;
  text-align: center;
`;

const SecondaryText = styled.div`
  text-align: center;
`;

interface ILanguageModalProps {
  onModalDismiss: () => void;
  heading?: string;
  regionFilter?: (region: string) => boolean;
  redirectToCurrentLocation?: boolean;
  primaryText?: string;
  secondaryText?: string;
  disclaimer?: string;
}

const LanguageSelectorModal: React.FC<ILanguageModalProps> = ({
  onModalDismiss,
  heading,
  primaryText,
  secondaryText,
  disclaimer,
  regionFilter = (regionString: string) => !!regionString,
  redirectToCurrentLocation = false,
}) => {
  const { formatMessage } = useIntl();
  const { loadRoutes, routes } = useStaticPageRoutes();
  const { trackEvent, updateUserAttributes } = useMParticleContext();
  const { region, clearRegionSpecificStorage, setHasShownLocaleSelector, locale, language } =
    useLocale();
  const [{ langKey, regionKey }, setSelectedLocale] = useState({
    langKey: language,
    regionKey: region,
  });

  const singleRegionLanguageSelector = useFlag(LaunchDarklyFlag.SINGLE_REGION_LANGUAGE_SELECTOR);

  const enableRedirectLocalePage = useFlag(LaunchDarklyFlag.ENABLE_REDIRECT_LOCALE_PAGE);

  const handleLanguageChange = (selectedLanguage: string, mappedRegion: string) => () => {
    setSelectedLocale({
      langKey: selectedLanguage as SupportedLanguages,
      regionKey: mappedRegion as SupportedRegions,
    });
  };

  const isNewLocaleSelectionSameAsCurrent = useCallback(() => {
    const inferredLocaleFromArgs = inferSupportedLocale(
      langKey as SupportedLanguages,
      regionKey as SupportedRegions
    );

    return inferredLocaleFromArgs === locale;
  }, [langKey, regionKey, locale]);

  const handleSaveClick = useCallback(() => {
    if (isNewLocaleSelectionSameAsCurrent()) {
      onModalDismiss();
      return;
    }

    updateUserAttributes({ language: langKey });

    const encodedCurrentUrl = encodeURIComponent(window.location.pathname);
    let strParams = `?lang=${langKey}&${LOCALE_SELECTED_QUERY_PARAM}=1`;

    if (redirectToCurrentLocation) {
      strParams += `&${NEXT_URL_QUERY_PARAM}=${encodedCurrentUrl}`;
    }

    const params = new URLSearchParams(strParams);
    const pathname = window.location?.pathname ?? '';

    const localeStaticPagePath =
      enableRedirectLocalePage && findLocaleStaticPage(pathname, langKey, language, routes);

    const localeUrl = localeStaticPagePath
      ? `${window.location.origin}${localeStaticPagePath}`
      : `${window.location.origin}${pathname}`;

    const url = enableRedirectLocalePage ? localeUrl : window.location.origin;

    // Add the region query param and clear localstorage
    // if we are not going to do a full url change
    if (url === window.location.origin) {
      params.append('region', regionKey);

      clearRegionSpecificStorage();
    }
    // Set language in local storage
    LocalStorage.setItem(StorageKeys.LANGUAGE, langKey);
    LocalStorage.setItem(StorageKeys.REGION, regionKey);

    setHasShownLocaleSelector();
    window.location.href = localeStaticPagePath ? url : `${url}?${params}`;

    onModalDismiss();
  }, [
    isNewLocaleSelectionSameAsCurrent,
    updateUserAttributes,
    langKey,
    redirectToCurrentLocation,
    setHasShownLocaleSelector,
    onModalDismiss,
    regionKey,
    clearRegionSpecificStorage,
    language,
    routes,
    enableRedirectLocalePage,
  ]);

  useEffectOnce(() => {
    loadRoutes();
    trackEvent({ name: CustomEventNames.LOCALE_SELECTOR_MODAL_SHOWN, type: EventTypes.Navigation });
  });

  const applyButtonText = formatMessage({ id: 'apply' });
  const modalHeading =
    heading ||
    (singleRegionLanguageSelector
      ? formatMessage({ id: 'selectLanguage' })
      : formatMessage({ id: 'changeLanguage' }));

  return (
    <Modal
      data-testid="select-language-modal"
      onDismiss={onModalDismiss}
      mParticleEventData={{
        modalAppearanceEventMessage: 'Language and region selector',
      }}
    >
      <ContentContainer>
        <StyledModalHeading id="language-select-modal-heading">{modalHeading}</StyledModalHeading>
        {(primaryText || secondaryText) && (
          <ModalAdditionalContentWrapper>
            {primaryText && <PrimaryText>{primaryText}</PrimaryText>}
            {secondaryText && <SecondaryText>{secondaryText}</SecondaryText>}
          </ModalAdditionalContentWrapper>
        )}
        <LanguageSelectorModalContents
          ariaRadioGroupLabeledBy="language-select-modal-heading"
          regionFilter={regionFilter}
          regionKey={regionKey}
          langKey={langKey}
          disclaimer={disclaimer}
          applyButtonText={applyButtonText}
          onLanguageChange={handleLanguageChange}
          onSaveClick={handleSaveClick}
        />
      </ContentContainer>
    </Modal>
  );
};

export default LanguageSelectorModal;
