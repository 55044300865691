import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { IsoCountryCode2, Language, Locale, asIsoCountryCode2 } from '@rbilabs/intl';
import imageUrlBuilder from '@sanity/image-url';
import {
  ImageUrlBuilderOptionsWithAliases,
  SanityImageSource,
} from '@sanity/image-url/lib/types/types';
import { IFormatCurrencyProps, formatCurrency } from 'utils';

import { IBaseProps } from '@rbi-ctg/frontend';
import useEffectOnce from 'hooks/use-effect-once';
import buildImageUrlUtil from 'remote/build-image-url';
import { useAuthContext } from 'state/auth';
import { UserDetails } from 'state/auth/hooks/types';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { FALLBACK_ISO2 } from 'utils/constants';
import { sanityDataset as defaultSanityDataset, getConfigValue } from 'utils/environment';
import { ISOs, ISOsToRegions, getCountryAndCurrencyCodes } from 'utils/form/constants';
import { setIsWebViewVisible } from 'utils/ui';

import { CustomEventNames, useMParticleContext } from '../mParticle';

export type ImageBuilderType = ReturnType<typeof imageUrlBuilder>;

export type BuildImageUrlType = (
  source: SanityImageSource,
  options?: Partial<ImageUrlBuilderOptionsWithAliases>
) => string;

export type FormatCurrencyForType = (amount: number) => string;

export interface IUIContext {
  isShowingDrawer: boolean;
  isShowingWelcomeMessage: boolean;
  isShowingStoreInfoDialog: boolean;
  activeDrawerTab: number;
  userEmail: string | null;
  setUserEmail: (email: string) => void;
  setActiveDrawerTab: (index: number) => void;
  setIsWebViewVisible(isVisible: boolean): void;
  showDrawerTab: (index: number) => void;
  showCardTab: () => void;
  showRewardsTab: () => void;
  showDrawer: () => void;
  hideDrawer: () => void;
  showStoreInfoDialog: () => void;
  hideStoreInfoDialog: () => void;
  showWelcomeMessage: () => void;
  hideWelcomeMessage: () => void;
  imageBuilder: ImageBuilderType;
  buildImageUrl: (
    source: SanityImageSource,
    options?: Partial<ImageUrlBuilderOptionsWithAliases>
  ) => string;
  shouldConfirmStoreOnCheckout(flag: boolean): void;
  confirmStoreOnCheckout: boolean;
  formatCurrencyForLocale(amount: number): string;
  formatCurrencyForUser(amount: number | undefined): string;
  currencySymbol: string;
  currencyIsSuffix: boolean;
}

export enum DrawerTab {
  Rewards,
  Card,
}

interface GetFormatCurrenctForUserOptions {
  user: UserDetails | null;
  locale: string;
  region: IsoCountryCode2;
}

export const getFormatCurrencyForUser =
  ({ user, locale, region }: GetFormatCurrenctForUserOptions) =>
  (amount: number) => {
    const userISOCountryCode = user?.details?.isoCountryCode || FALLBACK_ISO2;
    const { currencyCode, countryCode } = getCountryAndCurrencyCodes(ISOs[userISOCountryCode]);

    const formatOptions: IFormatCurrencyProps = {
      language: locale,
      currency: currencyCode,
      currencyDisplay: countryCode === region ? 'narrowSymbol' : 'symbol',
      amount,
    };

    return formatCurrency(formatOptions);
  };

interface GetFormatCurrenctForLocaleOptions {
  user: UserDetails | null;
  language: string;
  region: IsoCountryCode2;
}

export const getFormatCurrencyForLocale =
  ({ user, language, region }: GetFormatCurrenctForLocaleOptions) =>
  (amount: number) => {
    const userISOCountryCode = user?.details?.isoCountryCode;
    const userRegion = ((userISOCountryCode && ISOsToRegions[userISOCountryCode]) ||
      region ||
      FALLBACK_ISO2) as IsoCountryCode2;

    const userLocale = `${language}-${userRegion}`;

    const { currencyCode } = getCountryAndCurrencyCodes(ISOs[region]);

    const formatOptions: IFormatCurrencyProps = {
      language: userLocale as Locale,
      currency: currencyCode,

      currencyDisplay: userRegion === region ? 'narrowSymbol' : 'symbol',
      amount,
    };

    return formatCurrency(formatOptions);
  };

export const UIContext = createContext<IUIContext>({} as IUIContext);
export const useUIContext = () => useContext(UIContext);

export const UIProvider = ({ children }: IBaseProps) => {
  const mParticle = useMParticleContext();
  const { location, setReferrerLocation } = useLocationContext();
  const { user } = useAuthContext();
  const { language, region, locale } = useLocale();
  const [activeDrawerTab, setActiveDrawerTab] = useState<number>(0);
  const [confirmStoreOnCheckout, setConfirmStoreOnCheckout] = useState(true);
  const [isShowingDrawer, setIsShowingDrawer] = useState<boolean>(false);
  const [isShowingStoreInfoDialog, setIsShowingStoreInfoDialog] = useState<boolean>(false);
  const [isShowingWelcomeMessage, setIsShowingWelcomeMessage] = useState(false);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const enableStoreConfirmationModal = useFlag(LaunchDarklyFlag.ENABLE_STORE_CONFIRMATION_MODAL);
  const previousUrl = useRef<string>('');

  useEffectOnce(() => {
    // Just in case there was an issue re-showing webview
    // Set isVisible on mount
    setIsWebViewVisible(true);
  });

  useEffect(() => {
    const currentUrl = location.pathname + location.search;

    if (previousUrl.current === currentUrl) {
      return;
    }

    setReferrerLocation(previousUrl.current);

    previousUrl.current = currentUrl;
  }, [location.pathname, location.search, setReferrerLocation]);

  const showStoreInfoDialog = useCallback(() => {
    setIsShowingStoreInfoDialog(true);
  }, [setIsShowingStoreInfoDialog]);

  const hideStoreInfoDialog = useCallback(() => {
    setIsShowingStoreInfoDialog(false);
  }, [setIsShowingStoreInfoDialog]);

  const showDrawer = useCallback(() => {
    setIsShowingDrawer(true);
  }, [setIsShowingDrawer]);

  const hideDrawer = useCallback(() => {
    setIsShowingDrawer(false);
  }, [setIsShowingDrawer]);

  const showWelcomeMessage = useCallback(() => {
    setIsShowingWelcomeMessage(true);
  }, [setIsShowingWelcomeMessage]);

  const hideWelcomeMessage = useCallback(() => {
    setIsShowingWelcomeMessage(false);
  }, [setIsShowingWelcomeMessage]);

  const showDrawerTab = useCallback(
    (tabIndex: number) => {
      setIsShowingDrawer(true);
      setActiveDrawerTab(tabIndex);
    },
    [setIsShowingDrawer, setActiveDrawerTab]
  );

  const showCardTab = useCallback(() => {
    showDrawerTab(DrawerTab.Card);
    mParticle.logNavigationClick(CustomEventNames.BUTTON_CLICK_TIMS_CARD);
  }, [showDrawerTab, mParticle]);

  const showRewardsTab = useCallback(() => {
    showDrawerTab(DrawerTab.Rewards);
    mParticle.logNavigationClick(CustomEventNames.BUTTON_CLICK_DRAWER_REWARDS);
  }, [showDrawerTab, mParticle]);

  const shouldConfirmStoreOnCheckout = useCallback(
    (flag: boolean) => {
      if (enableStoreConfirmationModal) {
        setConfirmStoreOnCheckout(flag);
      }
    },
    [setConfirmStoreOnCheckout, enableStoreConfirmationModal]
  );

  const imageBuilder = useMemo(() => {
    const dataset = region
      ? `${defaultSanityDataset}_${region.toLowerCase()}`
      : defaultSanityDataset.toLowerCase();

    return imageUrlBuilder({ dataset, projectId: getConfigValue('sanityProjectId') });
  }, [region]);

  const buildImageUrl: BuildImageUrlType = useCallback(
    (source, options = {}) => buildImageUrlUtil(imageBuilder, source, options),
    [imageBuilder]
  );

  /**
   * Use the user profile's isoCountryCode to determine the locale.
   * Use the site's locale to determine currency.
   * Useful for product prices which are displayed in the currency of the site (e.g. show a US user '$CA' on the fr-CA and en-CA sites)
   */
  const formatCurrencyForLocale: FormatCurrencyForType = useCallback(
    getFormatCurrencyForLocale({
      user,
      region: asIsoCountryCode2(region) ?? FALLBACK_ISO2,
      language,
    }),
    [user, region, language]
  );

  /**
   * Use the site's locale as the formatted number's locale
   * Use the user profile's isoCountryCode to determine the currency,
   * Useful for showing user an amount that they are going to pay in their account's currency (e.g. reloading a pre-paid/TimCard)
   */
  const formatCurrencyForUser: FormatCurrencyForType = useCallback(
    getFormatCurrencyForUser({
      user,
      locale,
      region: asIsoCountryCode2(region) ?? FALLBACK_ISO2,
    }),
    [user, locale, region]
  );

  const currencySymbol = useMemo(() => {
    return formatCurrencyForLocale(0)
      .replace(/\d+(,|\.)\d+/, '')
      .trim();
  }, [formatCurrencyForLocale]);

  // @TODO: Remove or update this logic since it remains to US / CA codebase
  // and it's not needed for INTL
  const currencyIsSuffix = useMemo(() => {
    return language.toString() === Language.fr.toString();
  }, [language]);

  const value = useMemo(
    () => ({
      activeDrawerTab,
      userEmail,
      isShowingDrawer,
      isShowingWelcomeMessage,
      isShowingStoreInfoDialog,
      setUserEmail,
      setActiveDrawerTab,
      hideStoreInfoDialog,
      showStoreInfoDialog,
      hideWelcomeMessage,
      showWelcomeMessage,
      hideDrawer,
      showDrawer,
      showDrawerTab,
      showRewardsTab,
      showCardTab,
      buildImageUrl,
      imageBuilder,
      confirmStoreOnCheckout: enableStoreConfirmationModal && confirmStoreOnCheckout,
      shouldConfirmStoreOnCheckout,
      formatCurrencyForLocale,
      formatCurrencyForUser,
      currencySymbol,
      currencyIsSuffix,
      setIsWebViewVisible,
    }),
    [
      activeDrawerTab,
      buildImageUrl,
      confirmStoreOnCheckout,
      currencyIsSuffix,
      currencySymbol,
      enableStoreConfirmationModal,
      formatCurrencyForLocale,
      formatCurrencyForUser,
      hideDrawer,
      hideStoreInfoDialog,
      hideWelcomeMessage,
      imageBuilder,
      isShowingDrawer,
      isShowingStoreInfoDialog,
      isShowingWelcomeMessage,
      shouldConfirmStoreOnCheckout,
      showCardTab,
      showDrawer,
      showDrawerTab,
      showRewardsTab,
      showStoreInfoDialog,
      showWelcomeMessage,
      userEmail,
    ]
  );

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};
