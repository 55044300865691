import styled from 'styled-components';

import { primitive } from 'styles/constants/primitives';

export const RewardTierOptionContainer = styled.div`
  margin: 0rem;
  padding: ${primitive.$spacing2} 0rem ${primitive.$spacing2} 0rem;
  cursor: pointer;
`;

export const CheckmarkWrapper = styled.span`
  margin-left: ${primitive.$spacing2};
  position: relative;
  inset-block-end: 1px;
`;
