import styled, { css } from 'styled-components';

import { primitive } from 'styles/constants/primitives';

type ExpandProps = {
  isExpanded: boolean | undefined;
};

type ContainerProps = {
  drawerOffset: number;
  hasAllDrawerElementsRendered: boolean;
} & ExpandProps;

type BottomDrawerProps = {
  drawerContentHeight: number | false;
} & ExpandProps;

type IsFHSBrandProp = {
  isFHSBrand: boolean;
};

type AnchorElementProps = {
  previewMargin: number;
  showBottomSheet: boolean;
};

// The anchor element will act as the bottom sheet preserving the space for the portal approach
export const AnchorElement = styled.div<AnchorElementProps>`
  height: 0;
  width: 100%;
  margin-top: ${({ previewMargin, showBottomSheet }) => (!showBottomSheet ? 0 : previewMargin)}px;
  visibility: hidden;
`;

export const Overlay = styled.div`
  background-color: rgb(0 0 0 / 50%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  ${({ isExpanded, hasAllDrawerElementsRendered, drawerOffset }) => css`
    pointer-events: ${isExpanded && hasAllDrawerElementsRendered ? 'initial' : 'none'};
    visibility: ${hasAllDrawerElementsRendered ? 'visible' : 'hidden'};
    bottom: ${drawerOffset ? `${drawerOffset}px` : undefined};
  `}
`;

export const BottomDrawer = styled.div<BottomDrawerProps>`
  background-color: ${Styles.color.dialogBackground};
  border-radius: ${({ theme }) =>
    `${theme.token('border-radius-pattern')} ${theme.token('border-radius-pattern')} 0 0`};
  box-shadow:
    0px -1px 1px 0 rgba(0, 0, 0, 0.1),
    inset 0px -1px 1px 0 rgba(0, 0, 0, 0.1);

  ${({ isExpanded, drawerContentHeight }) => css`
    pointer-events: ${isExpanded ? 'initial' : 'none'};
    transform: ${!isExpanded ? `translateY(${drawerContentHeight}px)` : 'translateY(0px)'};
    transition: ${typeof isExpanded !== 'boolean'
      ? undefined
      : '0.2s cubic-bezier(0.85, 0.74, 0.46, 0.93)'};
  `}
`;

export const BottomDrawerPreview = styled.div<ExpandProps>`
  display: flex;
  align-items: center;
  padding: 0 ${primitive.$spacing4} ${primitive.$spacing4};
  box-shadow: ${({ isExpanded }) => !isExpanded && 'inset 0px -1px 1px 0 rgba(0, 0, 0, 0.1)'};
  pointer-events: initial;
`;

export const BottomDrawerPreviewInfo = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
  padding: 0 ${primitive.$spacing4};
`;

export const Title = styled.p<IsFHSBrandProp>`
  font: var(--font-headerTwo);
  color: ${({ isFHSBrand }) => isFHSBrand && primitive.fhs.$houseNavy};
  margin: 0;
`;

export const Description = styled.p<IsFHSBrandProp>`
  font: var(--font-copyTwo);
  color: ${({ isFHSBrand }) => isFHSBrand && primitive.fhs.$houseNavy};
  margin: 0;
`;

export const BottomDrawerHandler = styled.div`
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: initial;

  &::before {
    content: '';
    width: 40px;
    height: 4px;
    background: rgba(0, 0, 0, 0.1);
  }
`;

export const BottomDrawerContent = styled.div`
  padding: 0 ${primitive.$spacing4} ${primitive.$spacing4};
`;
