import { useContext, useEffect } from 'react';

import { useSalesForceInApp } from 'hooks/use-salesforce-in-app';
import { LocationContext } from 'state/location';
import {
  CustomEventNames,
  EventTypes,
  MParticleContext,
  useMParticleContext,
} from 'state/mParticle';
import { setUserUTMAttributes } from 'state/mParticle/set-user-attributes';
import { useStoreContext } from 'state/store';
import { routes } from 'utils/routing';

import { useFeatureHomePage } from '../features/home-page/use-feature-home-page';

export default function useLogPageView() {
  const locationContext = useContext(LocationContext);
  const { eventPageName, setEventPageName } = locationContext;
  const { pathname, search } = locationContext.location;
  const mParticle = useContext(MParticleContext);
  const { homePageId } = useFeatureHomePage();
  const { trackEvent } = useMParticleContext();
  const { restaurantLogAttributesRef } = useStoreContext();

  const { handleLogPageView } = useSalesForceInApp();

  useEffect(() => {
    // get mParticle user object with current attributes
    const user = window.mParticle?.Identity?.getCurrentUser?.();

    // add the UTM attributes conteined on URL to mParticle user object
    setUserUTMAttributes(user, new URLSearchParams(window.location.search));

    // determine attributes to send to the events below
    const attrs = {
      pathname,
      pathWithQuery: pathname + search,
      sanityId: pathname === routes.base ? homePageId.featureHomePageId : '',
      referrer: window.location.toString() || document.referrer,
      Name: eventPageName,
      ...restaurantLogAttributesRef.current,
    };
    // Flags for google analytics integration
    const flags = {
      'Google.Page': pathname,
      'Google.DocumentReferrer': window.location.toString() || document.referrer,
    };
    // mParticle page view event - uses mParticle logPageView API to track page view
    mParticle.logPageView(pathname, attrs, flags);
    // mParticle custom event for page view - uses mParticle logEvent API to track page view
    trackEvent({
      name: CustomEventNames.PAGE_VIEW,
      type: EventTypes.Other,
      attributes: {
        ...attrs,
        ...flags,
        path: pathname,
      },
    });

    setEventPageName(undefined);
    handleLogPageView();
  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps
}
