import {
  AddCreditAccountMutationHookResult,
  GetEncryptionDetailsMutationHookResult,
} from 'generated/rbi-graphql';
import { IErrorModal } from 'hooks/use-error-modal';
import { UpdateUserInfo } from 'state/auth';
import { UserDetails } from 'state/auth/hooks/use-current-user';
import { IMParticleCtx } from 'state/mParticle';
import { CardTypes, IPaymentMethod } from 'state/payment/types';

export interface IGetValidPaymentMethodId {
  canUseApplePay?: boolean;
  canUseGooglePay?: boolean;
  paymentMethodId: string;
  paymentMethods: IPaymentMethod[];
  // Return the first valid method
  returnFirstValid?: boolean;
}

export interface IInitPaymentMethods {
  accounts: IPaymentMethod[];
  paymentMethodId: string;
  prepaidPaymentMethodId: string;
}

export interface IPayment {
  user: null | UserDetails;
  isGuestAuthenticated: boolean;
  mParticle: IMParticleCtx;
  openErrorDialog: (args: IErrorModal) => void;
  updateUserInfo(args: UpdateUserInfo, shouldMuteUserInfoErrors?: boolean): Promise<void>;
  getEncryptionDetailsMutation: GetEncryptionDetailsMutationHookResult[0];
  addCreditAccountMutation: AddCreditAccountMutationHookResult[0];
}

export interface ISetupPaymentMethodState {
  paymentMethodId: string;
  prepaidPaymentMethodId: string;
  accounts: IPaymentMethod[];
  canUseApplePay: boolean;
  enableBlik: boolean;
  enableCashPayment: boolean;
  enablePayPal: boolean;
  enableSodexoVoucher: boolean;
  enableChequeGourmetVoucher: boolean;
  enablePayPalPaycomet: boolean;
  enableSodexo: boolean;
  enableChequeGourmet: boolean;
  enableThDirectPayApplePay: boolean;
  applePayCardDetails: IPaymentMethod;
  canUseGooglePay: boolean;
  enableThDirectPayGooglePay: boolean;
  googlePayCardDetails: IPaymentMethod;
  paymentMethodHasBeenInit: boolean;
  enableSaveCard: boolean;
}
export interface IGetAvailablePaymentMethods
  extends Pick<
    ISetupPaymentMethodState,
    | 'accounts'
    | 'applePayCardDetails'
    | 'canUseApplePay'
    | 'canUseGooglePay'
    | 'googlePayCardDetails'
  > {}

export interface IGetPrepaidReloadPaymentMethodId {
  paymentMethodHasBeenInit: boolean;
  accounts: IPaymentMethod[];
  usersPaymentMethodId: string;
  canUseApplePay: boolean;
  applePayCardDetails: IPaymentMethod;
  canUseGooglePay: boolean;
  googlePayCardDetails: IPaymentMethod;
  isUsersPaymentMethodGiftCard?: boolean;
}

export interface IGetCheckoutPaymentMethodId {
  paymentMethodHasBeenInit: boolean;
  accounts: IPaymentMethod[];
  usersPaymentMethodId: string;
  canUseApplePay: boolean;
  enableThDirectPayApplePay: boolean;
  applePayCardDetails: IPaymentMethod;
  canUseGooglePay: boolean;
  enableThDirectPayGooglePay: boolean;
  googlePayCardDetails: IPaymentMethod;
}

export interface ICheckNativePaymentDefaulting {
  paymentMethodId: string;
  cardType: CardTypes;
  canUseNativePayment?: boolean;
}

export interface INativePaymentDefaulted {
  paymentMethodId: string;
  canUseApplePay?: boolean;
  canUseGooglePay?: boolean;
}

export enum ThreeDSGraphQLErrorsCode {
  THREEDS_PAYMENT_METHOD_ERROR = 'THREEDS_PAYMENT_METHOD_ERROR',
}

export enum ThreeDSType {
  METHOD = 'METHOD',
  CHALLENGE = 'CHALLENGE',
}

export interface ThreeDSData {
  code: ThreeDSGraphQLErrorsCode;
  statusCategory: string;
  statusCode: number;
  iframeContent?: string;
  transactionId?: string;
  type: ThreeDSType;
  acsUrl?: string;
  challengeRequestToken?: string;
}

export interface ThreeDSDataPayment extends ThreeDSData {
  saleId?: string;
}

export interface RedirectData {
  action: {
    data?: any;
    method?: string;
    paymentData?: string;
    paymentMethodType?: string;
    type?: string;
    url?: string;
  };
  saleId: string;
}

export enum GraphQLPaymentErrorType {
  AWAIT = 'await',
  REDIRECT = 'redirect',
}
