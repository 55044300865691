import React, { useContext, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { IBaseProps } from '@rbi-ctg/frontend';
import { getCountry } from 'utils/environment';
import { IPlaceData } from 'utils/geolocation';
import noop from 'utils/noop';

import { useNavigateBack } from './hooks/use-navigate-back';
import { ILocation } from './types';

export { Link, useMatch } from 'react-router-dom';

export const LocationContext = React.createContext<ILocation>({} as ILocation);
export const useLocationContext = () => useContext(LocationContext);

export function LocationProvider(props: IBaseProps) {
  const [storeLocatorCallbackUrl, setStoreLocatorCallbackUrl] = useState('');

  const { addNavigateBackListener, back } = useNavigateBack();
  const addHardwareBackButtonListener = noop;
  const removeHardwareBackButtonListener = noop;
  const [referrerLocation, setReferrerLocation] = useState<string | undefined>();
  const [signUpEmail, setSignUpEmail] = useState('');
  const [deliveryAddressMapFallback, setDeliveryAddressMapFallback] = useState<IPlaceData>({
    coordinates: {
      lat: 0,
      lng: 0,
    },
    address: {
      addressLine1: '',
      city: '',
      country: getCountry(),
    },
  });
  const [eventPageName, setEventPageName] = useState<string | undefined>();

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <LocationContext.Provider
      value={{
        addNavigateBackListener,
        addHardwareBackButtonListener,
        back,
        signUpEmail,
        location,
        navigate,
        referrerLocation,
        removeHardwareBackButtonListener,
        setSignUpEmail,
        setReferrerLocation,
        setStoreLocatorCallbackUrl,
        storeLocatorCallbackUrl,
        deliveryAddressMapFallback,
        setDeliveryAddressMapFallback,
        eventPageName,
        setEventPageName,
      }}
    >
      {props.children}
    </LocationContext.Provider>
  );
}

export default LocationContext.Consumer;
