import { useCallback, useState } from 'react';

import { useAuthContext } from 'state/auth';

import { useAutoEnrollUserInLoyaltyMutation } from '../../generated/rbi-graphql';

export const useAutoEnrollUserInLoyalty = () => {
  const { isAuthenticated } = useAuthContext();
  const [loyaltyUserCreated, setLoyaltyUserCreated] = useState(false);
  const [result, setResult] = useState<any>(null);

  const [createLoyaltyUser] = useAutoEnrollUserInLoyaltyMutation({
    onCompleted: ({ autoEnrollUserInLoyalty: { loyaltyId } }) => {
      if (loyaltyId) {
        setLoyaltyUserCreated(true);
      }
    },
  });

  const tryToEnroll = useCallback(async () => {
    if (!isAuthenticated() || loyaltyUserCreated) {
      return;
    }
    const response = await createLoyaltyUser();
    setResult(response);
  }, [isAuthenticated, loyaltyUserCreated, createLoyaltyUser]);

  return { tryAutoEnrollUserInLoyalty: tryToEnroll, result };
};
