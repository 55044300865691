import {
  format as dateFnsFormat,
  formatDistanceToNowStrict as dateFnsformatDistanceToNowStrict,
} from 'date-fns';

import { SupportedLanguages } from '@rbi-ctg/frontend';
import logger from 'utils/logger';

import { Locales } from './locales';

export function formatDistanceToNow(date: Date | number, language: SupportedLanguages): string {
  try {
    return dateFnsformatDistanceToNowStrict(date, {
      locale: Locales[language] || Locales.en,
    });
  } catch (error) {
    logger.error({ error, date, language });
    return '';
  }
}

export function format(
  date: Date | number,
  formatString: string,
  language: SupportedLanguages
): string {
  try {
    return dateFnsFormat(date, formatString, {
      locale: Locales[language] || Locales.en,
    });
  } catch (error) {
    logger.error({ error, date, formatString, language });
    return '';
  }
}
