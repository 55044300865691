import { CustomEventNames, EventTypes, IMParticleCtx } from 'state/mParticle';

export const checkLimitReachedEvent = (overLimitTotal: string, mParticle: IMParticleCtx) => {
  mParticle.trackEvent({
    name: CustomEventNames.CHECKOUT_LIMIT_REACHED,
    type: EventTypes.Other,
    attributes: {
      total: overLimitTotal,
    },
  });
};

export const checkMinimumNotReachedEvent = (underLimitTotal: string, mParticle: IMParticleCtx) => {
  mParticle.trackEvent({
    name: CustomEventNames.CHECKOUT_DELIVERY_MINIMUM_NOT_REACHED,
    type: EventTypes.Other,
    attributes: {
      total: underLimitTotal,
    },
  });
};
