import { asIsoCountryCode2, getDefaultLanguage, getLanguages } from '@rbilabs/intl';

import { loadLanguage } from 'state/intl/load-language';
import { loadRegion } from 'state/intl/load-region';
import { FALLBACK_ISO2, FALLBACK_LOCALES } from 'utils/constants';
import { brand, country } from 'utils/environment';

import { REGIONAL_APP } from './regional-app';

export const loadLocale = () => {
  if (REGIONAL_APP) {
    const region = country?.toUpperCase();

    const regionSupportedLanguages = getLanguages(brand(), region) ?? FALLBACK_LOCALES;
    const hasMultipleLanguages = region && regionSupportedLanguages.length > 1;
    // If region has multiple languages, access language from selection history (or default if not available)
    // otherwise lock in default language
    const language = hasMultipleLanguages ? loadLanguage() : getDefaultLanguage(brand(), region);
    const supportedRegion = asIsoCountryCode2(region) || FALLBACK_ISO2;

    return { language, region: supportedRegion };
  }
  return { language: loadLanguage(), region: loadRegion() };
};
