import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ICartEntry } from '@rbi-ctg/menu';
import {
  IBenefitSwap,
  ILoyaltyOffersQuery,
  ILoyaltyUserOffersQuery,
  IOffersFragment,
  OfferRedemptionType,
  PaymentMethod,
  useLoyaltyOffersLazyQuery,
  useLoyaltyUserOffersLazyQuery,
} from 'generated/graphql-gateway';
import { OfferType } from 'generated/rbi-graphql';
import {
  useFeatureSortedLoyaltyOffersQuery,
  useLoyaltySystemwideOffersByIdsQuery,
} from 'generated/sanity-graphql';
import { useLocaleSmartBlockContent } from 'hooks/use-locale-smart-block-content/use-locale-smart-block-content';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import {
  flattenEntriesToMap,
  parseEntry,
  parseOffers,
} from 'state/global-state/models/loyalty/offers/offers.utils';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useNearbyStoresContext } from 'state/nearby-stores';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import logger from 'utils/logger';

import { LoyaltyAppliedOffer, LoyaltyOffer } from '../types';

import { IAppliedRewards, IQueryLoyaltyUserOffersOptions, isSwap } from './types';
import { useLoyaltyOffersEvaluation } from './use-loyalty-offers-evaluation';
import { usePersonalizedOffers } from './use-personalized-offers';
import { IPersonalizedData, mergePersonalizedData } from './utils/personalized-offers';

export const useLoyaltyOffers = () => {
  // This flag is parent to the next ones
  const loyaltyOffersEnabled = Boolean(useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_OFFERS));
  // This flag replaces Offers 3.0
  const loyaltyStandardOffersEnabled = Boolean(
    useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_STANDARD_OFFERS)
  );
  // This flag enables surprise offers including swaps
  const loyaltySurpriseOffersEnabled = Boolean(
    useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_SURPRISE_OFFERS)
  );

  const enableLoyaltyStandardOffers = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_STANDARD_OFFERS);
  const offersCooldownEnabled = Boolean(useFlag(LaunchDarklyFlag.ENABLE_GLOBAL_OFFERS_COOLDOWN));

  const [evaluating, setEvaluating] = useState(false);
  const dispatch = useAppDispatch();
  const loyaltyUserData = useAppSelector(selectors.loyalty.selectUser);
  const appliedOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
  const userOffers = useAppSelector(selectors.loyalty.selectUserOffers);
  const cartEntriesIdsMap = useAppSelector(selectors.loyalty.selectCartEntriesIdsMap);
  const { serviceMode } = useServiceModeContext();
  const { store } = useStoreContext();
  const sortedOffersRef = useRef<LoyaltyOffer[]>([]);
  const enableGeolocation = useFlag(LaunchDarklyFlag.ENABLE_SETTINGS_GEOLOCATION);

  const [fetchPersonalizedOfferData, { loading: personalizedDataLoading }] =
    usePersonalizedOffers();
  const { storeId: nearbyStoreId, storeIdFromRestaurantGroup } = useNearbyStoresContext();

  const storeId = useMemo(() => {
    const storeIdFromGeolocation = store?.number || nearbyStoreId || storeIdFromRestaurantGroup;
    return !enableGeolocation ? store?.number : storeIdFromGeolocation;
  }, [enableGeolocation, nearbyStoreId, store, storeIdFromRestaurantGroup]);

  const { transformSmartBlockContent } = useLocaleSmartBlockContent();
  const { loading: cmsOffersLoading, refetch: refetchCmsOffers } =
    useFeatureSortedLoyaltyOffersQuery({
      skip: !enableLoyaltyStandardOffers,
      variables: {
        id: 'feature-loyalty-offers-ui-singleton',
      },
      onCompleted(data) {
        if (data?.LoyaltyOffersUI?.sortedSystemwideOffers) {
          sortedOffersRef.current = sortedOffersRef.current.concat(
            data.LoyaltyOffersUI.sortedSystemwideOffers as LoyaltyOffer[]
          );
        }
        // Trigger dispatch in case request finishes after rendering.
        dispatch(actions.loyalty.setCmsOffers(sortedOffersRef.current));
      },
    });

  const getValidOfferWithSwap = (offer: IOffersFragment) => {
    const isValidSwapOffer = (singleBenefit: IBenefitSwap) => {
      // Checking swap from value is in cartEntries
      const isValidSwap = cartEntriesIdsMap[singleBenefit.value.from];
      if (isValidSwap) {
        dispatch(actions.loyalty.setUpsizeAvailable(true));
      }
      return isValidSwap;
    };

    const isSwapApplied = appliedOffers.some(entry => !!entry.swap && entry.id === offer.id);
    // Filtering valid benefits from offer
    const benefits = offer?.benefits?.filter(benefit => {
      // filter out all the benefits if the swap was applied
      if (isSwap(benefit) && !isSwapApplied) {
        return isValidSwapOffer(benefit);
      }
      // Offer is not a swap so don't filter it
      return true;
    });

    return { ...offer, ...(benefits && { benefits }) };
  };

  const getPersonalizedOffer = (
    offer: IOffersFragment,
    personalizedOfferData: IPersonalizedData
  ): LoyaltyOffer | undefined => {
    let personalizedOffer;

    try {
      personalizedOffer = mergePersonalizedData({
        engineOffer: offer,
        transformSmartBlockContent,
        ...personalizedOfferData,
      });
    } catch (error) {
      const message = error?.message ? `Error: ${error.message}` : '';
      logger.error(`Failed processing personalized offer: ${offer.id} - ${message}`);
    }

    return personalizedOffer;
  };

  const onUserOffersQueryComplete = async (data: ILoyaltyUserOffersQuery) => {
    const loyaltyUser = data?.loyaltyUser;
    const offerRedemptionAvailableAfter = loyaltyUser?.offerRedemptionAvailability?.availableAfter;

    if (offerRedemptionAvailableAfter && offersCooldownEnabled) {
      dispatch(actions.loyalty.setOfferRedemptionAvailableAfter(offerRedemptionAvailableAfter));
    }

    const loyaltyUserOffers = loyaltyUser?.offers;
    if (!loyaltyUserOffers?.length) {
      return;
    }

    // Fetch data needed to build personalized offer
    const personalizedOfferData = await fetchPersonalizedOfferData(loyaltyUserOffers);

    let personalizedUserOffers: LoyaltyOffer[] = [];
    let systemWideOffers: IOffersFragment[] = [];
    loyaltyUserOffers.forEach(offer => {
      let validOffer = offer;

      // Do not validate surprise offers if feature is not enabled

      if (loyaltySurpriseOffersEnabled) {
        validOffer = getValidOfferWithSwap(offer);
        dispatch(actions.loyalty.setSurpriseOfferIfAvailable(offer));
      }

      if (validOffer.type === OfferType.PERSONALIZED && personalizedOfferData) {
        const personalizedOffer = getPersonalizedOffer(validOffer, personalizedOfferData);
        if (personalizedOffer) {
          personalizedUserOffers.push(personalizedOffer);
          systemWideOffers.push(validOffer);
        }
      }

      if (validOffer.type === OfferType.GLOBAL) {
        systemWideOffers.push(validOffer);
      }
    });

    sortedOffersRef.current = sortedOffersRef.current.concat(personalizedUserOffers);

    dispatch(actions.loyalty.setCmsOffers(sortedOffersRef.current));
    dispatch(actions.loyalty.setUserOffers(systemWideOffers));
    dispatch(actions.loyalty.setPersonalizedOffers(personalizedUserOffers));
  };

  const [queryUserOffers, { loading: queryUserOffersLoading }] = useLoyaltyUserOffersLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: onUserOffersQueryComplete,
  });

  const [queryEngineOffers, { loading: engineOffersLoading }] = useLoyaltyOffersLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      serviceMode: serviceMode || undefined,
      storeId,
      omitInvalids: false,
    },
    onCompleted: (data: ILoyaltyOffersQuery) => {
      if (data?.loyaltyOffers?.length) {
        dispatch(actions.loyalty.setCmsOffers(sortedOffersRef.current));
        dispatch(actions.loyalty.setOffers(data.loyaltyOffers as IOffersFragment[]));
      }
    },
  });

  // Find all surprise offers ids for the current user
  const surpriseOffersEngineIds = useMemo(
    () =>
      userOffers.reduce((acc: string[], offer) => {
        if (offer.redemptionType === OfferRedemptionType.SURPRISE && offer.sanityId) {
          acc.push(offer.sanityId);
        }
        return acc;
      }, []),
    [userOffers]
  );

  // Attach applied offers ids to get the full collection of offers from CMS
  const appliedLoyaltyOffersIds = useMemo(
    () => surpriseOffersEngineIds.concat(appliedOffers.map(({ cmsId }) => cmsId || '')),
    [appliedOffers, surpriseOffersEngineIds]
  );

  const loyaltyUserId = loyaltyUserData?.id || '';

  const { loading: swoffersLoading } = useLoyaltySystemwideOffersByIdsQuery({
    variables: {
      ids: appliedLoyaltyOffersIds,
    },
    skip: !loyaltyUserId || !appliedLoyaltyOffersIds.length,
    onCompleted(data) {
      if (data?.allSystemwideOffers) {
        sortedOffersRef.current = sortedOffersRef.current.concat(
          data.allSystemwideOffers as LoyaltyOffer[]
        );
        dispatch(actions.loyalty.setCmsOffers(sortedOffersRef.current));

        // For upsize and discount offers we need to validate fetching user offers again.
        const upsizeAndDiscountIds = appliedOffers
          .filter(({ swap, cartId }) => !!swap || cartId === 'discount-offer')
          .map(({ id }) => id || '');

        if (upsizeAndDiscountIds.length) {
          queryUserOffers({
            variables: {
              loyaltyId: loyaltyUserId,
              where: {
                omitInvalids: false,
                ids: upsizeAndDiscountIds,
              },
            },
          });
        }
      }
    },
  });

  const queryLoyaltyUserOffers = useCallback(
    ({
      redemptionTypes,
      omitInvalids = false,
    }: {
      redemptionTypes: OfferRedemptionType[];
      omitInvalids?: boolean;
    }) =>
      ({
        loyaltyId,
        cartEntries,
        appliedRewards,
        subtotalAmount,
        appliedLoyaltyOffers = [],
      }: IQueryLoyaltyUserOffersOptions) => {
        const parsedEntries = cartEntries?.reduce(parseEntry(appliedRewards), []);
        dispatch(
          actions.loyalty.setCartEntriesIdsMap(
            (parsedEntries || []).reduce(flattenEntriesToMap, {})
          )
        );
        queryUserOffers({
          variables: {
            loyaltyId,
            where: {
              appliedIncentives: parseOffers(appliedLoyaltyOffers),
              cartEntries: parsedEntries,
              serviceMode: serviceMode || undefined,
              redemptionTypes,
              storeId,
              subtotalAmount,
              omitInvalids,
            },
          },
        });
      },
    [queryUserOffers, dispatch, serviceMode, storeId]
  );

  // Offers without loyaltyId should always be of redemption type STANDARD
  const queryLoyaltyOffers = useCallback(
    ({ subtotalAmount = 0 }) => {
      queryEngineOffers({
        variables: {
          serviceMode,
          storeId,
          subtotalAmount,
          redemptionTypes: [OfferRedemptionType.STANDARD],
        },
      });
    },
    [queryEngineOffers, serviceMode, storeId]
  );

  const queryLoyaltyUserStandardOffers = useCallback(
    queryLoyaltyUserOffers({ redemptionTypes: [OfferRedemptionType.STANDARD] }),
    [queryLoyaltyUserOffers]
  );

  const queryLoyaltyUserSurpriseOffers = useCallback(
    queryLoyaltyUserOffers({
      redemptionTypes: [OfferRedemptionType.SURPRISE, OfferRedemptionType.SWAP],
      omitInvalids: true,
    }),
    [queryLoyaltyUserOffers]
  );

  const { evaluateLoyaltyOffers } = useLoyaltyOffersEvaluation();

  const evaluateLoyaltyUserIncentives = useCallback(
    async (
      loyaltyId: string,
      appliedLoyaltyOffers: LoyaltyAppliedOffer[],
      cartEntries?: ICartEntry[],
      appliedLoyaltyRewards?: IAppliedRewards | null,
      subtotalAmount?: number,
      paymentMethod?: PaymentMethod | null
    ) => {
      const parsedEntries = cartEntries?.reduce(parseEntry(appliedLoyaltyRewards), []);
      dispatch(
        actions.loyalty.setCartEntriesIdsMap((parsedEntries || []).reduce(flattenEntriesToMap, {}))
      );
      setEvaluating(true);

      let evaluationResult = null;
      try {
        evaluationResult = await evaluateLoyaltyOffers({
          loyaltyId,
          appliedOffers: appliedLoyaltyOffers,
          cartEntries: parsedEntries,
          subtotalAmount,
          paymentMethod,
          serviceMode,
          storeId,
        });

        if (evaluationResult) {
          dispatch(actions.loyalty.setOffersFeedbackMap(evaluationResult));
        }
      } finally {
        setEvaluating(false);
      }

      return evaluationResult;
    },
    [dispatch, evaluateLoyaltyOffers, serviceMode, storeId]
  );

  useEffect(() => {
    dispatch(
      actions.loyalty.setOffersLoading(
        engineOffersLoading ||
          queryUserOffersLoading ||
          personalizedDataLoading ||
          cmsOffersLoading ||
          swoffersLoading ||
          evaluating
      )
    );
  }, [
    dispatch,
    engineOffersLoading,
    queryUserOffersLoading,
    personalizedDataLoading,
    cmsOffersLoading,
    swoffersLoading,
    evaluating,
  ]);

  return {
    evaluateLoyaltyUserIncentives,
    loyaltyOffersEnabled,
    loyaltyStandardOffersEnabled,
    loyaltySurpriseOffersEnabled,
    queryLoyaltyOffers,
    queryLoyaltyUserStandardOffers,
    queryLoyaltyUserSurpriseOffers,
    refetchCmsOffers,
  };
};
