// exceptions for network requests by type

class BaseNetworkError extends Error {
  constructor(
    readonly name: string,
    readonly message: string,
    readonly originalError?: Error
  ) {
    super(`${name.split(' ')}: ${message}`);
    this.stack = originalError && originalError.stack;
  }
}

export class NotFoundMenuItemError extends Error {
  notFoundError: boolean = true;
  constructor(readonly type: string) {
    super(`unsupported item type ${type}`);
  }
}

export class NetworkConnectionError extends BaseNetworkError {
  connectionError: boolean = true;
  constructor(originalError?: Error) {
    super('NetworkError', 'Network connection unavailable', originalError);
  }
}

export class CommitOrderError extends BaseNetworkError {
  constructor(message: string, originalError: Error) {
    super(CommitOrderError.name, message, originalError);
  }
}

export class CustomizableContentError extends BaseNetworkError {
  constructor(message: string, originalError: Error) {
    super(CustomizableContentError.name, message, originalError);
  }
}

export class FirstDataError extends BaseNetworkError {
  constructor(message: string, originalError: Error) {
    super(FirstDataError.name, message, originalError);
  }
}

export class LoyaltyError extends BaseNetworkError {
  constructor(message: string, originalError?: Error) {
    super(LoyaltyError.name, message, originalError);
  }
}

export class MenuDataError extends BaseNetworkError {
  constructor(message: string, originalError: Error) {
    super(MenuDataError.name, message, originalError);
  }
}

export class OfferError extends BaseNetworkError {
  constructor(message: string, originalError: Error) {
    super(OfferError.name, message, originalError);
  }
}

export class OrderError extends BaseNetworkError {
  constructor(message: string, originalError: Error) {
    super(OrderError.name, message, originalError);
  }
}

export class PriceOrderError extends BaseNetworkError {
  constructor(message: string, originalError: Error) {
    super(PriceOrderError.name, message, originalError);
  }
}

export class RestaurantsError extends BaseNetworkError {
  constructor(message: string, originalError: Error) {
    super(RestaurantsError.name, message, originalError);
  }
}

export class StaticPageError extends BaseNetworkError {
  constructor(message: string, originalError: Error) {
    super(StaticPageError.name, message, originalError);
  }
}

export class UserOrdersError extends BaseNetworkError {
  constructor(message: string, originalError: Error) {
    super(UserOrdersError.name, message, originalError);
  }
}

export class RollUpTheRimError extends BaseNetworkError {
  constructor(message: string, originalError: Error) {
    super(RollUpTheRimError.name, message, originalError);
  }
}
