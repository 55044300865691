import React, { useMemo } from 'react';

import { Box } from '@rbilabs/components-library/build/components/layout';
import { RadioButton } from '@rbilabs/components-library/build/components/radio-button';
import { asLanguage, getLocales } from '@rbilabs/intl';
import { useIntl } from 'react-intl';

import ActionButton from 'components/action-button';
import { messageIdMap } from 'components/country-select/generated/country-info';
import { useLocale } from 'state/intl';
import { useFlag } from 'state/launchdarkly';
import { FALLBACK_LANGUAGE, FALLBACK_LOCALES } from 'utils/constants';
import { brand } from 'utils/environment';
import { RegionToISOs } from 'utils/form/constants';
import { getNativeLanguageName } from 'utils/i18n';
import { LaunchDarklyFlag } from 'utils/launchdarkly';
import { formatCurrencyCodeForRegion } from 'utils/payment/format-currency-code-for-region';

import { SHOW_LANGUAGE_BEFORE_COUNTRY } from '../show-language-before-country';

import {
  Disclaimer,
  LanguageSelectionCell,
  LanguageSelectionContainer,
  ModalAdditionalContentWrapper,
} from './styled';

interface ILanguageAndCountryLabelProps {
  labelHeader: boolean;
  showLanguageBeforeCountry: boolean;
  singleRegionLanguageSelector: boolean;
  mappedLanguage: string;
  mappedRegion: string;
}

const LabelContent: React.FC<ILanguageAndCountryLabelProps> = ({
  labelHeader,
  showLanguageBeforeCountry,
  singleRegionLanguageSelector,
  mappedLanguage,
  mappedRegion,
}) => {
  const { formatMessage } = useIntl();

  const languageName = getNativeLanguageName(asLanguage(mappedLanguage) ?? FALLBACK_LANGUAGE);
  const languageLabel = <span lang={mappedLanguage.toLowerCase()}>{languageName}</span>;

  const regionLabel = !singleRegionLanguageSelector ? (
    <span data-testid={`dialog-code-${mappedLanguage.toLowerCase()}-${mappedRegion.toLowerCase()}`}>
      {formatCurrencyCodeForRegion(formatMessage({ id: messageIdMap[RegionToISOs[mappedRegion]] }))}
    </span>
  ) : null;

  if (showLanguageBeforeCountry) {
    return labelHeader ? languageLabel : regionLabel;
  }
  return labelHeader ? regionLabel : languageLabel;
};

interface ILanguageModalContentsProps {
  regionFilter: (region: string) => boolean;
  disclaimer?: string;
  applyButtonText: string;
  onLanguageChange: (selectedLanguage: string, mappedRegion: string) => VoidFunction;
  onSaveClick: VoidFunction;
  regionKey: string;
  langKey: string;
  /**
   * Pass the id of the heading that describes the radio group.
   */
  ariaRadioGroupLabeledBy: string;
}

const LanguageSelectorModalContents: React.FC<ILanguageModalContentsProps> = ({
  regionKey,
  langKey,
  disclaimer,
  applyButtonText,
  onLanguageChange,
  onSaveClick,
  ariaRadioGroupLabeledBy = '',
}) => {
  const { formatMessage } = useIntl();
  const { region } = useLocale();
  const singleRegionLanguageSelector = useFlag(LaunchDarklyFlag.SINGLE_REGION_LANGUAGE_SELECTOR);

  const locales = useMemo(() => getLocales(brand(), region) ?? FALLBACK_LOCALES, [region]);

  const regionLanguageSelections = locales.map(locale => {
    const language = asLanguage(locale.slice(0, 2)) ?? FALLBACK_LANGUAGE;
    const languageName = getNativeLanguageName(language);
    const commonLabelProps = {
      showLanguageBeforeCountry: SHOW_LANGUAGE_BEFORE_COUNTRY,
      singleRegionLanguageSelector,
      mappedLanguage: language,
      mappedRegion: region,
    };
    return (
      <LanguageSelectionCell key={`dialog-button-${language}-${region}`}>
        <RadioButton
          value={`${language.toLowerCase()}-${region}`}
          data-testid={`dialog-button-${language.toLowerCase()}-${region.toLowerCase()}`}
          onChange={onLanguageChange(language, region)}
          checked={regionKey === region && langKey === language}
          aria-label={formatMessage({ id: 'selectUiLanguage' }, { languageName })}
          labelHeader={<LabelContent labelHeader {...commonLabelProps} />}
          label={<LabelContent labelHeader={false} {...commonLabelProps} />}
        />
      </LanguageSelectionCell>
    );
  });

  return (
    <>
      <LanguageSelectionContainer role="radiogroup" aria-labelledby={ariaRadioGroupLabeledBy}>
        {regionLanguageSelections}
      </LanguageSelectionContainer>
      {disclaimer && (
        <ModalAdditionalContentWrapper>
          <Disclaimer>{disclaimer}</Disclaimer>
        </ModalAdditionalContentWrapper>
      )}
      <Box center width="100%" margin="0 0 2rem">
        <ActionButton onClick={onSaveClick} data-testid="action-button-apply-language-selection">
          {applyButtonText}
        </ActionButton>
      </Box>
    </>
  );
};

export default LanguageSelectorModalContents;
