import styled, { css } from 'styled-components';

import Currency from 'components/currency';
import { brandFont } from 'components/layout/brand-font';
import { primitive } from 'styles/constants/primitives';

export const EntryDescriptionRow = styled.p`
  font: ${brandFont.copyOne};
  width: 100%;
  margin: 0;
  line-height: 1.4;
`;

export const Item = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex-wrap: wrap;
  padding: 0 0 ${primitive.$spacing3} 0;

  &:nth-of-type(n + 2) {
    border-top: 1px solid ${p => p.theme.token('border-color-default')};
    padding: ${primitive.$spacing3} 0;
  }

  &:last-of-type {
    padding-bottom: 0;
  }

  h4 {
    font: ${brandFont.headerThree};
    text-transform: ${Styles.textTransform.headlines};
    margin: ${primitive.$spacing2} 0;
    padding-right: ${primitive.$spacing8};
  }
`;

export const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: baseline;
`;

export const ItemName = styled.h4`
  order: -2;
  flex: 1;
  line-height: 1;
`;

export const ItemPrice = styled.div<{ row?: number }>`
  order: 999;
  width: 100%;
  margin: 0;
`;

export const ItemQuantityBadge = styled.div<{ quantityText?: string; row?: number }>`
  order: -1;
  padding: 0 ${primitive.$spacing3};
  font-size: 0.75rem;
  background-color: ${p => p.theme.token('background-badge-cart-entry')};
  border-radius: 9999rem;
`;

export const StyledCurrency = styled(Currency)<{ $zeroAmount: boolean }>`
  font: ${brandFont.headerThree};
  ${p =>
    p.$zeroAmount &&
    css`
      color: ${p.theme.token('icon-default')};
    `}
`;
