import { IsoCountryCode2, IsoCountryCode3, Language, Locale } from '@rbilabs/intl';

export * from './constants.base';

export const ENABLE_AUTO_SHOW_REGION_SELECTOR = false;
export const ENABLE_IDENTITY_SYNC_ON_LOGIN = false;
export const ENABLE_LEGACY_EMAIL_IMPORT = false;
export const ENABLE_LOYALTY_2_0_SEE_MORE_OPTIONS = false;
export const ENABLE_LOYALTY_20_ON_BOARDING_CONTENT_CARD_ON_THE_HOME_PAGE = false;
export const ENABLE_NO_PIN_CHECK_PREPAID_MERGE = false;
export const ENABLE_TH_DIRECT_PAY_FOR_DELIVERY = false;
export const LOYALTY_CARD_DETAILS_POLL_INTERVAL_MS = 120000;
export const PHONE_NUMBER_DUMMY = '00000000';
export const FALLBACK_ZIPCODE_REGEX = /^([a-zA-Z0-9\\\\s_-]){4,10}$/;
export const FALLBACK_LOCALES = [Locale.en];
export const FALLBACK_LANGUAGE = Language.en;
export const FALLBACK_ISO2 = IsoCountryCode2.AQ;
export const FALLBACK_ISO3 = IsoCountryCode3.ATA;
export const FALLBACK_CURRENCY_SYMBOL = '$';
