import React, { useCallback } from 'react';

import { Icon } from '@rbilabs/components-library/build/components/icon';

import { LinkNoDecoration } from 'components/app-header/mobile-header/styled';
import { useFeatureNavigation } from 'hooks/use-feature-navigation';
import { useLocationContext } from 'state/location';
import { CustomEventNames, useMParticleContext } from 'state/mParticle';
import { useReversedUIContext } from 'state/reversed-ui';

export const HamburgerButton: React.FC<{}> = () => {
  const { featureNavigationMobileHeaderButton } = useFeatureNavigation();
  const { reversedUI } = useReversedUIContext();
  const mParticle = useMParticleContext();
  const { location } = useLocationContext();
  const iconColor = reversedUI ? 'icon-reversed' : 'icon-header-contrast';
  const hasNavigationLink = !!featureNavigationMobileHeaderButton?.link?.locale;

  const pathName = location.pathname;
  const handleClick = useCallback(() => {
    mParticle.logNavigationClick(CustomEventNames.BUTTON_CLICK, {
      Name: 'Account',
      Path: pathName,
    });
  }, [mParticle, pathName]);

  return hasNavigationLink ? (
    <LinkNoDecoration
      to={featureNavigationMobileHeaderButton?.link?.locale ?? ''}
      onClick={handleClick}
    >
      <Icon icon="menu" color={iconColor} width="1.5rem" aria-hidden />
    </LinkNoDecoration>
  ) : null;
};
